import { Button, Checkbox, FormControl, FormControlLabel, FormHelperText, Stack, TextField } from "@mui/material";
import { ChangeEvent } from "react";
import { Trans, useTranslation } from "react-i18next";
import { confirmApplicant } from "../../../../../services/confirmApplicantService";
import './ProfileStep.scss';
import { ISubmitApplicationResult } from "../../../../../services/commonTypes";

const ProfileStep = (props: IProps) => {
    const { t } = useTranslation();

    const isFormValid = () => {
        let errors: IErrors = {};
        const minLength = 2;
        const maxLength = 50;

        if (!props.state.fields.firstName) {
            errors.firstName = t('validation_errors.required');
        } else if (props.state.fields.firstName.length > maxLength) {
            errors.firstName = t('validation_errors.max_length_reached', { max: maxLength });
        } else if (props.state.fields.firstName.length < minLength) {
            errors.firstName = t('validation_errors.min_length_unreached', { min: minLength });
        }

        if (!props.state.fields.lastName) {
            errors.lastName = t('validation_errors.required');
        } else if (props.state.fields.lastName.length > maxLength) {
            errors.lastName = t('validation_errors.max_length_reached', { max: maxLength });
        } else if (props.state.fields.lastName.length < minLength) {
            errors.lastName = t('validation_errors.min_length_unreached', { min: minLength });
        }

        if (!props.state.fields.email) {
            errors.email = t('validation_errors.required');
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(props.state.fields.email)) {
            errors.email = t('validation_errors.invalid_email');
        }

        if (!props.state.fields.authorized) {
            errors.authorized = t('validation_errors.required');
        }

        props.onStateChanged({ ...props.state, errors: errors });

        return (Object.keys(errors).length === 0);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name } = event.target;
        let value: string | boolean;

        if (name === 'authorized') {
            value = event.target.checked;

        } else {
            value = event.target.value;
        }

        props.onStateChanged({
            ...props.state,
            fields: {
                ...props.state.fields,
                [name]: value
            }
        });
    };

    const handleSubmit = async () => {
        const isValid = isFormValid();
        if (!isValid) {
            return;
        }

        props.onStateChanged({ ...props.state, isLoading: true })

        try {
            const result = await confirmApplicant(
                props.state.operationUrl,
                props.resourceToken,
                {
                    firstName: props.state.fields.firstName!,
                    lastName: props.state.fields.lastName!,
                    email: props.state.fields.email!
                });

            props.onCompleted(result);
        } catch (error) {
            console.log(error);
            props.onError();
        }

        props.onStateChanged({
            ...props.state,
            isLoading: false,
            greetingsFirstName: props.state.fields.firstName,
            errors: {}
        });
    };

    return (
        <div className="profile-step-container">
            <div className='wizard-step-introduction'>
                <p>
                    {props.state.greetingsFirstName
                         ? <Trans i18nKey='steps.profile.landing_message_with_name' values={{ platformName: props.platformName, firstName: props.state.greetingsFirstName }} />
                         : <Trans i18nKey='steps.profile.landing_message_without_name' values={{ platformName: props.platformName, firstName: props.state.greetingsFirstName }} />
                    }
                </p>
                <p>{t('steps.profile.tell_about_you')}</p>
            </div>

            <Stack spacing={2} className='wizard-step-fields'>
                <TextField
                    id='firstName'
                    name='firstName'
                    label={t('form_labels.first_name')}
                    variant='outlined'
                    defaultValue={props.state.fields.firstName}
                    onChange={handleChange}
                    error={!!props.state.errors.firstName}
                    helperText={props.state.errors.firstName}
                    required />

                <TextField
                    id='lastName'
                    name='lastName'
                    label={t('form_labels.last_name')}
                    variant='outlined'
                    defaultValue={props.state.fields.lastName}
                    onChange={handleChange}
                    error={!!props.state.errors.lastName}
                    helperText={props.state.errors.lastName}
                    required />

                <TextField
                    id='email'
                    name='email'
                    label={t('form_labels.email')}
                    variant='outlined'
                    defaultValue={props.state.fields.email}
                    onChange={handleChange}
                    error={!!props.state.errors.email}
                    helperText={props.state.errors.email}
                    required />
            </Stack>

            <div className='profile-step-authorization'>
                <FormControl required error={!!props.state.errors.authorized} onChange={handleChange}>
                    <FormControlLabel id='authorized' name='authorized' control={<Checkbox checked={props.state.fields.authorized} inputProps={{ 'aria-label': 'authorized' }} required />} label={t('steps.profile.authorization')} />
                    <FormHelperText>{props.state.errors.authorized}</FormHelperText>
                </FormControl>
            </div>

            <div className='wizard-step-btn'>
                <Button id="continueBtn" variant='contained' color='secondary' size='large' onClick={handleSubmit} disabled={props.state.isLoading}>{t('buttons.continue')}</Button>
            </div>
        </div>
    );
};

export default ProfileStep;

export interface IProfileState {
    fields: IFields;
    errors: IErrors;
    isLoading: boolean;
    greetingsFirstName?: string;
    operationUrl: string;
}

interface IProps {
    resourceToken: string;
    platformName: string;
    state: IProfileState;
    onCompleted: (result: ISubmitApplicationResult) => void;
    onError: (message?: string) => void;
    onStateChanged: (state: IProfileState) => void;
}

interface IFields {
    firstName?: string;
    lastName?: string;
    email?: string;
    authorized?: boolean;
}

interface IErrors {
    firstName?: string;
    lastName?: string;
    email?: string;
    authorized?: string;
}
