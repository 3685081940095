import { Alert, Button, FormControl, FormHelperText, SelectChangeEvent, Stack, TextField } from "@mui/material";
import { ReactNode, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getDocumentTypeText, getRegistrationNumberLabel } from "../../../../../helpers/applicationHelper";
import { IBusinessProfile, updateBusinessProfile } from "../../../../../services/updateBusinessProfileService";
import CountryField from "../../../../shared/CountryField/CountryField";
import SelectField, { IItem } from "../../../../shared/SelectField/SelectField";
import PhonePrefixField from "../../../../shared/PhonePrefixField/PhonePrefixField";
import { IDocument } from "../../../../../services/getApplicationService";
import { uploadFile } from '../../../../../services/uploadFileService';
import { deleteFile } from '../../../../../services/deleteFileService';
import { Dropzone } from "@dropzone-ui/react";
import { FileValidated } from "@dropzone-ui/react/build/components/dropzone/components/utils/validation.utils";
import UploadedDocuments from "../../Common/UploadedDocuments/UploadedDocuments";
import { ICountry } from "../../../../../services/getCountriesService";
import { getDistrictCourts } from "../../../../../services/getDistrictCourtsService";
import { FormStepKey, IBusinessProfileForm, getForm } from "../../../../../services/getFormService";
import { RegistrationNumberCode, DocumentType, ISubmitApplicationResult } from "../../../../../services/commonTypes";
import SearchField from "../../../../shared/SearchField/SearchField";

const BusinessProfileStep = (props: IProps) => {
    const { t: translate } = useTranslation();

    const handleSubmit = async () => {
        const isValid = isFormValid();
        if (!isValid) {
            return;
        }

        props.onStateChanged({ ...props.state, isLoading: true });

        try {
            const businessProfile: IBusinessProfile = {
                countryCode: props.state.countryCode,
                legalName: props.state.fields.legalName!,
                tradingName: props.state.fields.tradingName,
                registrationNumber: props.state.fields.registrationNumber,
                registrationNumberCode: props.state.form?.registrationNumber?.type,
                districtCourtCode: props.state.fields.districtCourtCode,
                vatNumber: props.state.fields.vatNumber,
                website: props.state.fields.website,
                mcc: props.state.fields.mcc!,
                registeredAddress: {
                    line1: props.state.fields.line1!,
                    line2: props.state.fields.line2,
                    number: props.state.fields.houseNumber!,
                    townCity: props.state.fields.townCity!,
                    state: props.state.fields.state,
                    zip: props.state.fields.zip!
                },
                phoneNumber: (props.state.fields.countryPrefix && props.state.fields.phoneNumber) ? {
                    countryCode: props.state.fields.countryPrefix,
                    number: props.state.fields.phoneNumber
                } : undefined
            };

            const result = await updateBusinessProfile(props.state.operationUrl!, props.resourceToken, businessProfile);

            if ('errors' in result) {
                let errors: IFields = {};

                if ('registration_number' in result.errors) {
                    errors.registrationNumber = result.errors['registration_number'];
                }
                if ('vat_number' in result.errors) {
                    errors.vatNumber = result.errors['vat_number'];
                }

                if (!errors) {
                    throw new Error(`Unknown error when validating the data: ${result.errors}`);
                }

                props.onStateChanged({
                    ...props.state,
                    isLoading: false,
                    errors: errors
                });

                return;
            } else {
                props.onCompleted(result);
            }
        } catch (error) {
            console.log(error);
            props.onError();
        }

        props.onStateChanged({
            ...props.state,
            isLoading: false,
            errors: {}
        });
    };

    const handleFieldChange = (fieldName: string, value: string) => {
        props.onStateChanged(
            {
                ...props.state,
                fields: {
                    ...props.state.fields!,
                    [fieldName]: value
                }
            });
    };

    const handlePhoneChange = (value: string) => {
        const regex = /^[0-9]+$/;

        if (regex.test(value) || value === '') {
            props.onStateChanged({
                ...props.state,
                fields: {
                    ...props.state.fields,
                    phoneNumber: value
                }
            });
        }
    };

    const handleDeleteDocument = async (document: IDocument) => {
        await deleteFile(props.resourceToken, document.links.self.href);
        const documents = [...props.state.uploadedDocuments];
        const toDelete = documents.indexOf(document);

        documents.splice(toDelete, 1);

        props.onStateChanged({
            ...props.state,
            uploadedDocuments: documents
        });
    };

    const handleDropFile = async (file: FileValidated) => {
        const uploadDocumentResponse = await uploadFile(props.state.uploadDocumentOperationUrl!, props.resourceToken, file.file, props.state.fields.documentType!);

        if (!uploadDocumentResponse.success) {
            const error = uploadDocumentResponse.errors.file;

            if (error) {
                const errorMessage = translate('backend_validation_errors.' + error[0], { "supportedFileTypes": "JPEG/JPG, PNG or PDF", "maxFileSize": "4MB" });
                props.onStateChanged({
                    ...props.state,
                    errors: { ...props.state.errors, document: errorMessage }
                });
            }
            return;
        }

        const document: IDocument = {
            id: uploadDocumentResponse.document.id,
            name: file.file.name,
            type: props.state.fields.documentType!,
            links: uploadDocumentResponse.document.links
        };

        const documents = [...props.state.uploadedDocuments, document];

        props.onStateChanged({
            ...props.state,
            uploadedDocuments: documents,
            errors: { ...props.state.errors, document: undefined },
            fields: { ...props.state.fields!, documentType: undefined }
        });
    }

    const handleChangeDocumentType = (event: SelectChangeEvent<any>) => {
        props.onStateChanged({
            ...props.state,
            fields: { ...props.state.fields, documentType: event.target.value }
        });
    };

    const isFormValid = () => {
        let errors: IErrors = {};

        const legalNameMaxLength = 300;
        const legalNameMinLength = 2;
        if (!props.state.fields.legalName) {
            errors.legalName = translate('validation_errors.required');
        } else if (props.state.fields.legalName.length > legalNameMaxLength) {
            errors.legalName = translate('validation_errors.max_length_reached', { max: legalNameMaxLength });
        } else if (props.state.fields.legalName.length < legalNameMinLength) {
            errors.legalName = translate('validation_errors.min_length_unreached', { min: legalNameMinLength });
        }

        const tradingNameMaxLength = 300;
        const tradingNameMinLength = 2;
        if (props.state.fields.tradingName) {
            if (props.state.fields.tradingName.length > tradingNameMaxLength) {
                errors.tradingName = translate('validation_errors.max_length_reached', { max: tradingNameMaxLength });
            } else if (props.state.fields.tradingName.length < tradingNameMinLength) {
                errors.tradingName = translate('validation_errors.min_length_unreached', { min: tradingNameMinLength });
            }
        }

        if (props.state.form?.registrationNumber?.required && !props.state.fields.registrationNumber) {
            errors.registrationNumber = translate('validation_errors.required');
        }

        if (props.state.form?.vatNumber?.required && !props.state.fields.vatNumber) {
            errors.vatNumber = translate('validation_errors.required');
        }

        if (props.state.form?.districtCourtCode?.required && !props.state.fields.districtCourtCode) {
            errors.districtCourtCode = translate('validation_errors.required');
        }

        if (!props.state.fields.mcc) {
            errors.mcc = translate('validation_errors.required');
        }

        if (props.state.fields.website) {
            const urlRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
            if (!urlRegex.test(props.state.fields.website)) {
                errors.website = translate('validation_errors.invalid_website');
            }
        }

        const line1MaxLength = 300;
        const line1MinLength = 1;
        if (!props.state.fields.line1) {
            errors.line1 = translate('validation_errors.required');
        } else if (props.state.fields.line1.length > line1MaxLength) {
            errors.line1 = translate('validation_errors.max_length_reached', { max: line1MaxLength });
        } else if (props.state.fields.line1.length < line1MinLength) {
            errors.line1 = translate('validation_errors.min_length_unreached', { min: line1MinLength });
        }

        const line2MaxLength = 300;
        const line2MinLength = 1;
        if (props.state.fields.line2) {
            if (props.state.fields.line2.length > line2MaxLength) {
                errors.line2 = translate('validation_errors.max_length_reached', { max: line2MaxLength });
            } else if (props.state.fields.line2.length < line2MinLength) {
                errors.line2 = translate('validation_errors.min_length_unreached', { min: line2MinLength });
            }
        }

        const numberMaxLength = 300;
        if (!props.state.fields.houseNumber) {
            errors.houseNumber = translate('validation_errors.required');
        } else if (props.state.fields.houseNumber.length > numberMaxLength) {
            errors.townCity = translate('validation_errors.max_length_reached', { max: numberMaxLength });
        }

        const cityMaxLength = 300;
        const cityMinLength = 2;
        if (!props.state.fields.townCity) {
            errors.townCity = translate('validation_errors.required');
        } else if (props.state.fields.townCity.length > cityMaxLength) {
            errors.townCity = translate('validation_errors.max_length_reached', { max: cityMaxLength });
        } else if (props.state.fields.townCity.length < cityMinLength) {
            errors.townCity = translate('validation_errors.min_length_unreached', { min: cityMinLength });
        }

        const stateMaxLength = 300;
        const stateMinLength = 1;
        if (props.state.fields.state) {
            if (props.state.fields.state.length > stateMaxLength) {
                errors.state = translate('validation_errors.max_length_reached', { max: stateMaxLength });
            } else if (props.state.fields.state.length < stateMinLength) {
                errors.state = translate('validation_errors.min_length_unreached', { min: stateMinLength });
            }
        }

        const zipMaxLength = 16;
        const zipMinLength = 1;
        if (!props.state.fields.zip) {
            errors.zip = translate('validation_errors.required');
        } else if (props.state.fields.zip.length > zipMaxLength) {
            errors.zip = translate('validation_errors.max_length_reached', { max: zipMaxLength });
        } else if (props.state.fields.zip.length < zipMinLength) {
            errors.zip = translate('validation_errors.min_length_unreached', { min: zipMinLength });
        }

        if (props.state.fields.phoneNumber) {
            if (!props.state.fields.countryPrefix) {
                errors.countryPrefix = translate('validation_errors.required');
            }

            const phoneNumberMaxLength = 16;
            const phoneNumberMinLength = 1;
            if (props.state.fields.phoneNumber.length > phoneNumberMaxLength) {
                errors.phoneNumber = translate('validation_errors.max_length_reached', { max: phoneNumberMaxLength });
            } else if (props.state.fields.phoneNumber.length < phoneNumberMinLength) {
                errors.phoneNumber = translate('validation_errors.min_length_unreached', { min: phoneNumberMinLength });
            }
        }

        if (props.state.fields.countryPrefix) {
            if (!props.state.fields.phoneNumber) {
                errors.phoneNumber = translate('validation_errors.required');
            }
        }

        const hasCompanyRegistration = props.state.uploadedDocuments.some(d => d.type === DocumentType.CompanyRegistration);

        if (!hasCompanyRegistration) {
            errors.missingDocuments = (
                <ul>
                    <li>{getDocumentTypeText(DocumentType.CompanyRegistration, translate)}</li>
                </ul>
            )
        }

        props.onStateChanged({ ...props.state, errors: errors });

        return (Object.keys(errors).length === 0);
    };

    const getRegistrationNumberPlaceHolder = (registrationNumberType: string | undefined): string => {
        switch (registrationNumberType) {
            case RegistrationNumberCode.DeHrb:
                return 'HRB 00000';
            case RegistrationNumberCode.DeHra:
                return 'HRA 0000';
            case RegistrationNumberCode.DeVr:
                return 'VR 00000';
            case RegistrationNumberCode.SiTin:
                return '10000000';
            case RegistrationNumberCode.EeKmkr:
                return '00000000';
            case RegistrationNumberCode.LuRcs:
                return 'A00000';
            default:
                return '';
        }
    }

    useEffect(() => {
        props.onStateChanged({ ...props.state, isLoading: true });

        getForm(props.resourceToken, FormStepKey.BusinessProfile)
            .then(form => {
                if (!form.districtCourtCode) {
                    props.onStateChanged({
                        ...props.state,
                        form: form,
                        fields: {
                            ...props.state.fields,
                            registrationNumber: form.registrationNumber ? props.state.fields.registrationNumber : undefined,
                            vatNumber: form.vatNumber ? props.state.fields.vatNumber : undefined,
                            districtCourtCode: form.districtCourtCode ? props.state.fields.districtCourtCode : undefined,
                        },
                        isLoading: false
                    });
                    return;
                }

                getDistrictCourts(props.resourceToken, props.state.countryCode)
                    .then(districtCourts => {
                        const districtCourtItems = districtCourts.map<IItem>(districtCourt => ({ value: districtCourt.code, description: districtCourt.name }));

                        props.onStateChanged({
                            ...props.state,
                            form: form,
                            fields: {
                                ...props.state.fields,
                                registrationNumber: form.registrationNumber ? props.state.fields.registrationNumber : undefined,
                                vatNumber: form.vatNumber ? props.state.fields.vatNumber : undefined,
                                districtCourtCode: form.districtCourtCode ? props.state.fields.districtCourtCode : undefined,
                            },
                            isLoading: false,
                            districtCourts: districtCourtItems
                        });
                    })
                    .catch(error => {
                        console.log(error);
                        props.onError();
                        props.onStateChanged({ ...props.state, isLoading: false });
                    });
            })
            .catch(error => {
                console.log(error);
                props.onError();
                props.onStateChanged({ ...props.state, isLoading: false });
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='business-profile-step-container'>
            <div className='wizard-step-fields'>
                <h2>{translate('steps.business_profile.business_information')}</h2>

                <Stack spacing={2}>
                    <TextField
                        id='legalName'
                        name='legalName'
                        label={translate('form_labels.legal_name')}
                        variant='outlined'
                        defaultValue={props.state.fields?.legalName}
                        onChange={(ev) => handleFieldChange(ev.target.name, ev.target.value)}
                        error={!!props.state.errors.legalName}
                        helperText={props.state.errors.legalName}
                        required />

                    <TextField
                        id='tradingName'
                        name='tradingName'
                        label={translate('form_labels.trading_name')}
                        variant='outlined'
                        defaultValue={props.state.fields?.tradingName}
                        onChange={(ev) => handleFieldChange(ev.target.name, ev.target.value)}
                        error={!!props.state.errors.tradingName}
                        helperText={props.state.errors.tradingName} />

                    {props.state.form?.registrationNumber &&
                        <TextField
                            id='registrationNumber'
                            name='registrationNumber'
                            label={getRegistrationNumberLabel(props.state.form.registrationNumber.type, translate)}
                            variant='outlined'
                            placeholder={getRegistrationNumberPlaceHolder(props.state.form.registrationNumber.type)}
                            defaultValue={props.state.fields?.registrationNumber}
                            onChange={(ev) => handleFieldChange(ev.target.name, ev.target.value)}
                            error={!!props.state.errors.registrationNumber}
                            helperText={props.state.errors.registrationNumber}
                            required={!!props.state.form.registrationNumber.required} />
                    }

                    {props.state.form?.districtCourtCode &&
                        <SelectField
                            id='districtCourtCode'
                            name='districtCourtCode'
                            label={translate('form_labels.district_court')}
                            value={props.state.fields.districtCourtCode}
                            onChange={(ev) => handleFieldChange(ev.target.name, ev.target.value)}
                            errorMessage={props.state.errors.districtCourtCode}
                            items={props.state.districtCourts}
                            required={!!props.state.form.districtCourtCode.required} />
                    }

                    {props.state.form?.vatNumber &&
                        <TextField
                            id='vatNumber'
                            name='vatNumber'
                            label={translate('form_labels.vat_number')}
                            placeholder={`${props.state.countryCode}000000`}
                            variant='outlined'
                            defaultValue={props.state.fields?.vatNumber}
                            onChange={(ev) => handleFieldChange(ev.target.name, ev.target.value)}
                            error={!!props.state.errors.vatNumber}
                            helperText={props.state.errors.vatNumber}
                            required={!!props.state.form.vatNumber.required} />
                    }

                    <TextField
                        id='website'
                        name='website'
                        label={translate('form_labels.website')}
                        variant='outlined'
                        defaultValue={props.state.fields?.website}
                        onChange={(ev) => handleFieldChange(ev.target.name, ev.target.value)}
                        error={!!props.state.errors.website}
                        helperText={props.state.errors.website} />

                    <SearchField
                        id='mcc'
                        name='mcc'
                        label={translate('form_labels.mcc')}
                        value={props.state.fields.mcc}
                        onChange={handleFieldChange}
                        items={props.state.merchantCategories}
                        errorMessage={props.state.errors.mcc}
                        required/>
                </Stack>

                <h2>{translate('steps.business_profile.registered_address')}</h2>

                <Stack spacing={2}>
                    <TextField
                        id='line1'
                        name='line1'
                        label={translate('form_labels.line_1')}
                        variant='outlined'
                        defaultValue={props.state.fields?.line1}
                        onChange={(ev) => handleFieldChange(ev.target.name, ev.target.value)}
                        error={!!props.state.errors.line1}
                        helperText={props.state.errors.line1}
                        required />

                    <TextField
                        id='line2'
                        name='line2'
                        label={translate('form_labels.line_2')}
                        variant='outlined'
                        defaultValue={props.state.fields?.line2}
                        onChange={(ev) => handleFieldChange(ev.target.name, ev.target.value)}
                        error={!!props.state.errors.line2}
                        helperText={props.state.errors.line2} />

                    <TextField
                        id='houseNumber'
                        name='houseNumber'
                        label={translate('form_labels.house_number')}
                        variant='outlined'
                        defaultValue={props.state.fields?.houseNumber}
                        onChange={(ev) => handleFieldChange(ev.target.name, ev.target.value)}
                        error={!!props.state.errors.houseNumber}
                        helperText={props.state.errors.houseNumber}
                        required />

                    <TextField
                        id='townCity'
                        name='townCity'
                        label={translate('form_labels.city')}
                        variant='outlined'
                        defaultValue={props.state.fields?.townCity}
                        onChange={(ev) => handleFieldChange(ev.target.name, ev.target.value)}
                        error={!!props.state.errors.townCity}
                        helperText={props.state.errors.townCity}
                        required />

                    <TextField
                        id='state'
                        name='state'
                        label={translate('form_labels.state')}
                        variant='outlined'
                        defaultValue={props.state.fields?.state}
                        onChange={(ev) => handleFieldChange(ev.target.name, ev.target.value)}
                        error={!!props.state.errors.state}
                        helperText={props.state.errors.state} />

                    <TextField
                        id='zip'
                        name='zip'
                        label={translate('form_labels.zip')}
                        variant='outlined'
                        defaultValue={props.state.fields?.zip}
                        onChange={(ev) => handleFieldChange(ev.target.name, ev.target.value)}
                        error={!!props.state.errors?.zip}
                        helperText={props.state.errors?.zip}
                        required />

                    <CountryField
                        id='country'
                        name='country'
                        label={translate('form_labels.country')}
                        value={props.state.countryCode}
                        items={props.state.countries}
                        onChange={handleFieldChange}
                        disabled />
                </Stack>

                <h2>{translate('steps.business_profile.phone_number')}</h2>

                <Stack spacing={2}>
                    <PhonePrefixField
                        id='countryPrefix'
                        name='countryPrefix'
                        label={translate('form_labels.country_prefix')}
                        value={props.state.fields.countryPrefix}
                        errorMessage={props.state.errors?.countryPrefix}
                        items={props.state.countries}
                        onChange={handleFieldChange} />

                    <TextField
                        id='phoneNumber'
                        name='phoneNumber'
                        type='tel'
                        label={translate('form_labels.number')}
                        variant='outlined'
                        value={props.state.fields.phoneNumber}
                        onChange={e => handlePhoneChange(e.target.value)}
                        error={!!props.state.errors.phoneNumber}
                        helperText={props.state.errors.phoneNumber} />
                </Stack>

                <h2>{translate('steps.common.documents')} *</h2>

                <Stack spacing={2}>
                    {props.state.errors.missingDocuments &&
                        <Alert severity="error">
                            {translate('validation_errors.documents_required')}
                            {props.state.errors.missingDocuments}
                        </Alert>
                    }

                    {(props.state.uploadedDocuments?.length > 0) &&
                        <UploadedDocuments documents={props.state.uploadedDocuments} onDelete={handleDeleteDocument} />
                    }

                    {(props.state.uploadedDocuments?.length === 0) &&
                        <>
                            <SelectField
                                id="documentType"
                                name="documentType"
                                onChange={handleChangeDocumentType}
                                value={props.state.fields.documentType}
                                label={translate('form_labels.document_type')}
                                items={[
                                    { value: DocumentType.CompanyRegistration, description: translate(`steps.common.document_types.${DocumentType.CompanyRegistration}`) }
                                ]} />

                            {props.state.fields.documentType &&
                                <>
                                    <Alert severity="info">
                                        {props.state.fields.documentType === DocumentType.CompanyRegistration && translate('steps.business_profile.company_registration_explanation')}
                                    </Alert>

                                    <FormControl
                                        error={!!props.state?.errors?.document}>
                                        {props.state.errors.document &&
                                            <FormHelperText>{props.state.errors.document}</FormHelperText>
                                        }
                                        <Dropzone
                                            onDrop={(files => handleDropFile(files[0]))}
                                            maxFileSize={4194304}
                                            footer={false}
                                            header={false}
                                            label={translate('form_labels.file_upload')}>
                                        </Dropzone>
                                    </FormControl>
                                </>
                            }
                        </>
                    }
                </Stack>
            </div>

            <div className="wizard-step-btn">
                <Button id="goBackBtn" color='secondary' size='large' disabled={props.state.isLoading} onClick={props.onBack}>{translate('buttons.back')}</Button>
                <Button id="continueBtn" variant='contained' color='secondary' size='large' type='submit' disabled={props.state.isLoading} onClick={handleSubmit}>{translate('buttons.continue')}</Button>
            </div>
        </div>
    );
};

export default BusinessProfileStep;

interface IProps {
    resourceToken: string;
    state: IBusinessProfileState;
    onBack: () => void;
    onCompleted: (result: ISubmitApplicationResult) => void;
    onError: (message?: string) => void;
    onStateChanged: (state: IBusinessProfileState) => void;
}

export interface IBusinessProfileState {
    countries: ICountry[],
    districtCourts: IItem[],
    fields: IFields;
    errors: IErrors;
    countryCode: string;
    merchantCategories: IItem[];
    operationUrl?: string;
    uploadDocumentOperationUrl?: string;
    uploadedDocuments: IDocument[];
    form?: IBusinessProfileForm;
    isLoading: boolean;
}

interface IFields {
    legalName?: string;
    tradingName?: string;
    registrationNumber?: string;
    districtCourtCode?: string;
    vatNumber?: string;
    line1?: string;
    line2?: string;
    houseNumber?: string;
    townCity?: string;
    state?: string;
    zip?: string;
    mcc?: string;
    website?: string;
    countryPrefix?: string;
    phoneNumber?: string;
    documentType?: DocumentType;
}

interface IErrors {
    legalName?: string;
    tradingName?: string;
    registrationNumber?: string;
    districtCourtCode?: string;
    vatNumber?: string;
    line1?: string;
    line2?: string;
    houseNumber?: string;
    townCity?: string;
    state?: string;
    zip?: string;
    mcc?: string;
    website?: string;
    countryPrefix?: string;
    phoneNumber?: string;
    document?: string;
    missingDocuments?: ReactNode;
}
