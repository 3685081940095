import { handleKoResponse } from "./common/fetchHelper";
import { ILinks, Role } from "./commonTypes";

export async function addRepresentative(resourceToken: string, representative: IAddRepresentative) {
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authorization', `bearer ${resourceToken}`);

    const representativeDto = mapToDto(representative);

    const requestUrl = `${process.env.REACT_APP_API_URL}/application/representatives`;
    const requestOptions: RequestInit = {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(representativeDto)
    };

    const response = await fetch(requestUrl, requestOptions);
    await handleKoResponse(response);

    const resultDto = (await response.json()) as IAddRepresentativeResponseDto;
    const result: IAddRepresentativeResponse = {
        id: resultDto.id
    };

    return result;
}

const mapToDto = (representative: IAddRepresentative): IAddRepresentativeDto => {
    return {
        first_name: representative.firstName,
        last_name: representative.lastName,
        email: representative.email,
        roles: representative.roles,
        date_of_birth: representative.dateOfBirth,
        nationality: representative.nationality,
        pep: representative.pep,
        pep_description: representative.pepDescription
    };
}

// Types

export interface IAddRepresentative {
    firstName: string;
    lastName: string;
    email: string;
    roles: Role[];
    dateOfBirth: string;
    nationality: string;
    pep: boolean;
    pepDescription?: string;
}

export interface IAddRepresentativeResponse {
    id: string;
}

// DTO types (in snake_case)

interface IAddRepresentativeDto {
    first_name: string;
    last_name: string;
    email: string;
    roles: Role[];
    date_of_birth?: string;
    nationality?: string;
    pep: boolean;
    pep_description?: string;
}

interface IAddRepresentativeResponseDto {
    id: string;
    status: string;
    _links: ILinks
}
