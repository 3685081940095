import logo from '../../../resources/images/logo.png';
import { useTranslation } from "react-i18next";
import './Welcome.scss'

const Welcome = () => {
    const { t } = useTranslation();

    return (
        <div className='welcome-page-container'>
            <img src={logo} alt="Getpaid logo" />
            <p>{t('welcome_message')}</p>
        </div>
    );
}

export default Welcome;
