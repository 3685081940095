import { mapToSubmitApplicationResult } from "../helpers/applicationHelper";
import { handleKoResponse } from "./common/fetchHelper";
import { ISubmitApplicationResultDto, Role } from "./commonTypes";

export async function updateApplicantDetails(requestUrl: string, resourceToken: string, applicantDetails: IApplicantDetails) {
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authorization', `bearer ${resourceToken}`);

    const applicantDetailsDto = mapDoDto(applicantDetails);

    const requestOptions: RequestInit = {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(applicantDetailsDto)
    };

    const response = await fetch(requestUrl, requestOptions);
    await handleKoResponse(response);

    const resultDto = (await response.json()) as ISubmitApplicationResultDto;
    const result = mapToSubmitApplicationResult(resultDto);

    return result;
}

const mapDoDto = (applicantDetails: IApplicantDetails): IApplicantDetailsDto => {
    return {
        nationality: applicantDetails.nationality,
        gender: applicantDetails.gender,
        date_of_birth: applicantDetails.dateOfBirth,
        pep: applicantDetails.pep,
        pep_description: applicantDetails.pepDescription,
        roles: applicantDetails.roles
    };
};

// Types

export interface IApplicantDetails {
    nationality: string;
    gender: string;
    dateOfBirth: string;
    pep: boolean;
    pepDescription?: string;
    roles: Role[];
}

// DTO types (in snake_case)

interface IApplicantDetailsDto {
    nationality: string;
    gender: string;
    date_of_birth: string;
    pep: boolean;
    pep_description?: string;
    roles: Role[];
}
