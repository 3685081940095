import { handleKoResponse } from "./common/fetchHelper";

export async function deleteFile(resourceToken: string, requestUrl: string) {
    const headers = new Headers();
    headers.set('Authorization', `bearer ${resourceToken}`);

    const requestOptions: RequestInit = {
        method: 'DELETE',
        headers: headers,
    };

    const response = await fetch(requestUrl, requestOptions);

    handleKoResponse(response);
}
