import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useEffect } from "react";

const SelectField = (props: IProps) => {
    useEffect(() => {

    }, []);

    return (
        <FormControl
            error={!!props.errorMessage}
            required={props.required}
            disabled={props.disabled}>
            <InputLabel
                id={props.id}
                required={props.required}>
                {props.label}
            </InputLabel>
            <Select
                labelId={props.id}
                name={props.name}
                required={props.required}
                value={props.value || ''}
                onChange={props.onChange}
                label={props.label}
                error={!!props.errorMessage}>
                {
                    props.items.map((item, index) => {
                        return (
                            <MenuItem value={item.value} key={`${props.id}-${index}`}>{item.description}</MenuItem>
                        );
                    })
                }
            </Select>
            {props.errorMessage &&
                <FormHelperText>{props.errorMessage}</FormHelperText>
            }
        </FormControl>
    );
};

export default SelectField;

interface IProps {
    id: string;
    name: string;
    label: string;
    items: IItem[];
    required?: boolean;
    disabled?: boolean;
    value?: string;
    errorMessage?: string;
    onChange: (event: SelectChangeEvent<any>) => void;
}

export interface IItem {
    value: string;
    description: string;
}
