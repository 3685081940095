import { handleKoResponse } from "./common/fetchHelper";
import { DocumentSide, DocumentType, ILinks, ISubmitApplicationResult, ISubmitApplicationResultDto, Role } from "./commonTypes";

export async function getApplication(resourceToken: string) {
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authorization', `bearer ${resourceToken}`);

    const requestUrl = `${process.env.REACT_APP_API_URL}/application`;
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: headers
    };

    const response = await fetch(requestUrl, requestOptions);
    await handleKoResponse(response);

    const getApplicationResultDto = (await response.json()) as IGetApplicationResultDto;
    const getApplicationResult = mapFromDto(getApplicationResultDto)

    return getApplicationResult;
}

function mapFromDto(resultDto: IGetApplicationResultDto): IGetApplicationResult {
    const result: IGetApplicationResult = {
        id: resultDto.id,
        language: resultDto.language,
        applicant: resultDto.applicant && {
            email: resultDto.applicant.email,
            firstName: resultDto.applicant.first_name,
            lastName: resultDto.applicant.last_name,
            nationality: resultDto.applicant.nationality,
            gender: resultDto.applicant.gender,
            dateOfBirth: resultDto.applicant.date_of_birth,
            roles: resultDto.applicant.roles,
            pep: resultDto.applicant.pep,
            pepDescription: resultDto.applicant.pep_description,
            documents: resultDto.applicant.documents?.map<IDocument>(d => { return {id: d.id, name: d.name, type: d.type, side: d.side, links: d._links} })
        },
        platform: {
            logoUrl: resultDto.platform.logo_url,
            name: resultDto.platform.name,
            colors: {
                primaryColor: resultDto.platform.colors.primary_color,
                secondaryColor: resultDto.platform.colors.secondary_color
            }
        },
        business: resultDto.business && {
            countryCode: resultDto.business.country_code,
            type: resultDto.business.type,
            structure: resultDto.business.structure,
            profile: resultDto.business.profile && {
                legalName: resultDto.business.profile.legal_name,
                tradingName: resultDto.business.profile.trading_name,
                registrationNumber: resultDto.business.profile.registration_number,
                districtCourtCode: resultDto.business.profile.district_court_code,
                vatNumber: resultDto.business.profile.vat_number,
                website: resultDto.business.profile.website,
                mcc: resultDto.business.profile.mcc,
                registeredAddress: resultDto.business.profile.registered_address && {
                    line1: resultDto.business.profile.registered_address.line1,
                    line2: resultDto.business.profile.registered_address.line2,
                    number: resultDto.business.profile.registered_address.number,
                    townCity: resultDto.business.profile.registered_address.town_city,
                    state: resultDto.business.profile.registered_address.state,
                    zip: resultDto.business.profile.registered_address.zip
                },
                phoneNumber: resultDto.business.profile.phone_number && {
                    countryCode: resultDto.business.profile.phone_number.country_code,
                    number: resultDto.business.profile.phone_number.number
                }
            },
            representatives: resultDto.business.representatives && resultDto.business.representatives.map(r => {
                return {
                    id: r.id,
                    firstName: r.first_name,
                    lastName: r.last_name,
                    email: r.email,
                    nationality: r.nationality,
                    dateOfBirth: r.dateOfBirth,
                    pep: r.pep,
                    pepDescription: r.pep_description,
                    roles: r.roles
                }
            })
        },
        payoutAccount: resultDto.payout_account && {
            accountOwnersName: resultDto.payout_account.account_owners_name,
            accountNumber: resultDto.payout_account.account_number && {
                value: resultDto.payout_account.account_number.value,
                type: resultDto.payout_account.account_number.type
            }
        },
        documents: resultDto.documents?.map<IDocument>(d => { return { id: d.id, name: d.name, type: d.type, side: d.side, links: d._links } }),
        workflow: resultDto.workflow,
        links: resultDto._links,
        status: resultDto.status
    };
    return result;
}

export interface IGetApplicationResult extends ISubmitApplicationResult {
    id: string;
    language: string;
    platform: IPlatform;
    applicant: IApplicant;
    business?: IBusiness;
    payoutAccount?: IPayoutAccount;
    documents?: IDocument[]
}

export interface IPlatform {
    name: string;
    logoUrl: string;
    colors: IPlatformColors;
}

interface IPlatformColors {
    primaryColor: string;
    secondaryColor: string;
}

interface IApplicant {
    firstName?: string;
    lastName?: string;
    email: string;
    nationality?: string;
    gender?: string;
    dateOfBirth?: string;
    roles?: Role[];
    pep?: boolean;
    pepDescription?: string;
    documents?: IDocument[];
}

interface IBusiness {
    countryCode: string;
    type: string;
    structure: string;
    profile?: IBusinessProfile;
    representatives?: IRepresentative[];
}

interface IBusinessProfile {
    legalName: string;
    tradingName?: string;
    registrationNumber: string;
    districtCourtCode?: string;
    vatNumber?: string;
    registeredAddress?: IRegisteredAddress;
    mcc: string;
    website: string;
    phoneNumber?: IPhoneNumber
}

interface IRegisteredAddress {
    line1: string;
    line2?: string;
    number: string;
    townCity: string;
    state?: string;
    zip: string;
}

interface IPhoneNumber {
    countryCode?: string;
    number?: string;
}

interface IRepresentative {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    nationality: string;
    dateOfBirth: string;
    pep: boolean;
    pepDescription?: string;
    roles: Role[];
}

interface IPayoutAccount {
    accountOwnersName: string,
    accountNumber: IAccountNumber
}

interface IAccountNumber {
    type?: string,
    value?: string
}

export interface IDocument {
    id: string,
    name: string,
    type: DocumentType,
    side?: DocumentSide,
    links: ILinks
}


// DTO types (in snake_case)

interface IGetApplicationResultDto extends ISubmitApplicationResultDto {
    id: string;
    language: string;
    platform: IPlatformDto;
    applicant: IApplicantDto;
    business?: IBusinessDto;
    payout_account?: IPayoutAccountDto;
    documents?: IDocumentDto[];
}

interface IPayoutAccountDto {
    account_owners_name: string,
    account_number: IAccountNumberDto
}

interface IAccountNumberDto {
    type: string,
    value: string
}

interface IPlatformDto {
    name: string;
    logo_url: string;
    colors: IPlatformColorsDto;
}

interface IPlatformColorsDto {
    primary_color: string;
    secondary_color: string;
}

interface IApplicantDto {
    first_name?: string;
    last_name?: string;
    email: string;
    nationality?: string;
    gender?: string;
    date_of_birth?: string;
    roles?: Role[];
    pep?: boolean;
    pep_description?: string;
    documents?: IDocumentDto[];
}

interface IBusinessDto {
    country_code: string;
    type: string;
    structure: string;
    profile: IBusinessProfileDto;
    representatives: IRepresentativeDto[];
}

interface IBusinessProfileDto {
    legal_name: string;
    trading_name?: string;
    registration_number: string;
    district_court_code?: string;
    vat_number?: string;
    registered_address?: IRegisteredAddressDto;
    mcc: string;
    website: string;
    phone_number?: IPhoneNumberDto
}

interface IRegisteredAddressDto {
    line1: string;
    line2?: string;
    number: string;
    town_city: string;
    state?: string;
    district?: string;
    zip: string;
}

interface IPhoneNumberDto {
    country_code?: string;
    number?: string;
}

interface IRepresentativeDto {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    nationality: string;
    dateOfBirth: string;
    pep: boolean;
    pep_description?: string;
    roles: Role[];
    _links: ILinks;
}

interface IDocumentDto {
    id: string,
    name: string,
    type: DocumentType;
    side?: DocumentSide
    _links: ILinks
}
