import { Button } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { StepKey } from "../../../../../helpers/applicationHelper";
import { submitApplication } from "../../../../../services/submitApplicationService";
import { IApplication } from "../../OnBoarding";
import ApplicantDetailsSummary from "./ApplicantDetailsSummary/ApplicantDetailsSummary";
import BusinessProfileSummary from "./BusinessProfileSummary/BusinessProfileSummary";
import BusinessTypeSummary from "./BusinessTypeSummary/BusinessTypeSummary";
import PayoutAccountSummary from "./PayoutAccountSummary/PayoutAccountSummary";
import ProfileSummary from "./ProfileSummary/ProfileSummary";
import RepresentativesSummary from "./RepresentativesSummary/RepresentativesSummary";
import './SummaryStep.scss';
import { LinkRelation } from "../../../../../services/commonTypes";

const SummaryStep = (props: IProps) => {
    const { t } = useTranslation();

    const handleSubmit = async () => {
        props.onStateChanged({ ...props.applicationState.summaryState!, isLoading: true });
        try {
            const result = await submitApplication(props.applicationState.summaryState!.operationUrl!, props.resourceToken);
            if (!result.workflow.complete) {
                throw new Error();
            }

            const redirectLink = result.links[LinkRelation.Redirect];
            if (redirectLink) {
                window.location.href = redirectLink.href;
            } else {
                props.onStateChanged({ ...props.applicationState.summaryState!, isLoading: false, alreadySubmitted: true });
                props.onCompleted();
            }
        } catch (error) {
            console.log(error);
            props.onError();
            props.onStateChanged({ ...props.applicationState.summaryState!, isLoading: false });
        }
    };

    return (
        <div className="summary-step-container">
            {props.applicationState.summaryState!.alreadySubmitted &&
                <>
                    <div className="wizard-step-introduction">
                        <h1 id="wizardCompletedHeading">{t('steps.summary.submit_completed')}</h1>
                        <p><Trans i18nKey='steps.summary.submit_completed_description_1' values={{ platformName: props.applicationState.platform.name }} /></p>
                        <p><Trans i18nKey='steps.summary.submit_completed_description_2' /></p>
                    </div>
                </>
            }
            {!props.applicationState.summaryState!.alreadySubmitted &&
                <>
                    <div className="wizard-step-introduction">
                        <h2>{t('steps.summary.introduction')}</h2>
                        <p>{t('steps.summary.summary')}</p>
                    </div>

                    <ProfileSummary state={props.applicationState.profileState!} onEdit={() => props.onNavigate(StepKey.Profile)} />
                    <ApplicantDetailsSummary state={props.applicationState.applicantDetailsState!} onEdit={() => props.onNavigate(StepKey.ApplicantDetails)} />
                    <BusinessTypeSummary state={props.applicationState.businessTypeState!} onEdit={() => props.onNavigate(StepKey.BusinessType)} />
                    <BusinessProfileSummary state={props.applicationState.businessProfileState!} onEdit={() => props.onNavigate(StepKey.BusinessProfile)} />
                    <RepresentativesSummary state={props.applicationState.representativesState!} onEdit={() => props.onNavigate(StepKey.Representatives)} />
                    <PayoutAccountSummary state={props.applicationState.payoutAccountState!} onEdit={() => props.onNavigate(StepKey.Payouts)} />

                    <div className="wizard-step-btn">
                        <Button id="goBackBtn" color='secondary' size='large' disabled={props.applicationState.summaryState!.isLoading} onClick={props.onBack}>{t('buttons.back')}</Button>
                        <Button id="continueBtn" variant='contained' color='secondary' size='large' disabled={props.applicationState.summaryState!.isLoading} onClick={handleSubmit}>{t('buttons.submit')}</Button>
                    </div>
                </>
            }
        </div>
    );
};

export default SummaryStep;

interface IProps {
    resourceToken: string;
    applicationState: IApplication;
    onBack: () => void;
    onNavigate: (stepKey: StepKey) => void;
    onStateChanged: (state: ISummaryState) => void;
    onError: (message?: string) => void;
    onCompleted: () => void;
}

export interface ISummaryState {
    operationUrl?: string;
    isLoading: boolean;
    alreadySubmitted: boolean;
}
