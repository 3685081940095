import { mapToSubmitApplicationResult } from "../helpers/applicationHelper";
import { ValidationErrorResponse, handleKoResponse } from "./common/fetchHelper";
import { ISubmitApplicationResultDto } from "./commonTypes";

export async function updatePayoutAccount(requestUrl: string, resourceToken: string, payoutAccount: IPayoutAccount) {
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authorization', `bearer ${resourceToken}`);

    const requestOptions: RequestInit = {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(mapToDto(payoutAccount))
    };

    const response = await fetch(requestUrl, requestOptions);

    if (response.status === 422) {
        return (await response.json()) as ValidationErrorResponse;
    }

    await handleKoResponse(response);

    const resultDto = (await response.json()) as ISubmitApplicationResultDto;
    const result = mapToSubmitApplicationResult(resultDto);

    return result;
}

export interface IPayoutAccount {
    accountNumber: IPayoutAccountNumber;
    accountOwnersName: string;
    currency: 'EUR'
}

function mapToDto(source: IPayoutAccount): IPayoutAccountDto {
    return {
        account_number: source.accountNumber,
        account_owners_name: source.accountOwnersName,
        currency: source.currency
    };
}

interface IPayoutAccountNumber {
    type: "iban",
    value: string
}

interface IPayoutAccountDto {
    account_number: IPayoutAccountNumber;
    account_owners_name: string;
    currency: 'EUR'
}
