import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import OnBoarding from './components/pages/OnBoarding/OnBoarding';
import Welcome from './components/pages/Welcome/Welcome';
import enTranslations from './resources/lang/en.json';
import esTranslations from './resources/lang/es.json';
import deTranslations from './resources/lang/de.json';
import './App.scss';

const App = () => {
  i18n
    .use(initReactI18next)
    .init({
      lng: "en",
      fallbackLng: "en",
      resources: {
        en: {
          translation: enTranslations
        },
        es: {
          translation: esTranslations
        },
        de: {
          translation: deTranslations
        }
      },
      interpolation: {
        escapeValue: false
      }
    });

  return (
    <div className="app-container">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="/:resourceToken" element={<OnBoarding />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
