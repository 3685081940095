import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, useMediaQuery, useTheme } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";
import { PropsWithChildren } from "react";

const CustomDialog = (props: PropsWithChildren<IProps>) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            open={props.isVisible}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
            fullScreen={fullScreen}
        >
            <DialogTitle id="alert-dialog-title">
                {props.title}

                <IconButton
                    edge="end"
                    color="inherit"
                    sx={{ float: "right" }}
                    onClick={props.onClose}
                    aria-label="close"
                    size="small">
                    <CloseIcon fontSize="small" />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {props.children}
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} autoFocus>
                    {t('buttons.close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CustomDialog;

interface IProps {
    isVisible: boolean,
    onClose: () => void,
    title: string,
    'aria-labelledby'?: string,
    'aria-describedby'?: string
}
