import { Dropzone, FileValidated } from "@dropzone-ui/react";
import { Alert, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, Link, Stack } from "@mui/material";
import { ChangeEvent, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { getRoles, getDocumentTypeText, getDocumentSideText } from "../../../../../helpers/applicationHelper";
import { IDocument } from "../../../../../services/getApplicationService";
import { IApplicantDetails, updateApplicantDetails } from "../../../../../services/updateApplicantDetailsService";
import { uploadFile } from "../../../../../services/uploadFileService";
import SelectField from "../../../../shared/SelectField/SelectField";
import moment, { Moment } from 'moment';
import { deleteFile } from "../../../../../services/deleteFileService";
import RepresentativeData from "../../Common/RepresentativeData/RepresentativeData";
import UploadedDocuments from "../../Common/UploadedDocuments/UploadedDocuments";
import { ICountry } from "../../../../../services/getCountriesService";
import CustomDialog from "../../../../shared/CustomDialog/CustomDialog";
import VerificationTipsBody from "./Dialogs/VerificationTips/VerificationTipsBody";
import TermsAndConditionsBodyEN from "./Dialogs/TermsAndConditions/TermsAndConditionsBodyEn";
import DataProtectionPolicyBodyEn from "./Dialogs/DataProtectionPolicy/DataProtectionPolicyBodyEn";
import { DocumentSide, DocumentType, ISubmitApplicationResult } from "../../../../../services/commonTypes";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import "./ApplicantDetailsStep.scss";

const ApplicantDetailsStep = (props: IProps) => {
    const { t: translate } = useTranslation();

    const handleFieldChange = (fieldName: string, value: any) => {
        props.onStateChanged(
            {
                ...props.state,
                fields: {
                    ...props.state.fields!,
                    [fieldName]: value
                }
            });
    };

    const handleDropFile = async (file: FileValidated) => {
        let uploadedFileResponse = await uploadFile(props.state.uploadDocumentOperationUrl!, props.resourceToken, file.file, props.state.fields.documentType!, props.state.fields.documentSide || 'front');

        if (!uploadedFileResponse.success) {
            const error = uploadedFileResponse.errors.file;

            if (error) {
                let errorMessage = translate('backend_validation_errors.' + error[0], { "supportedFileTypes": "JPEG/JPG, PNG or PDF", "maxFileSize": "4MB" });
                props.onStateChanged({
                    ...props.state,
                    errors: { ...props.state.errors, file: errorMessage }
                });
            }

            return;
        }

        const document: IDocument = {
            id: uploadedFileResponse.document.id,
            name: file.file.name,
            side: props.state.fields.documentSide!,
            type: props.state.fields.documentType!,
            links: uploadedFileResponse.document.links
        };

        const documents = [...props.state.uploadedDocuments, document];

        props.onStateChanged({
            ...props.state,
            uploadedDocuments: documents,
            errors: { ...props.state.errors, file: undefined },
            fields: { ...props.state.fields!, documentType: undefined, documentSide: undefined }
        });
    }

    const handleDeleteDocument = async (document: IDocument) => {
        await deleteFile(props.resourceToken, document.links.self.href);
        const documents = [...props.state.uploadedDocuments];
        const toDelete = documents.indexOf(document);

        documents.splice(toDelete, 1);

        props.onStateChanged({
            ...props.state,
            uploadedDocuments: documents
        });
    };

    const isMissingDocument = (documentType: DocumentType, side?: DocumentSide) => !props.state.uploadedDocuments.some(d => d.type === documentType && (!side || d.side === side));

    const isMissingSelfieWithId = () => isMissingDocument(DocumentType.SelfieWithId);
    const isMissingPassport = () => isMissingDocument(DocumentType.Passport);
    const isMissingId = () => isMissingDocument(DocumentType.Id, DocumentSide.Front) || isMissingDocument(DocumentType.Id, DocumentSide.Back);
    const isMissingDrivingLicense = () => isMissingDocument(DocumentType.DrivingLicense, DocumentSide.Front) || isMissingDocument(DocumentType.DrivingLicense, DocumentSide.Back);

    const isMissingIdentification = () => isMissingPassport() && isMissingId() && isMissingDrivingLicense();

    const getAvailableDocumentsTypes = () => {
        let documents = [];

        isMissingPassport() && documents.push({ value: DocumentType.Passport, description: translate(`steps.common.document_types.${DocumentType.Passport}`) });
        isMissingId() && documents.push({ value: DocumentType.Id, description: translate(`steps.common.document_types.${DocumentType.Id}`) });
        isMissingDrivingLicense() && documents.push({ value: DocumentType.DrivingLicense, description: translate(`steps.common.document_types.${DocumentType.DrivingLicense}`) });
        isMissingSelfieWithId() && documents.push({ value: DocumentType.SelfieWithId, description: translate(`steps.common.document_types.${DocumentType.SelfieWithId}`) });

        return documents;
    };

    const getAvailableDocumentSides = (documentType : DocumentType) => {
        let sides = [];

        isMissingDocument(documentType, DocumentSide.Front) && sides.push({ value: DocumentSide.Front, description: getDocumentSideText(DocumentSide.Front, translate) });
        isMissingDocument(documentType, DocumentSide.Back) && sides.push({ value: DocumentSide.Back, description: getDocumentSideText(DocumentSide.Back, translate) });

        return sides;
    };

    const isFormValid = () => {
        let errors: IErrors = {};

        if (!props.state.fields.gender) {
            errors.gender = translate('validation_errors.required');
        }

        if (!props.state.fields.nationality) {
            errors.nationality = translate('validation_errors.required');
        }

        if (!props.state.fields.dateOfBirth) {
            errors.dateOfBirth = translate('validation_errors.required');
        } else if (props.state.fields.dateOfBirth.isAfter(moment())) {
            errors.dateOfBirth = translate('validation_errors.not_allowed_date');
        } else if (props.state.fields.dateOfBirth.isAfter(moment().subtract(16, 'years'))) {
            errors.dateOfBirth = translate('validation_errors.not_reached_min_age', { age: 16 });
        }

        if (!props.state.fields.isDirector && !props.state.fields.isOwner) {
            errors.roles = translate('validation_errors.invalid_role_selection')
        }

        if (props.state.fields.pep) {
            const pepDescriptionMaxLength = 300;

            if (!props.state.fields.pepDescription) {
                errors.pepDescription = translate('validation_errors.required');
            } else if (props.state.fields.pepDescription.length > pepDescriptionMaxLength) {
                errors.pepDescription = translate('validation_errors.max_length_reached', { max: pepDescriptionMaxLength });
            }
        }

        if (!props.state.fields.termsAndConditionsAccepted) {
            errors.termsAndConditionsAccepted = translate('validation_errors.required');
        }

        if (!props.state.fields.dataProtectionPolicyAccepted) {
            errors.dataProtectionPolicyAccepted = translate('validation_errors.required');
        }

        if (isMissingSelfieWithId())
        {
            errors.missingSelfieWithId = true;
        }

        if (isMissingIdentification())
        {
            errors.missingIdentification = true;
        }

        props.onStateChanged({ ...props.state, errors: errors });

        return (Object.keys(errors).length === 0);
    };

    const handleSubmit = async () => {
        const isValid = isFormValid();
        if (!isValid) {
            return;
        }

        props.onStateChanged({ ...props.state, isLoading: true });

        try {
            const roles = getRoles(props.state.fields.isOwner, props.state.fields.isDirector);
            const applicantDetails: IApplicantDetails = {
                nationality: props.state.fields.nationality!,
                gender: props.state.fields.gender!,
                dateOfBirth: props.state.fields.dateOfBirth!.format('YYYY-MM-DD'),
                pep: props.state.fields.pep,
                pepDescription: props.state.fields.pepDescription,
                roles: roles
            };

            const result = await updateApplicantDetails(props.state.operationUrl!, props.resourceToken, applicantDetails);
            props.onCompleted(result);

        } catch (error) {
            console.log(error);
            props.onError();
        }

        props.onStateChanged({
            ...props.state,
            isLoading: false,
            errors: {}
        });
    };

    const openTermsAndConditions = (e: React.MouseEvent<HTMLAnchorElement>) => {
        props.onStateChanged({ ...props.state, isTermsAndConditionsVisible: true });
        e.preventDefault();
    };

    const openDataProtectionPolicy = (e: React.MouseEvent<HTMLAnchorElement>) => {
        props.onStateChanged({ ...props.state, isDataProtectionPolicyVisible: true });
        e.preventDefault();
    };

    const renderDocumentStatus = (missingDocument: boolean) => {
        return (missingDocument
            ? <RadioButtonUncheckedIcon fontSize="inherit" />
            : <CheckCircleIcon fontSize="inherit" />
        );
    };

    useEffect(() => {
        let documentSide = undefined;

        if (props.state.fields.documentType === DocumentType.SelfieWithId ||
            props.state.fields.documentType === DocumentType.Passport) {
            documentSide = DocumentSide.Front;
        }

        props.onStateChanged({
            ...props.state,
            fields: { ...props.state.fields!, documentSide: documentSide }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.state.fields.documentType]);

    useEffect(() => {
        if (!props.state.fields.pep) {
            props.onStateChanged({
                ...props.state,
                fields: { ...props.state.fields!, pepDescription: '' }
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.state.fields.pep]);

    return (
        <div className='applicant-details-step-container wizard-step-fields'>
            <h2>{translate('steps.applicant_details.introduction')}</h2>
            <Stack spacing={2}>
                <SelectField
                    id='gender'
                    name='gender'
                    label={translate('form_labels.gender')}
                    value={props.state.fields.gender}
                    onChange={(ev) => handleFieldChange(ev.target.name, ev.target.value)}
                    errorMessage={props.state.errors?.gender}
                    items={[
                        { value: 'male', description: translate('steps.applicant_details.gender.male') },
                        { value: 'female', description: translate('steps.applicant_details.gender.female') },
                        { value: 'na', description: translate('steps.applicant_details.gender.na') }
                    ]}
                    required />

                <RepresentativeData
                    fields={{
                        nationality: props.state.fields.nationality,
                        dateOfBirth: props.state.fields.dateOfBirth,
                        pep: props.state.fields.pep,
                        pepDescription: props.state.fields.pepDescription,
                        isDirector: props.state.fields.isDirector,
                        isOwner: props.state.fields.isOwner
                    }}
                    errors={{
                        nationality: props.state.errors.nationality,
                        dateOfBirth: props.state.errors.dateOfBirth,
                        pepDescription: props.state.errors.pepDescription,
                        roles: props.state.errors.roles
                    }}
                    countries={props.state.countries}
                    handleFieldChange={handleFieldChange} />
            </Stack>

            <h2>{translate('steps.common.documents')} *</h2>
            <Stack spacing={2}>

                <Alert severity={props.state.errors.missingIdentification || props.state.errors.missingSelfieWithId ? "error" : "info"}>
                    {translate('validation_errors.documents_required')}

                    <div style={{ marginTop: '10px' }}>
                        <Stack spacing={1} direction="row">
                            {renderDocumentStatus(isMissingSelfieWithId())}
                            <div>{getDocumentTypeText(DocumentType.SelfieWithId, translate)}</div>
                        </Stack>

                        <Stack spacing={1} direction="row">
                            {renderDocumentStatus(isMissingIdentification())}
                            <div>{`${getDocumentTypeText(DocumentType.Id, translate, true)}, ${getDocumentTypeText(DocumentType.Passport, translate)}, ${translate('steps.common.or')} ${getDocumentTypeText(DocumentType.DrivingLicense, translate, true)}`}</div>
                        </Stack>
                    </div>
                </Alert>

                {(props.state?.uploadedDocuments?.length > 0) &&
                    <UploadedDocuments documents={props.state.uploadedDocuments} onDelete={handleDeleteDocument} showSide />
                }

                <SelectField
                    id='documentType'
                    name='documentType'
                    label={translate('form_labels.document_type')}
                    value={props.state.fields.documentType}
                    onChange={(ev) => handleFieldChange(ev.target.name, ev.target.value)}
                    items={getAvailableDocumentsTypes()} />

                {(props.state.fields.documentType === DocumentType.Id || props.state.fields.documentType === DocumentType.DrivingLicense) &&
                    <>
                        <Alert severity="info">{translate('steps.applicant_details.upload_both_sides')}</Alert>

                        <SelectField
                            id='documentSide'
                            name='documentSide'
                            label={translate('form_labels.document_side')}
                            value={props.state.fields.documentSide}
                            onChange={(ev) => handleFieldChange(ev.target.name, ev.target.value)}
                            items={getAvailableDocumentSides(props.state.fields.documentType)} />
                    </>
                }

                {props.state.fields.documentSide &&
                    <>
                        <FormControl
                            error={!!props.state?.errors?.file}>
                            <Dropzone
                                onDrop={(files => handleDropFile(files[0]))}
                                maxFileSize={4194304}
                                footer={false}
                                header={false}
                                label={translate('form_labels.file_upload')}
                                minHeight="150px">
                            </Dropzone>
                            <FormHelperText>
                                <Link component="button" variant="inherit" onClick={() => props.onStateChanged({ ...props.state, isTipsDialogVisible: true })}>{translate('steps.applicant_details.verification_tips')}</Link>
                            </FormHelperText>
                            {props.state.errors.file &&
                                <FormHelperText>{props.state.errors.file}</FormHelperText>
                            }
                        </FormControl>

                        <CustomDialog isVisible={props.state.isTipsDialogVisible} onClose={() => props.onStateChanged({ ...props.state, isTipsDialogVisible: false })} title={translate('steps.applicant_details.verification_tips')}>
                            <VerificationTipsBody />
                        </CustomDialog>
                    </>
                }
            </Stack>

            <div className='applicant-details-step-checks'>
                <FormControl required error={!!props.state.errors.termsAndConditionsAccepted} onChange={(ev: ChangeEvent<HTMLInputElement>) => { handleFieldChange(ev.target.name, ev.target.checked) }}>
                    <FormControlLabel
                        id='termsAndConditionsAccepted'
                        name='termsAndConditionsAccepted'
                        control={<Checkbox checked={props.state.fields.termsAndConditionsAccepted} inputProps={{ 'aria-label': 'termsAndConditionsAccepted' }} required />}
                        label={
                            <Trans i18nKey="steps.applicant_details.terms_and_conditions_acceptance">
                                I accept the <Link component="a" onClick={openTermsAndConditions}>General Terms and Conditions</Link>
                            </Trans>
                        } />
                    <FormHelperText>{props.state.errors.termsAndConditionsAccepted}</FormHelperText>
                </FormControl>

                <FormControl required error={!!props.state.errors.dataProtectionPolicyAccepted} onChange={(ev: ChangeEvent<HTMLInputElement>) => { handleFieldChange(ev.target.name, ev.target.checked) }}>
                    <FormControlLabel
                        id='dataProtectionPolicyAccepted'
                        name='dataProtectionPolicyAccepted'
                        control={<Checkbox checked={props.state.fields.dataProtectionPolicyAccepted} inputProps={{ 'aria-label': 'dataProtectionPolicyAccepted' }} required />}
                        label={
                            <Trans i18nKey="steps.applicant_details.data_protection_policy_acceptance">
                                I accept the <Link component="a" onClick={openDataProtectionPolicy}>Data Protection Policy</Link>
                            </Trans>
                        } />
                    <FormHelperText>{props.state.errors.dataProtectionPolicyAccepted}</FormHelperText>
                </FormControl>
            </div>

            <div className="wizard-step-btn">
                <Button id="goBackBtn" color='secondary' size='large' disabled={props.state.isLoading} onClick={props.onBack}>{translate('buttons.back')}</Button>
                <Button id="continueBtn" variant='contained' color='secondary' size='large' type='submit' disabled={props.state.isLoading} onClick={handleSubmit}>{translate('buttons.continue')}</Button>
            </div>

            <CustomDialog isVisible={props.state.isTermsAndConditionsVisible} onClose={() => props.onStateChanged({ ...props.state, isTermsAndConditionsVisible: false })} title={translate('steps.applicant_details.terms_and_conditions')}>
                <TermsAndConditionsBodyEN />
            </CustomDialog>

            <CustomDialog isVisible={props.state.isDataProtectionPolicyVisible} onClose={() => props.onStateChanged({ ...props.state, isDataProtectionPolicyVisible: false })} title={translate('steps.applicant_details.data_protection_policy')}>
                <DataProtectionPolicyBodyEn />
            </CustomDialog>
        </div >
    );
};

export default ApplicantDetailsStep;

interface IProps {
    resourceToken: string;
    state: IApplicantDetailsState;
    onBack: () => void;
    onCompleted: (result: ISubmitApplicationResult) => void;
    onError: (message?: string) => void;
    onStateChanged: (state: IApplicantDetailsState) => void;
}

export interface IApplicantDetailsState {
    countries: ICountry[],
    operationUrl?: string;
    uploadDocumentOperationUrl?: string;
    fields: IFields;
    errors: IErrors;
    uploadedDocuments: IDocument[];
    isTipsDialogVisible: boolean;
    isTermsAndConditionsVisible: boolean;
    isDataProtectionPolicyVisible: boolean;
    isLoading: boolean;
}

interface IFields {
    nationality?: string;
    gender?: string;
    dateOfBirth: Moment | null;
    isOwner: boolean;
    isDirector: boolean;
    pep: boolean;
    pepDescription?: string;
    documentType?: DocumentType;
    documentSide?: DocumentSide;
    termsAndConditionsAccepted: boolean;
    dataProtectionPolicyAccepted: boolean;
}

interface IErrors {
    nationality?: string;
    gender?: string;
    dateOfBirth?: string;
    roles?: string;
    pepDescription?: string;
    file?: string;
    missingIdentification?: boolean;
    missingSelfieWithId?: boolean;
    termsAndConditionsAccepted?: string;
    dataProtectionPolicyAccepted?: string;
}
