import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, Stack, Switch, TextField } from "@mui/material";
import CountryField from "../../../../shared/CountryField/CountryField";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Moment } from 'moment';
import { useTranslation } from "react-i18next";
import { ChangeEvent } from "react";
import { ICountry } from "../../../../../services/getCountriesService";

const RepresentativeData = (props: IProps) => {
    const { t } = useTranslation();

    return (
        <Stack className='representative-data-container' spacing={2}>
            <CountryField
                id='nationality'
                name='nationality'
                label={t('form_labels.nationality')}
                value={props.fields.nationality}
                errorMessage={props.errors?.nationality}
                onChange={props.handleFieldChange}
                items={props.countries}
                required />

            <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                    label={t('form_labels.date_of_birth')}
                    inputFormat='DD/MM/YYYY'
                    value={props.fields.dateOfBirth}
                    onChange={(value) => { props.handleFieldChange('dateOfBirth', value) }}
                    renderInput={(params: any) => <TextField {...params} id="dateOfBirth" error={!!props.errors.dateOfBirth} helperText={props.errors.dateOfBirth} required />}
                />
            </LocalizationProvider>

            <FormControlLabel
                id='pep'
                name='pep'
                control={<Switch checked={props.fields.pep}
                    onChange={(ev) => props.handleFieldChange(ev.target.name, ev.target.checked)}
                    inputProps={{ 'aria-label': 'isPoliticallyExposedPerson' }} />}
                label={t('form_labels.pep')} />

            {props.fields.pep &&
                <TextField
                    id='pepDescription'
                    name='pepDescription'
                    label={t('form_labels.description')}
                    variant='outlined'
                    value={props.fields.pepDescription}
                    onChange={(ev) => props.handleFieldChange(ev.target.name, ev.target.value)}
                    error={!!props.errors.pepDescription}
                    helperText={props.errors.pepDescription}
                    required />
            }

            <div>
                <h2>
                    {t('steps.common.shareholder_type')}
                    <FormHelperText>{t('steps.common.shareholder_type_help')}</FormHelperText>
                </h2>

                <FormGroup>
                    <FormControl onChange={(ev: ChangeEvent<HTMLInputElement>) => props.handleFieldChange(ev.target.name, ev.target.checked)}>
                        <FormControlLabel
                            id='isOwner'
                            name='isOwner'
                            control={<Checkbox checked={props.fields.isOwner} inputProps={{ 'aria-label': 'isUbo' }} />}
                            label={t('steps.common.ultimate_beneficiary_owner')} />
                        <div className='representatives-step-checkhelper'>
                            <p>{t('steps.common.ubo_definition')}</p>
                            <ul>
                                <li>{t('steps.common.ubo_definition_1')}</li>
                                <li>{t('steps.common.ubo_definition_2')}</li>
                                <li>{t('steps.common.ubo_definition_3')}</li>
                            </ul>
                        </div>
                    </FormControl>

                    <FormControl onChange={(ev: ChangeEvent<HTMLInputElement>) => props.handleFieldChange(ev.target.name, ev.target.checked)}>
                        <FormControlLabel
                            id='isDirector'
                            name='isDirector'
                            control={<Checkbox checked={props.fields.isDirector} inputProps={{ 'aria-label': 'is business director' }} />}
                            label={t('steps.common.business_director')} />
                        <div className='representatives-step-checkhelper'>
                            <p>{t('steps.common.bd_definition')}</p>
                            <ul>
                                <li>{t('steps.common.bd_definition_1')}</li>
                            </ul>
                        </div>
                    </FormControl>

                    <FormHelperText error={!!props.errors.roles}>{props.errors.roles}</FormHelperText>
                </FormGroup>
            </div>
        </Stack>
    );
};

export default RepresentativeData;

export interface IProps {
    countries: ICountry[],
    fields: IFields,
    errors: IErrors,
    handleFieldChange: (fieldName: string, value: any) => void;
}

export interface IFields {
    nationality?: string;
    dateOfBirth: Moment | null;
    pep: boolean;
    pepDescription?: string;
    isOwner: boolean;
    isDirector: boolean;
}

export interface IErrors {
    nationality?: string;
    dateOfBirth?: string;
    pepDescription?: string;
    roles?: string;
}
