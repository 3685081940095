import { useTranslation } from "react-i18next";
import { IProfileState } from "../../ProfileStep/ProfileStep";
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Tooltip } from "@mui/material";

const ProfileSummary = (props: IProps) => {
    const { t } = useTranslation();

    return (
        <div className='profile-summary-container summary-section'>
            <div className='summary-section-header'>
                <h3>{t('steps.profile.name')}</h3>
                <Tooltip title={t('buttons.edit')}>
                    <IconButton aria-label="edit" size="small" onClick={props.onEdit}>
                        <EditIcon fontSize="inherit" />
                    </IconButton>
                </Tooltip>
            </div>

            <div className="summary-section-content summary-section-grid">
                <div>{t('steps.common.full_name')}</div>
                <div>{`${props.state.fields.firstName} ${props.state.fields.lastName}`}</div>
                <div>{t('form_labels.email')}</div>
                <div>{props.state.fields.email}</div>
            </div>
        </div>
    );
};

export default ProfileSummary;

interface IProps {
    state: IProfileState;
    onEdit: () => void;
}
