import { handleKoResponse } from "./common/fetchHelper";

export async function getBusinessTypes(resourceToken: string, countryCode: string) {
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authorization', `bearer ${resourceToken}`);

    const requestUrl = `${process.env.REACT_APP_API_URL}/countries/${countryCode}/business-types`;
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: headers
    };

    const response = await fetch(requestUrl, requestOptions);
    await handleKoResponse(response);

    const businessTypesDto = (await response.json()) as IBusinessTypesDto
    const businessType = mapFromDto(businessTypesDto);

    return businessType;
}

const mapFromDto = (dto: IBusinessTypesDto): IBusinessTypes => {
    return {
        defaultValue: dto.default_value,
        items: dto.items.map(i => mapFromItemDto(i))
    };
};

const mapFromItemDto = (dto: IBusinessTypeItemDto): IBusinessTypeItem => {
    return {
        label: dto.label,
        value: dto.value,
        children: dto.children && {
            defaultValue: dto.children.default_value,
            items: dto.children.items.map(i => mapFromItemDto(i))
        }
    };
}

// Types

export interface IBusinessTypes {
    defaultValue: string;
    items: IBusinessTypeItem[],
}

interface IBusinessTypeItem {
    value: string;
    label: string;
    children?: IBusinessTypes
}

// DTO types (in snake_case)

interface IBusinessTypesDto {
    default_value: string;
    items: IBusinessTypeItemDto[],
}

interface IBusinessTypeItemDto {
    value: string;
    label: string;
    children?: IBusinessTypesDto
}
