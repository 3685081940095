import { handleKoResponse } from "./common/fetchHelper";
import { ILinks } from "./commonTypes";

export async function uploadFile(requestUrl: string, resourceToken: string, file: File, documentType: string, side?: string): Promise<UploadedDocumentSuccess | UploadDocumentValidationError> {

    const headers = new Headers();
    headers.set('Authorization', `bearer ${resourceToken}`);

    let formData = new FormData();
    formData.append('file', file);
    formData.append('type', documentType);
    if (side) {
        formData.append('side', side);
    }

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: headers,
        body: formData
    };

    const response = await fetch(requestUrl, requestOptions);

    if (response.status === 422) {
        let r = await response.json();
        return (r) as UploadDocumentValidationError;
    }

    handleKoResponse(response);
    const dto = (await response.json()) as IUploadedDocumentDto;

    return { success: true, document: { id: dto.id, links: dto._links } };
}

export interface IUploadedDocument {
    id: string,
    links: ILinks
}
interface IUploadedDocumentDto {
    id: string,
    _links: ILinks
}

type UploadedDocumentSuccess = {
    success: true,
    document: IUploadedDocument
}

type UploadDocumentValidationError = {
    success: false,
    errors: Errors
}

interface Errors {
    [file: string]: string[] | undefined
}
