import { Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormHelperText, IconButton, Paper, Snackbar, SnackbarCloseReason, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from "@mui/material";
import { ChangeEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getRoles, getRolesText } from "../../../../../helpers/applicationHelper";
import { addRepresentative, IAddRepresentative } from "../../../../../services/addRepresentativeService";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { confirmRepresentatives } from "../../../../../services/confirmRepresentativesService";
import { deleteRepresentative } from "../../../../../services/deleteRepresentativeService";
import { getApplication } from "../../../../../services/getApplicationService";
import moment, { Moment } from 'moment';
import RepresentativeData from "../../Common/RepresentativeData/RepresentativeData";
import { ICountry } from "../../../../../services/getCountriesService";
import { ISubmitApplicationResult, Role } from "../../../../../services/commonTypes";
import './RepresentativesStep.scss';

const RepresentativesStep = (props: IProps) => {
    const { t } = useTranslation();

    const emptyFields: IFields = {
        email: '',
        firstName: '',
        lastName: '',
        nationality: '',
        dateOfBirth: null,
        pep: false,
        pepDescription: '',
        isDirector: false,
        isOwner: false
    };

    const isFormValid = () => {
        let errors: IErrors = {};
        const minLength = 2;
        const maxLength = 50;

        if (!props.state.fields.firstName) {
            errors.firstName = t('validation_errors.required');
        } else if (props.state.fields.firstName.length > maxLength) {
            errors.firstName = t('validation_errors.max_length_reached', { max: maxLength });
        } else if (props.state.fields.firstName.length < minLength) {
            errors.firstName = t('validation_errors.min_length_unreached', { min: minLength });
        }

        if (!props.state.fields.lastName) {
            errors.lastName = t('validation_errors.required');
        } else if (props.state.fields.lastName.length > maxLength) {
            errors.lastName = t('validation_errors.max_length_reached', { max: maxLength });
        } else if (props.state.fields.lastName.length < minLength) {
            errors.lastName = t('validation_errors.min_length_unreached', { min: minLength });
        }

        if (!props.state.fields.email) {
            errors.email = t('validation_errors.required');
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(props.state.fields.email)) {
            errors.email = t('validation_errors.invalid_email');
        }

        if (!props.state.fields.nationality) {
            errors.nationality = t('validation_errors.required');
        }

        if (!props.state.fields.dateOfBirth) {
            errors.dateOfBirth = t('validation_errors.required');
        } else if (props.state.fields.dateOfBirth.isAfter(moment())) {
            errors.dateOfBirth = t('validation_errors.not_allowed_date');
        } else if (props.state.fields.dateOfBirth.isAfter(moment().subtract(16, 'years'))) {
            errors.dateOfBirth = t('validation_errors.not_reached_min_age', { age: 16 });
        }

        if (!props.state.fields.isDirector && !props.state.fields.isOwner) {
            errors.roles = t('validation_errors.invalid_role_selection')
        }

        if (props.state.fields.pep) {
            const pepDescriptionMaxLength = 300;

            if (!props.state.fields.pepDescription) {
                errors.pepDescription = t('validation_errors.required');
            } else if (props.state.fields.pepDescription.length > pepDescriptionMaxLength) {
                errors.pepDescription = t('validation_errors.max_length_reached', { max: pepDescriptionMaxLength });
            }
        }

        props.onStateChanged({ ...props.state, errors: errors });

        return (Object.keys(errors).length === 0);
    };

    const handleFieldChange = (fieldName: string, value: any) => {
        props.onStateChanged({
            ...props.state,
            fields: {
                ...props.state.fields!,
                [fieldName]: value
            }
        });
    };

    const handleChangeConfirmation = (event: ChangeEvent<HTMLInputElement>) => {
        props.onStateChanged({ ...props.state, userConfirmation: event.target.checked });
    };

    const switchAddRepresentativeVisibility = (value: boolean) => {
        props.onStateChanged({ ...props.state, isAddingRepresentative: value })
    };

    const handleAddRepresentative = async () => {
        const isValid = isFormValid();
        if (!isValid) {
            return;
        }

        const roles = getRoles(props.state.fields.isOwner, props.state.fields.isDirector);
        const representative: IAddRepresentative = {
            firstName: props.state.fields.firstName!,
            lastName: props.state.fields.lastName!,
            email: props.state.fields.email!,
            dateOfBirth: props.state.fields.dateOfBirth!.utc().format('YYYY-MM-DD'),
            nationality: props.state.fields.nationality!,
            pep: props.state.fields.pep,
            pepDescription: props.state.fields.pepDescription,
            roles: roles
        };

        try {
            const response = await addRepresentative(props.resourceToken, representative);
            const addedRepresentative: IAddedRepresentative = {
                id: response.id,
                firstName: representative.firstName,
                lastName: representative.lastName,
                email: representative.email,
                nationality: representative.nationality,
                pep: representative.pep,
                pepDescription: representative.pepDescription,
                dateOfBirth: representative.dateOfBirth ? moment(representative.dateOfBirth).local() : null,
                roles: representative.roles
            };

            props.onStateChanged({
                ...props.state,
                isAddingRepresentative: false,
                addedRepresentatives: [...props.state.addedRepresentatives, addedRepresentative],
                fields: { ...emptyFields },
                errors: {}
            });
        } catch (error) {
            console.log(error);
            props.onError();
        }
    };

    const handleDeleteRepresentative = async (id: string) => {
        try {
            await deleteRepresentative(props.resourceToken, id);
            const representatives = props.state.addedRepresentatives.filter(r => r.id !== id);
            props.onStateChanged({ ...props.state, addedRepresentatives: representatives });

        } catch (error) {
            console.log(error);
            props.onError();
        }
    }

    const handleContinue = async () => {
        if (!props.state.userConfirmation) {
            props.onStateChanged({ ...props.state, userConfirmationError: t('validation_errors.required') });
        } else {
            props.onStateChanged({ ...props.state, isLoading: true });

            try {
                const result = await confirmRepresentatives(props.resourceToken);

                props.onStateChanged({
                    ...props.state,
                    userConfirmationError: undefined,
                    fields: { ...emptyFields },
                    errors: {},
                    isAddingRepresentative: false,
                    isLoading: false
                });

                props.onCompleted(result);
            } catch (error) {
                console.log(error);
                props.onError();
                props.onStateChanged({ ...props.state, isLoading: false });
            }
        }
    };

    const handleCloseLinkPastedSnackbar = (event: any, reason: SnackbarCloseReason) => {
        if (reason === 'clickaway') {
            return;
        }

        closeLinkPastedSnackbar();
    };

    const closeLinkPastedSnackbar = () => {
        props.onStateChanged(({ ...props.state, linkPastedShowAlert: false }));
    };

    useEffect(() => {
        if (props.state.addedRepresentatives.length > 0) {
            return;
        }

        props.onStateChanged({ ...props.state, isLoadingRepresentatives: true });

        getApplication(props.resourceToken)
            .then(result => {
                const addedRepresentatives =
                    result.business?.representatives
                        ? result.business!.representatives!.map<IAddedRepresentative>(r => {
                            return {
                                id: r.id,
                                firstName: r.firstName,
                                lastName: r.lastName,
                                email: r.email,
                                dateOfBirth: moment(r.dateOfBirth).local(),
                                nationality: r.nationality,
                                pep: r.pep,
                                pepDescription: r.pepDescription,
                                roles: r.roles
                            };
                        })
                        : [];

                props.onStateChanged({ ...props.state, addedRepresentatives: addedRepresentatives, isLoadingRepresentatives: false });
            })
            .catch(error => {
                console.log(error);
                props.onStateChanged({ ...props.state, isLoadingRepresentatives: false });
                props.onError();
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='representatives-step-container'>
            {props.state.isLoadingRepresentatives &&
                <div className='representatives-step-loading'>
                    <CircularProgress size={50} />
                </div>
            }

            {!props.state.isLoadingRepresentatives &&
                <>
                    {props.state.addedRepresentatives.length > 0 &&
                        <>
                            <h2>{t('steps.representatives.introduction')}</h2>
                            <TableContainer component={Paper}>
                                <Table aria-label="representatives">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{t('steps.common.full_name')}</TableCell>
                                            <TableCell>{t('form_labels.email')}</TableCell>
                                            <TableCell>{t('steps.common.roles')}</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {props.state.addedRepresentatives.map((r, i) => (
                                            <TableRow
                                                key={`${r.firstName}_${i}`}>
                                                <TableCell component='th' scope='row'>{`${r.firstName} ${r.lastName}`}</TableCell>
                                                <TableCell>{r.email}</TableCell>
                                                <TableCell>{getRolesText(r.roles, t)}</TableCell>
                                                <TableCell align='right'>
                                                    <Tooltip title={t('buttons.delete')}>
                                                        <IconButton onClick={() => handleDeleteRepresentative(r.id)} aria-label='delete'><DeleteIcon /></IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <div className='representatives-step-btn'>
                                {!props.state.isAddingRepresentative &&
                                    <Button
                                        variant='outlined'
                                        color='secondary'
                                        size='medium'
                                        disabled={props.state.isLoading}
                                        onClick={() => switchAddRepresentativeVisibility(true)}
                                        startIcon={<AddCircleOutlineIcon />}>
                                        <span>{t('buttons.add_representative')}</span>
                                    </Button>
                                }

                                {props.state.isAddingRepresentative &&
                                    <Button
                                        variant='outlined'
                                        color='secondary'
                                        size='medium'
                                        disabled={props.state.isLoading}
                                        onClick={() => switchAddRepresentativeVisibility(false)}
                                        startIcon={<CloseIcon />}>
                                        <span>{t('buttons.cancel')}</span>
                                    </Button>
                                }
                            </div>
                        </>
                    }

                    {(props.state.isAddingRepresentative || props.state.addedRepresentatives.length === 0) &&
                        <div className='wizard-step-fields'>
                            <h2>{t('steps.representatives.add_representative')}</h2>

                            <Stack spacing={2}>
                                <TextField
                                    id='firstName'
                                    name='firstName'
                                    label={t('form_labels.first_name')}
                                    variant='outlined'
                                    defaultValue={props.state.fields.firstName}
                                    onChange={(ev) => handleFieldChange(ev.target.name, ev.target.value)}
                                    error={!!props.state.errors.firstName}
                                    helperText={props.state.errors.firstName}
                                    required />

                                <TextField
                                    id='lastName'
                                    name='lastName'
                                    label={t('form_labels.last_name')}
                                    variant='outlined'
                                    defaultValue={props.state.fields.lastName}
                                    onChange={(ev) => handleFieldChange(ev.target.name, ev.target.value)}
                                    error={!!props.state.errors.lastName}
                                    helperText={props.state.errors.lastName}
                                    required />

                                <TextField
                                    id='email'
                                    name='email'
                                    label={t('form_labels.email')}
                                    variant='outlined'
                                    defaultValue={props.state.fields.email}
                                    onChange={(ev) => handleFieldChange(ev.target.name, ev.target.value)}
                                    error={!!props.state.errors.email}
                                    helperText={props.state.errors.email}
                                    required />

                                <RepresentativeData
                                    fields={{
                                        nationality: props.state.fields.nationality,
                                        dateOfBirth: props.state.fields.dateOfBirth,
                                        pep: props.state.fields.pep,
                                        pepDescription: props.state.fields.pepDescription,
                                        isDirector: props.state.fields.isDirector,
                                        isOwner: props.state.fields.isOwner
                                    }}
                                    errors={{
                                        nationality: props.state.errors.nationality,
                                        dateOfBirth: props.state.errors.dateOfBirth,
                                        pepDescription: props.state.errors.pepDescription,
                                        roles: props.state.errors.roles
                                    }}
                                    countries={props.state.countries}
                                    handleFieldChange={handleFieldChange} />
                            </Stack>

                            <div className="wizard-step-btn">
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    size='large'
                                    disabled={props.state.isLoading}
                                    onClick={handleAddRepresentative}>
                                    {t('buttons.save')}
                                </Button>
                            </div>
                        </div>
                    }
                    <div className='representatives-step-user-confirmation'>
                        <FormControl required error={!!props.state.userConfirmationError} onChange={handleChangeConfirmation}>
                            <FormControlLabel
                                id='userConfirmation'
                                name='userConfirmation'
                                control={<Checkbox checked={props.state.userConfirmation} inputProps={{ 'aria-label': 'userConfirmation' }} required />}
                                label={t('steps.representatives.authorization')} />
                            <FormHelperText>{props.state.userConfirmationError}</FormHelperText>
                        </FormControl>
                    </div>

                    <div className="wizard-step-btn">
                        <Button id="goBackBtn" color='secondary' size='large' onClick={props.onBack} disabled={props.state.isLoading}>
                            {t('buttons.back')}
                        </Button>

                        <Button id="continueBtn" variant='contained' color='secondary' size='large' disabled={props.state.isLoading || props.state.addedRepresentatives.length === 0} onClick={handleContinue}>
                            {t('buttons.continue')}
                        </Button>
                    </div>
                </>
            }

            <Snackbar
                open={props.state.linkPastedShowAlert}
                autoHideDuration={2000}
                onClose={handleCloseLinkPastedSnackbar}
                message={t('steps.representatives.link_copied')}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                action={<IconButton onClick={closeLinkPastedSnackbar} aria-label="close" color="inherit"><CloseIcon fontSize="small" /></IconButton>}
            />
        </div >
    );
};

export default RepresentativesStep;

export interface IRepresentativesState {
    countries: ICountry[],
    addRepresentativeUrl?: string;
    confirmRepresentativesUrl?: string;
    isLoading: boolean;
    isLoadingRepresentatives: boolean;
    fields: IFields;
    errors: IErrors
    addedRepresentatives: IAddedRepresentative[];
    isAddingRepresentative: boolean;
    linkPastedShowAlert: boolean;
    userConfirmation: boolean;
    userConfirmationError?: string;
}

interface IProps {
    resourceToken: string;
    state: IRepresentativesState;
    onBack: () => void;
    onCompleted: (result: ISubmitApplicationResult) => void;
    onError: (message?: string) => void;
    onStateChanged: (state: IRepresentativesState) => void;
}

interface IAddedRepresentative {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    nationality: string;
    dateOfBirth: Moment | null;
    pep: boolean;
    pepDescription?: string;
    roles: Role[];
}

interface IFields {
    firstName?: string;
    lastName?: string;
    email?: string;
    nationality?: string;
    dateOfBirth: Moment | null;
    pep: boolean;
    pepDescription?: string;
    isOwner: boolean;
    isDirector: boolean;
}

interface IErrors {
    firstName?: string;
    lastName?: string;
    email?: string;
    nationality?: string;
    dateOfBirth?: string;
    pepDescription?: string;
    roles?: string;
}
