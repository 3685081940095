import { handleKoResponse } from "./common/fetchHelper";

export async function deleteRepresentative(resourceToken: string, id: string) {
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authorization', `bearer ${resourceToken}`);

    const requestUrl = `${process.env.REACT_APP_API_URL}/application/representatives/${id}`;
    const requestOptions: RequestInit = {
        method: 'DELETE',
        headers: headers
    };

    const response = await fetch(requestUrl, requestOptions);
    await handleKoResponse(response);
}
