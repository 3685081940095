import "./DataProtectionPolicyBody.scss";

const DataProtectionPolicyBodyEn = () => {
    return (
        <div className="data-protection-policy">
            <p>This Privacy Policy is established by Digiteal SA (hereinafter referred to as "the Controller").</p>
            <p>Its purpose is to inform visitors to the website hosted at the following address: www.digiteal.eu (hereinafter referred to as the "Website") of the way in which the data are collected and processed by the Controller.</p>
            <p>This Privacy Policy is in line with the Controller's wish to act in complete transparency, in compliance with its national provisions and with Regulation (EU) 2016/679 (hereinafter referred to as the "General Data Protection Regulation" or “GDPR”) of the European Parliament and of the Council of 27 April 2016 on the protection of individuals with regard to the processing of personal data and on the free movement of such data and repealing Directive 95/46/EC.</p>
            <p>The Controller pays particular attention to the protection of the privacy of its users and therefore undertakes to take the reasonable precautions required to protect the personal data collected against loss, theft, disclosure or unauthorised use.</p>

            <h2>What do you not see when visiting our Website?</h2>

            <p>Each time you visit our Website (request or attempt to request data from our server), the information concerning this request is stored in a log file (Logfile).</p>
            <p><strong>In particular, the following data is recorded for each request:</strong></p>
            <ul>
                <li>IP address</li>
                <li>Name of requested data</li>
                <li>Date and hour of the request</li>
                <li>Volume of data transmission</li>
                <li>Indication of successful completion</li>
                <li>Indication why an application has failed</li>
                <li>Operating system and browser software on your computer</li>
                <li>Screen resolution</li>
                <li>Browser language</li>
                <li>Color depth</li>
                <li>Plugins of the browser (JavaScript, Flash Player, Java, Silverlight, Adobe Acrobat Reader etc.)</li>
                <li>As well as the website from which you consult us.</li>
            </ul>
            <p>For statistical purposes, the data is stored in log files (Log-Files). We can not link the recorded data to your person. Nor do we pass the data on to third parties. It is only in case of breach of the law that we reserve the right to transmit certain registrations to the competent authorities to allow the prosecutionof the offenses.</p>
            <p>In order to be able to assist you online, we use the Zoho Corporation chat service.</p>

            <h3>Usage of Cookies</h3>

            <p>On our Website, we use so-called "cookies" to make browsing more useful and reliable. Some of these cookies are essential to the operation of the Website, others improve the user experience.</p>
            <p>Most web browsers are configured to automatically accept cookies. If the user wishes to customize their management, he must modify the settings of his browser.</p>
            <p>Google Analytics™ uses internal cookies to generate reports on users' interactions with Google Analytics™ customers' websites. These cookies are used to store information that does not personally identify users. Internal cookies stored in browsers are not valid from one domain to another.</p>

            <h2>What do you not see when you use our platform?</h2>

            <h3>Privacy Statements for the use of the Digiteal web platform</h3>

            <p>In addition to the privacy statements on our Website (see above), we would like to inform you of the following:</p>
            <ul>
                <li>Access to the platform:</li>
                <ul>
                    <li>When accessing the platform, we identify you through various personal data:</li>
                    <ul>
                        <li>Email address</li>
                        <li>IP address, browser agent and cookies</li>
                        <li>SSO identifier (Google, Apple and/or Facebook)</li>
                    </ul>
                </ul>
                <li>Linking a bank account:</li>
                <ul>
                    <li>To take full advantage of the Digiteal billing and payment platform, you will be offered to link one or more of your bank accounts. Since Digiteal is recognized as a banking institution by the Belgian National Bank, our company is subject to the various rules applicable to the sector, such as the fight against money laundering, combating the financing of terrorism, etc.</li>
                    <li>For these reasons, we are legally obliged to identify the natural person linked to the bank account of the latter or linked to a legal person. In this context personal data are requested such as:</li>
                    <ul>
                        <li>Identification via identity card</li>
                        <li>Last Name/First Name</li>
                        <li>Address</li>
                        <li>Bank account(s)</li>
                        <li>"KYC" (Know Your Customer) Rules</li>
                    </ul>
                </ul>
                <li>Bill presentment</li>
                <ul>
                    <li>If you request to receive your next "lambda" supplier invoice in your Digiteal environment, you authorize your supplier to send your pdf invoices to Digiteal so that we can display these pdf invoices in your environment.</li>
                    <li>When a new invoice is presented, an acknowledgment of receipt will be sent to the issuer of the invoice.</li>
                </ul>
                <li>"One-Click-Pay"</li>
                <ul>
                    <li>Information about the status of the payment will be sent to the creditor of the invoice (the issuer): payment initiated, outstanding, paid, rejected payment, programmed automatic payment.</li>
                </ul>
                <li>Bank Account Access Service (PSD2)</li>
                <ul>
                    <li>If you link a bank account to access the transactions of that account, we collect the following data:</li>
                    <ul>
                        <li>Name and surname</li>
                        <li>Internal identifiers (client access token, API token)</li>
                        <li>Third party identifiers (authorization token from your account management payment service provider) and your IP address</li>
                        <li>IBAN of your bank accounts</li>
                        <li> Bank account information (including history of payments sent and received)</li>
                        <li>Security, access and activity logs</li>
                        <li>User agent for your web browser.</li>
                    </ul>
                </ul>
                <li>Payment Initiation Service (PSD2)</li>
                <ul>
                    <li>If you initiate a transfer through Digiteal, we collect the following data:</li>
                    <ul>
                        <li>The same data as for the bank account access service.</li>
                        <li>Information about the payment you wish to make (IBAN and name of the beneficiary, amount, communication)</li>
                    </ul>
                </ul>
            </ul>

            <h2>What do you not see when using our app?</h2>

            <h3>Privacy Statements for the Use of the Digiteal APP</h3>

            <p>To take advantage of Digiteal's services, we provide you with a mobile app. In addition to the statements on privacy in relation to the web platform (see above), we would like to inform you of the following points:</p>

            <ul>
                <li>When using the app, we collect the following personal data for statistical purposes and to improve the functions of the app:</li>
                <ul>
                    <li>identification of your device, your mobile device number / PUSH (IMEI = International Mobile Equipment Identity),</li>
                    <li>Your IP address (in case of onboarding on the platform).</li>
                </ul>

                <li>We do not pass on your data to third parties for commercial purposes but only:</li>
                <ul>
                    <li>For operational purposes and in order to improve their safety of our services to third parties providing, directly or indirectly, services in relation to the platform.</li>
                    <li>For regulatory reasons towards the competent authorities.</li>
                </ul>

                <li>Storage: the data you provide us during registration or the ones that your supplier provides us are securely stored in Europe (Germany) with the Amazon Web Services (AWS) provider. The purpose of this processing is to allow the archiving of your information and documents so that you can access them at any time.</li>
                <li>Automation: some documents you provide to us during registration or that your suppliers provide to us might be transmitted to Google (OCR service) and Amazon Web Services (image analysis service). The purpose of this data processing is to check the concordance between two images, the degree of readability, to detect potential cases of fraud, to automatically prefill fields in order to facilitate onboarding, etc. If you do not wish to allow this processing, you can make an opt-out in "My profile".</li>
                <li>Support: some of the data you provide us during registration can be securely stored in Europe (Ireland) with our supplier Atlassian. The purpose of this data processing is to enable our compliance department to check the compliance of the provided data in order to fight against fraud, money laundering, etc.</li>
                <li>External control: certain data, typically financial transactions, may be passed on to third parties such as your accountant or his software supplier provided you have given us your explicit consent to share this data</li>
                <li>Regulatory: In order to fulfil our reporting obligations, personal data may exceptionally be disclosed to third parties who carry out regulatory control missions such as our external auditor and government institutions such as the NBB and CTIF</li>
                <li>Services: for the service "access to banking movements" and connection with banks (AIS and PIS), we work with Exthand SRL. For the Loyaltech service, we work with Promcom BV.</li>

                <li>In order to make the app work properly, the app uses the following functions and sensors via the interfaces of your mobile device:</li>
                <ul>
                    <li>Localization information: When you use the app outside of the European Union, we may collect and process information about your location in real time, if you have enabled the location function for this app beforehand on your mobile device. We use different technologies such as IP addresses, GPS or other sensors to locate you</li>
                    <li>The purpose of this processing is for the purposes of potential evaluation of fraud and alert generation in our system.</li>
                </ul>

                <li>If your mobile device has a camera, the latter is used to scan QR Codes. It can also be used to do pictures of your identity documents while subscribing to the service.</li>
                <li>When the app's messaging service is enabled, you receive push notifications on your mobile device. This service saves your linked keywords and the push ID of your mobile device in a database. The sending of messages is done through the push services of Apple (iOS devices) or Google (Android devices).</li>
                <li>The wireless connection of your mobile device is used by the app to establish a connection to the Internet.</li>
                <li>By accessing other functions and sensors on your mobile device, the app can access data from the internet and process error messages.</li>
                <li>In the event that you consult the websites of partners (billers) from the Digiteal platform, the privacy policy in force on these sites is independent of that of Digiteal.</li>
            </ul>

            <h2>Why collect and process this data?</h2>

            <p>Personal data is only used when you make it available to us, for example when you subscribe to our newsletter, when you make a request on our contact page or when you register on the Digiteal platform to take advantage of our services.</p>
            <p>The information provided is transmitted in a safe manner. This protects the communication between your computer and our web server, and this makes it possible to prevent the misuse of data by third parties. For encryption, we use SSL (Secure Socket Layer), a system recognized and widely used on the Internet, which is also used by banks and the current version of which still offers the necessary security.</p>
            <p>All personal data on our Website is collected, processed and used on our Website in accordance with the legal provisions concerning the protection of personal data and only for the provision of the services requested by you and for the processing of your requests. As part of payments processing, we work with a banking partner. This bank is contractually bound and has an obligation to respect these rules of protection of the private life. More generally, we process personal data on the basis of our legitimate interest (for instance, communication of personal data to entities providing services in relation to our platform).</p>

            <h2>Retention period</h2>

            <p>The Controller shall keep personal data only for as long as is reasonably necessary for the purposes for which they are collected and in accordance with legal and regulatory requirements.</p>
            <p>At the end of the storage period, the Controller shall make every effort to ensure that the personal data have been made unavailable.</p>

            <h2 id="dataSubjectRights">Data Subject rights</h2>

            <p>Under the GDPR the users have some important rights. In summary, these include rights to:</p>

            <ol>
                <li>Access your personal data</li>
                <li>Require us to correct any mistakes in your information which we hold.</li>
                <li>Request the erasure of personal data concerning you in certain situations.</li>
                <li>Request the data to be transferred to a third party in certain situations.</li>
                <li>Object at any time to processing of personal data concerning you for direct marketing.</li>
                <li>Object in certain other situations to our continued processing of your personal data.</li>
                <li>Otherwise restrict our processing of your personal data in certain circumstances.</li>
                <li>Claim compensation for damages caused by our breach of any data protection laws.</li>
            </ol>

            <p>To access, modify and erase your personal data, you can exercise your rights through the menu "My Profile"</p>
            <p>You can delete your Digiteal account to exercise your right to be forgotten. Minimal data will be kept by the Controller according to the EU payments logging requirements.</p>
            <p>To exercise your other rights, please send an email to support@digiteal.eu</p>

            <h2>Security</h2>

            <p>The Controller shall implement appropriate technical and organisational measures to ensure a level of security of the processing and data collected with regard to the risks represented by the processing and the nature of the data to be protected appropriate to the risk. It takes into account the state of knowledge, the costs of implementation and the nature, scope, context and purposes of the processing operation as well as the risks to users' rights and freedoms.</p>
            <p>The Controller always uses encryption technologies that are recognized as industry standards within the IT sector when transferring or receiving data on the Website.</p>
            <p>The Controller has put in place appropriate security measures to protect and avoid the loss, misuse or alteration of the information received on the Website.</p>

            <h2>What rights do you have regarding the transmitted data?</h2>

            <p>As Digiteal is <strong>responsible for the processing</strong> of personal data, you can ask your questions to our DPO (data protection officer ) via dpo@digiteal.eu.</p>
            <p>As a customer, in addition to all your rights detailed in <a href="#dataSubjectRights">Data Subject rights</a>, you can always question us about your personal data and ask which data are concerned (your personal data is what you gave us). You have the possibility to ask for the right to the deletion of your data, or the right to forget.</p>

            <table>
                <tbody>
                    <tr><td>Digiteal S.A.</td></tr>
                    <tr><td>Rue Emile Francqui 6/9</td></tr>
                    <tr><td>1435 Mont-Saint-Guibert</td></tr>
                    <tr><td>Belgium</td></tr>
                </tbody>
            </table>

            <p>This version of the Privacy Policy is dated 24/05/2023.</p>
        </div>
    );
};

export default DataProtectionPolicyBodyEn;
