import { mapToSubmitApplicationResult } from "../helpers/applicationHelper";
import { handleKoResponse } from "./common/fetchHelper";
import { ISubmitApplicationResultDto } from "./commonTypes";

export async function updateBusinessType(requestUrl: string, resourceToken: string, businessType: IBusinessType) {
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authorization', `bearer ${resourceToken}`);

    const businessTypeDto = mapToDto(businessType);

    const requestOptions: RequestInit = {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(businessTypeDto)
    };

    const response = await fetch(requestUrl, requestOptions);
    await handleKoResponse(response);

    const resultDto = (await response.json()) as ISubmitApplicationResultDto;
    const result = mapToSubmitApplicationResult(resultDto);

    return result;
}

const mapToDto = (businessType : IBusinessType): IBusinessTypeDto => {
    return {
        type: businessType.type,
        country_code: businessType.countryCode,
        structure: businessType.structure
    };
};

// Types

export interface IBusinessType {
    type: string;
    structure?: string;
    countryCode: string;
}

// DTO Types (in snake_case)

interface IBusinessTypeDto {
    type: string;
    structure?: string;
    country_code: string;
}
