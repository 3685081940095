import { mapToSubmitApplicationResult } from "../helpers/applicationHelper";
import { handleKoResponse } from "./common/fetchHelper";
import { ISubmitApplicationResultDto } from "./commonTypes";

export async function confirmApplicant(requestUrl: string, resourceToken: string, applicant: IApplicant) {
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authorization', `bearer ${resourceToken}`);

    const applicantDto = mapToApplicantDto(applicant);

    const requestOptions: RequestInit = {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(applicantDto)
    };

    const response = await fetch(requestUrl, requestOptions);
    await handleKoResponse(response);

    const resultDto = (await response.json()) as ISubmitApplicationResultDto;
    const result = mapToSubmitApplicationResult(resultDto);

    return result;
}

function mapToApplicantDto(applicant: IApplicant): IApplicantDto {
    return {
        first_name: applicant.firstName,
        last_name: applicant.lastName,
        email: applicant.email
    };
}

export interface IApplicant {
    firstName: string;
    lastName: string;
    email: string;
}

interface IApplicantDto {
    first_name: string;
    last_name: string;
    email: string;
}
