import './Header.scss';
import logo from '../../../../resources/images/logo.png';

const Header = (props: IProps) => {

    return (
        <div className='header-container' style={{backgroundColor: props.primaryColor || '#253F66'}}>
            <img src={props.logoUrl || logo} alt="logo" />
        </div>
    );
};

export default Header;

interface IProps {
    logoUrl?: string;
    primaryColor?: string;
}
