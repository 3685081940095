import { mapToSubmitApplicationResult } from "../helpers/applicationHelper";
import { ValidationErrorResponse, handleKoResponse } from "./common/fetchHelper";
import { ISubmitApplicationResultDto, RegistrationNumberCode } from "./commonTypes";

export async function updateBusinessProfile(requestUrl: string, resourceToken: string, businessProfile: IBusinessProfile) {
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authorization', `bearer ${resourceToken}`);

    const businessProfileDto = mapToDto(businessProfile);

    const requestOptions: RequestInit = {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(businessProfileDto)
    };

    const response = await fetch(requestUrl, requestOptions);

    if (response.status === 422) {
        return (await response.json()) as ValidationErrorResponse;
    }

    await handleKoResponse(response);

    const resultDto = (await response.json()) as ISubmitApplicationResultDto;
    const result = mapToSubmitApplicationResult(resultDto);

    return result;
}

const mapToDto = (businessProfile: IBusinessProfile): IBusinessProfileDto => {
    return {
        country_code: businessProfile.countryCode,
        legal_name: businessProfile.legalName,
        trading_name: businessProfile.tradingName,
        registration_number: businessProfile.registrationNumber,
        registration_number_code: businessProfile.registrationNumberCode,
        district_court_code: businessProfile.districtCourtCode,
        vat_number: businessProfile.vatNumber,
        website: businessProfile.website,
        mcc: businessProfile.mcc,
        registered_address: businessProfile.registeredAddress && {
            line1: businessProfile.registeredAddress.line1,
            line2: businessProfile.registeredAddress.line2,
            number: businessProfile.registeredAddress.number,
            town_city: businessProfile.registeredAddress.townCity,
            state: businessProfile.registeredAddress.state,
            zip: businessProfile.registeredAddress.zip
        },
        phone_number: businessProfile.phoneNumber && {
            country_code: businessProfile.phoneNumber.countryCode,
            number: businessProfile.phoneNumber.number
        }
    };
}

// Types

export interface IBusinessProfile {
    countryCode: string;
    legalName: string;
    tradingName?: string;
    registrationNumber?: string;
    registrationNumberCode?: RegistrationNumberCode;
    districtCourtCode?: string;
    vatNumber?: string;
    registeredAddress: IRegisteredAddress;
    mcc: string;
    website?: string;
    phoneNumber?: IPhoneNumber
}

interface IRegisteredAddress {
    line1: string;
    line2?: string;
    number: string;
    townCity: string;
    state?: string;
    zip: string;
}

interface IPhoneNumber {
    countryCode?: string;
    number?: string;
}

// DTO types (in snake_case)

interface IBusinessProfileDto {
    country_code: string
    legal_name: string;
    trading_name?: string;
    registration_number?: string;
    registration_number_code?: RegistrationNumberCode;
    district_court_code?: string;
    vat_number?: string;
    registered_address: IRegisteredAddressDto;
    mcc: string;
    website?: string;
    phone_number?: IPhoneNumberDto
}

interface IRegisteredAddressDto {
    line1: string;
    line2?: string;
    number: string;
    town_city: string;
    state?: string;
    district?: string;
    zip: string;
}

interface IPhoneNumberDto {
    country_code?: string;
    number?: string;
}
