import { handleKoResponse } from "./common/fetchHelper";

export async function getDistrictCourts(resourceToken: string, countryCode: string) {
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authorization', `bearer ${resourceToken}`);

    const requestUrl = `${process.env.REACT_APP_API_URL}/countries/${countryCode}/district-courts`;
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: headers
    };

    const response = await fetch(requestUrl, requestOptions);
    await handleKoResponse(response);

    const districtCourts = (await response.json()) as IDistrictCourt[]

    return districtCourts;
}

export interface IDistrictCourt {
    code: string;
    name: string;
    country_code: string;
}
