import { mapToSubmitApplicationResult } from "../helpers/applicationHelper";
import { handleKoResponse } from "./common/fetchHelper";
import { ISubmitApplicationResultDto } from "./commonTypes";

export async function submitApplication(requestUrl: string, resourceToken: string) {
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authorization', `bearer ${resourceToken}`);

    const requestOptions: RequestInit = {
        method: 'PATCH',
        headers: headers
    };

    const response = await fetch(requestUrl, requestOptions);
    await handleKoResponse(response);

    const resultDto = (await response.json()) as ISubmitApplicationResultDto;
    const result = mapToSubmitApplicationResult(resultDto);

    return result;
}
