import { Autocomplete, TextField } from "@mui/material";
import { SyntheticEvent, useEffect, useState } from "react";

const CountryField = (props: IProps) => {
    const [state, setState] = useState<IState>({ selectedItem: null });

    useEffect(() => {
        let selectedItem: IItem | null;

        if (props.value) {
            const country = props.items.find(c => c.value === props.value);
            if (!country) {
                throw new Error('Country is not supported');
            }

            selectedItem = country;
        } else {
            selectedItem = null;
        };

        setState(prev => ({
            ...prev,
            selectedItem: selectedItem
        }));

        // eslint-disable-next-line
    }, [props.value]);

    const handleChange = (event: SyntheticEvent<Element, Event>, value: IItem | null) => {
        let newValue;
        if (value) {
            newValue = value.value;
        } else {
            newValue = '';
        }

        props.onChange(props.name, newValue);
    };

    return (
        <Autocomplete
            id={props.id}
            options={props.items}
            autoHighlight
            disabled={props.disabled}
            getOptionLabel={(option) => option.description}
            value={state.selectedItem}
            onChange={handleChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    id={props.id}
                    name={props.name}
                    label={props.label}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                    required={props.required}
                    error={!!props.errorMessage}
                    helperText={props.errorMessage}
                />
            )}
        />
    );
}

export default CountryField;

interface IProps {
    id: string,
    name: string,
    label: string,
    value?: string;
    required?: boolean,
    errorMessage?: string,
    disabled?: boolean,
    items: IItem[],
    onChange: (fiedlName: string, value: string) => void;
}

interface IState {
    selectedItem: IItem | null
}

export interface IItem {
    value: string;
    description: string;
}
