import { useTranslation } from "react-i18next";
import { IBusinessProfileState } from "../../BusinessProfileStep/BusinessProfileStep";
import { IconButton, Tooltip } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { getDocumentTypeText, getRegistrationNumberLabel } from "../../../../../../helpers/applicationHelper";

const BusinessProfileSummary = (props: IProps) => {
    const { t: translate } = useTranslation();

    const selectedMcc = props.state.merchantCategories.find(c => c.value === props.state.fields.mcc);
    const selectedCountryPrefix = props.state.countries.find(c => c.code === props.state.fields.countryPrefix);
    const selectedDistrictCourt = props.state.districtCourts.find(c => c.value === props.state.fields.districtCourtCode);

    return (
        <div className='business-profile-summary-container summary-section'>
            <div className='summary-section-header'>
                <h3>{translate('steps.business_profile.name')}</h3>
                <Tooltip title={translate('buttons.edit')}>
                    <IconButton aria-label="edit" size="small" onClick={props.onEdit}>
                        <EditIcon fontSize="inherit" />
                    </IconButton>
                </Tooltip>
            </div>

            <div className="summary-section-content summary-section-grid">
                <div>{translate('form_labels.legal_name')}</div>
                <div>{props.state.fields.legalName!}</div>

                {props.state.fields.tradingName &&
                    <>
                        <div>{translate('form_labels.trading_name')}</div>
                        <div>{props.state.fields.tradingName}</div>
                    </>
                }

                {props.state.fields.registrationNumber &&
                    <>
                        <div>{getRegistrationNumberLabel(props.state.form!.registrationNumber!.type, translate)}</div>
                        <div>{props.state.fields.registrationNumber}</div>
                    </>
                }

                {props.state.fields.districtCourtCode &&
                    <>
                        <div>{translate('form_labels.district_court')}</div>
                        <div>{selectedDistrictCourt!.description}</div>
                    </>
                }

                {props.state.fields.vatNumber &&
                    <>
                        <div>{translate('form_labels.vat_number')}</div>
                        <div>{props.state.fields.vatNumber}</div>
                    </>
                }

                {props.state.fields.phoneNumber &&
                    <>
                        <div>{translate('form_labels.website')}</div>
                        <div>{props.state.fields.website}</div>
                    </>
                }

                <div>{translate('form_labels.mcc')}</div>
                <div>{selectedMcc!.description}</div>

                <div>{translate('steps.business_profile.registered_address')}</div>
                <div>
                    <div>{props.state.fields.line1!}</div>
                    {props.state.fields.line2 &&
                        <div>{props.state.fields.line2}</div>
                    }
                    <div>{props.state.fields.houseNumber}</div>
                    <div>{props.state.fields.townCity}</div>
                    {props.state.fields.state &&
                        <div>{props.state.fields.state}</div>
                    }
                    <div>{props.state.fields.zip!}</div>
                </div>

                {props.state.fields.countryPrefix && props.state.fields.phoneNumber &&
                    <>
                        <div>{translate('steps.business_profile.phone_number')}</div>
                        <div>{`+${selectedCountryPrefix!.phonePrefix} ${props.state.fields.phoneNumber}`}</div>
                    </>
                }

                {props.state.uploadedDocuments.length > 0 &&
                    <>
                        <div>{translate('steps.common.documents')}</div>
                        <div>
                            {props.state.uploadedDocuments.map((doc, i) => {
                                return (
                                    <div key={`doc-${i}`}>{`${doc.name} - ${getDocumentTypeText(doc.type, translate)}`}</div>
                                );
                            })}
                        </div>
                    </>
                }
            </div>
        </div>
    );
};

export default BusinessProfileSummary;

interface IProps {
    state: IBusinessProfileState;
    onEdit: () => void;
}
