import { handleKoResponse } from "./common/fetchHelper";
import { RegistrationNumberCode } from "./commonTypes";

export enum FormStepKey {
    BusinessProfile = "business-profile"
}

export async function getForm(resourceToken: string, formStepKey: FormStepKey) {
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authorization', `bearer ${resourceToken}`);


    const requestUrl = `${process.env.REACT_APP_API_URL}/application/forms/${formStepKey}`;
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: headers
    };

    const response = await fetch(requestUrl, requestOptions);
    await handleKoResponse(response);

    switch (formStepKey) {
        case FormStepKey.BusinessProfile:
            const businessProfileFormDto = (await response.json()) as IBusinessProfileFormDto;
            return mapFromDto(businessProfileFormDto);
        default:
            throw new Error(`Form with key ${formStepKey} is not supported`);
    }
}

function mapFromDto(dto: IBusinessProfileFormDto): IBusinessProfileForm {
    return {
        registrationNumber: dto.registration_number,
        vatNumber: dto.vat_number,
        districtCourtCode: dto.district_court_code
    };
}

// Types

export interface IBusinessProfileForm {
    registrationNumber?: IFormField;
    vatNumber?: IFormField;
    districtCourtCode?: IFormField;
}

export interface IFormField {
    required: boolean;
    type?: RegistrationNumberCode;
}

// DTO types (in snake_case)

export interface IBusinessProfileFormDto {
    registration_number?: IFormField;
    vat_number?: IFormField;
    district_court_code?: IFormField;
}
