import { IconButton, Tooltip } from "@mui/material";
import { IApplicantDetailsState } from "../../ApplicantDetailsStep/ApplicantDetailsStep";
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from "react-i18next";
import { getDocumentSideText, getDocumentTypeText, getRoles, getRolesText } from "../../../../../../helpers/applicationHelper";

const ApplicantDetailsSummary = (props: IProps) => {
    const { t } = useTranslation();

    const selectedCountry = props.state.countries.find(c => c.code === props.state.fields.nationality);

    return (
        <div className='applicant-details-summary-container summary-section'>
            <div className='summary-section-header'>
                <h3>{t('steps.applicant_details.name')}</h3>
                <Tooltip title={t('buttons.edit')}>
                    <IconButton aria-label="edit" size="small" onClick={props.onEdit}>
                        <EditIcon fontSize="inherit" />
                    </IconButton>
                </Tooltip>
            </div>

            <div className="summary-section-content summary-section-grid">
                <div>{t('form_labels.nationality')}</div>
                <div>{selectedCountry!.name}</div>

                <div>{t('form_labels.gender')}</div>
                <div>{t(`steps.applicant_details.gender.${props.state.fields.gender!}`)}</div>

                <div>{t('form_labels.date_of_birth')}</div>
                <div>{props.state.fields.dateOfBirth!.format('DD/MM/YYYY')}</div>

                <div>{t('form_labels.pep')}</div>
                <div>{props.state.fields.pep ? t('steps.summary.yes') : t('steps.summary.no')}</div>

                {props.state.fields.pepDescription &&
                    <>
                        <div>{t('form_labels.description')}</div>
                        <div>{props.state.fields.pepDescription}</div>
                    </>
                }

                <div>{t('steps.common.roles')}</div>
                <div>{getRolesText(getRoles(props.state.fields.isOwner, props.state.fields.isDirector), t)}</div>

                {props.state.uploadedDocuments.length > 0 &&
                    <>
                        <div>{t('steps.common.documents')}</div>
                        <div>
                            {props.state.uploadedDocuments.map((doc, i) => {
                                return (
                                    <div key={`doc-${i}`}>{`${doc.name} - ${getDocumentTypeText(doc.type, t)} (${getDocumentSideText(doc.side!, t)})`}</div>
                                );
                            })}
                        </div>
                    </>
                }
            </div>
        </div>
    );
};

export default ApplicantDetailsSummary;

interface IProps {
    state: IApplicantDetailsState;
    onEdit: () => void;
}
