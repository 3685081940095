import { TFunction } from "i18next";
import { RegistrationNumberCode, DocumentType, DocumentSide, Role, ISubmitApplicationResultDto, ISubmitApplicationResult } from "../services/commonTypes";

export function mapToSubmitApplicationResult(submitApplicationResultDto: ISubmitApplicationResultDto): ISubmitApplicationResult {
    return {
        status: submitApplicationResultDto.status,
        workflow: submitApplicationResultDto.workflow,
        links: submitApplicationResultDto._links
    };
}

export function getDocumentTypeText(documentType: DocumentType, translate: TFunction<'translation', undefined>, bothSides?: boolean) {
    if(bothSides){
        return `${translate(`steps.common.document_types.${documentType}`)} (${translate('steps.common.both_sides')})`;
    } else {
        return translate(`steps.common.document_types.${documentType}`);
    }
}

export function getDocumentSideText(documentSide: DocumentSide, translate: TFunction<'translation', undefined>) {
    return translate(`steps.common.document_sides.${documentSide}`);
};

export function getRoles(isOwner: boolean, isDirector: boolean): Role[] {
    let roles: Role[] = [];

    if (isOwner) {
        roles.push(Role.Owner);
    }
    if (isDirector) {
        roles.push(Role.Director);
    }
    if (isOwner && isDirector) {
        roles.push(Role.Executive);
    }

    return roles;
}

export function getRolesText(roles: Role[], translate: TFunction<'translation', undefined>) {
    const roleTranlsations = roles.map(role => translate(`roles.${role}`));
    return roleTranlsations.join(', ');
};

export function getRegistrationNumberLabel(registrationNumberType: string | undefined, translate: TFunction<'translation', undefined>): string {
    switch (registrationNumberType) {
        case RegistrationNumberCode.DeHrb:
            return 'HRB';
        case RegistrationNumberCode.DeHra:
            return 'HRA';
        case RegistrationNumberCode.DeVr:
            return 'VR';
        case RegistrationNumberCode.SiTin:
            return 'TIN';
        case RegistrationNumberCode.EeKmkr:
            return 'Kmkr';
        case RegistrationNumberCode.LuRcs:
            return 'RCS (Registre de commerce et des sociétés)';
        default:
            return translate('form_labels.registration_number');
    }
}

export enum StepKey {
    Profile = "PROFILE",
    ApplicantDetails = "APPLICANT_DETAILS",
    BusinessType = "BUSINESS_TYPE",
    BusinessProfile = "BUSINESS_PROFILE",
    Representatives = "REPRESENTATIVES",
    Payouts = "PAYOUTS",
    Summary = "SUMMARY"
}
