import { IconButton, List, ListItem, ListItemText, Tooltip } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { IDocument } from "../../../../../services/getApplicationService";
import { t } from "i18next";
import { getDocumentSideText, getDocumentTypeText } from "../../../../../helpers/applicationHelper";

const UploadedDocuments = (props: IProps) => {
    return (
        <List sx={{padding: 0}}>
            {props.documents.map(doc => (
                <ListItem
                    key={doc.id}
                    secondaryAction={
                        <Tooltip title={t('buttons.delete')}>
                            <IconButton aria-label="delete" onClick={_ => props.onDelete(doc)}><DeleteIcon /></IconButton>
                        </Tooltip>
                    }>
                    <ListItemText primary={doc.name} secondary={`${getDocumentTypeText(doc.type, t)}` + ((props.showSide && doc.side) ? ` (${getDocumentSideText(doc.side, t)})` : '')} />
                </ListItem>))}
        </List>
    );
};

export default UploadedDocuments;

interface IProps {
    documents: IDocument[];
    showSide?: boolean;
    onDelete: (document: IDocument) => void;
}
