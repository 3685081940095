import { Alert, Button, FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, SelectChangeEvent, Stack, TextField } from "@mui/material";
import { ChangeEvent, ReactNode, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { getDocumentTypeText } from "../../../../../helpers/applicationHelper";
import { IPayoutAccount, updatePayoutAccount } from "../../../../../services/updatePayoutAccountService";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import CancelIcon from '@mui/icons-material/Cancel';
import { EditStatus } from "../../Common/EditStatus";
import { ICountry } from "../../../../../services/getCountriesService";
import SelectField from "../../../../shared/SelectField/SelectField";
import { Dropzone, FileValidated } from "@dropzone-ui/react";
import { IDocument } from "../../../../../services/getApplicationService";
import { uploadFile } from "../../../../../services/uploadFileService";
import UploadedDocuments from "../../Common/UploadedDocuments/UploadedDocuments";
import { deleteFile } from "../../../../../services/deleteFileService";
import { DocumentType, ISubmitApplicationResult } from "../../../../../services/commonTypes";
import './PayoutAccountStep.scss';

const PayoutAccountStep = (props: IProps) => {
    const { t: translate } = useTranslation();

    const handleSubmit = async () => {
        const isValid = isFormValid();
        if (!isValid) {
            return;
        }

        if (props.state.editStatus === EditStatus.EditDisabled) {
            props.onSkipped();
            return;
        }

        props.onStateChanged({ ...props.state, isLoading: true });

        try {
            const payoutAccount: IPayoutAccount = {
                accountNumber: { value: props.state.fields.iban!, type: 'iban' },
                accountOwnersName: props.state.fields.accountOwnersName!,
                currency: 'EUR'
            };

            const result = await updatePayoutAccount(props.state.operationUrl!, props.resourceToken, payoutAccount);

            if ('errors' in result) {
                let errors: IFields = {};
                if ('account_number.value' in result.errors) {
                    var errorKey = result.errors['account_number.value'];
                    if (errorKey && errorKey.length > 0) {
                        errors.iban = translate(`validation_errors.${errorKey[0]}`);
                        props.onStateChanged({ ...props.state, isLoading: false, errors: errors });
                        return;
                    }
                }
            } else {
                props.onCompleted(result);
            }
        } catch (error) {
            console.log(error);
            props.onError();
        }

        props.onStateChanged({
            ...props.state,
            isLoading: false,
            editStatus: EditStatus.New,
            errors: {}
        });
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        props.onStateChanged({
            ...props.state,
            fields: {
                ...props.state.fields!,
                [name]: value
            }
        });
    };

    const handleChangeDocumentType = (event: SelectChangeEvent<any>) => {
        props.onStateChanged({
            ...props.state,
            fields: { ...props.state.fields, documentType: event.target.value }
        });
    };

    const handleDropFile = async (file: FileValidated) => {
        const uploadDocumentResponse = await uploadFile(props.state.uploadDocumentOperationUrl!, props.resourceToken, file.file, props.state.fields.documentType!);

        if (!uploadDocumentResponse.success) {
            const error = uploadDocumentResponse.errors.file;

            if (error) {
                const errorMessage = translate('backend_validation_errors.' + error[0], { "supportedFileTypes": "JPEG/JPG, PNG or PDF", "maxFileSize": "4MB" });
                props.onStateChanged({
                    ...props.state,
                    errors: { ...props.state.errors, document: errorMessage }
                });
            }
            return;
        }

        const document: IDocument = {
            id: uploadDocumentResponse.document.id,
            name: file.file.name,
            type: props.state.fields.documentType!,
            links: uploadDocumentResponse.document.links
        };

        const documents = [...props.state.uploadedDocuments, document];

        props.onStateChanged({
            ...props.state,
            uploadedDocuments: documents,
            errors: { ...props.state.errors, document: undefined },
            fields: { ...props.state.fields!, documentType: undefined }
        });
    };

    const handleDeleteDocument = async (document: IDocument) => {
        await deleteFile(props.resourceToken, document.links.self.href);
        const documents = [...props.state.uploadedDocuments];
        const toDelete = documents.indexOf(document);

        documents.splice(toDelete, 1);

        props.onStateChanged({
            ...props.state,
            uploadedDocuments: documents
        });
    };

    const enableEditMode = () => {
        props.onStateChanged(
            {
                ...props.state,
                editStatus: EditStatus.EditEnabled,
                fields: {
                    ...props.state.fields,
                    maskedIban: props.state.fields.iban,
                    iban: '',
                    oldAccountOwnersName: props.state.fields.accountOwnersName
                }
            }
        );
    };

    const disableEditMode = () => {
        props.onStateChanged({
            ...props.state,
            editStatus: EditStatus.EditDisabled,
            fields: {
                ...props.state.fields,
                iban: props.state.fields.maskedIban,
                accountOwnersName: props.state.fields.oldAccountOwnersName
            },
            errors: {}
        });
    };

    const getCountryFromIban = (iban: string) => {
        const countryCode = iban.substring(0, 2);
        const country = props.state.countries.find(c => c.code === countryCode);

        return country;
    }

    const isFormValid = () => {
        let errors: IErrors = {};

        if (props.state.editStatus !== EditStatus.EditDisabled) {

            const ibanMaxLength = 34;
            if (!props.state.fields.iban) {
                errors.iban = translate('validation_errors.required');
            } else if (props.state.fields.iban.length > ibanMaxLength) {
                errors.iban = translate('validation_errors.max_length_reached', { max: ibanMaxLength });
            }

            const accountOwnersNameMaxLength = 300;
            const accountOwnersNameMinLength = 1;
            if (!props.state.fields.accountOwnersName) {
                errors.accountOwnersName = translate('validation_errors.required');
            } else if (props.state.fields.accountOwnersName) {
                if (props.state.fields.accountOwnersName.length > accountOwnersNameMaxLength) {
                    errors.accountOwnersName = translate('validation_errors.max_length_reached', { max: accountOwnersNameMaxLength });
                } else if (props.state.fields.accountOwnersName.length < accountOwnersNameMinLength) {
                    errors.accountOwnersName = translate('validation_errors.min_length_unreached', { min: accountOwnersNameMinLength });
                }
            }
        }

        const hasBankAccountProof = props.state.uploadedDocuments.some(d => d.type === DocumentType.BankAccountProof);

        if (!hasBankAccountProof) {
            errors.missingDocuments = (
                <ul>
                    <li>{getDocumentTypeText(DocumentType.BankAccountProof, translate)}</li>
                </ul>
            )
        }

        props.onStateChanged({ ...props.state, errors: errors });

        return (Object.keys(errors).length === 0);
    };

    useEffect(() => {
        let countryName = '';

        if (props.state.fields.iban && props.state.fields.iban.length >= 2) {
            const country = getCountryFromIban(props.state.fields.iban);

            if (country) {
                countryName = country.name;
            }
        }

        props.onStateChanged({
            ...props.state,
            countryName: countryName
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.state.fields.iban]);

    return (
        <div className="payout-account-step-container">
            <div className="wizard-step-introduction">
                <h2>{translate('steps.payouts.title')}</h2>
                <p><Trans i18nKey='steps.payouts.description' values={{ platformName: props.platformName }} /></p>
            </div>

            <div className='wizard-step-fields'>
                <Stack spacing={2}>

                    {props.state.editStatus === EditStatus.EditDisabled &&
                        <Button startIcon={<ModeEditIcon />} onClick={() => enableEditMode()}>{translate('buttons.edit')}</Button>
                    }

                    {props.state.editStatus === EditStatus.EditEnabled &&
                        <Button startIcon={<CancelIcon />} onClick={() => disableEditMode()}>{translate('buttons.cancel')}</Button>
                    }

                    <FormControl disabled>
                        <FormLabel>{translate('form_labels.account_type')}</FormLabel>
                        <RadioGroup row value={props.accountType}>
                            <FormControlLabel value="individual" control={<Radio />} label={translate('form_labels.individual')} />
                            <FormControlLabel value="business" control={<Radio />} label={translate('form_labels.business')} />
                        </RadioGroup>
                    </FormControl>

                    <div className='payout-account-step-iban'>
                        <TextField
                            id='iban'
                            name='iban'
                            label={translate('form_labels.iban')}
                            variant='outlined'
                            disabled={props.state.editStatus === EditStatus.EditDisabled}
                            value={props.state.fields.iban}
                            onChange={handleChange}
                            error={!!props.state.errors.iban}
                            helperText={props.state.errors.iban}
                            required />
                        <FormHelperText>{props.state.countryName && `${translate('form_labels.country')}: ${props.state.countryName}`}</FormHelperText>
                    </div>

                    <TextField
                        id='accountOwnersName'
                        name='accountOwnersName'
                        label={translate('form_labels.account_owners_name')}
                        variant='outlined'
                        disabled={props.state.editStatus === EditStatus.EditDisabled}
                        value={props.state.fields.accountOwnersName}
                        onChange={handleChange}
                        error={!!props.state.errors.accountOwnersName}
                        helperText={props.state.errors.accountOwnersName}
                        required />

                    <TextField
                        id='currency'
                        name='currency'
                        label={translate('form_labels.currency')}
                        variant='outlined'
                        defaultValue='EUR'
                        disabled />
                </Stack>

                <h2>{translate('steps.common.documents')} *</h2>

                <Stack spacing={2}>
                    {props.state.errors.missingDocuments &&
                        <Alert severity="error">
                            {translate('validation_errors.documents_required')}
                            {props.state.errors.missingDocuments}
                        </Alert>
                    }

                    {(props.state?.uploadedDocuments?.length > 0) &&
                        <UploadedDocuments documents={props.state.uploadedDocuments} onDelete={handleDeleteDocument} />
                    }

                    <SelectField
                        id="documentType"
                        name="documentType"
                        onChange={handleChangeDocumentType}
                        value={props.state.fields.documentType}
                        label={translate('form_labels.document_type')}
                        items={[
                            { value: DocumentType.BankAccountProof, description: translate(`steps.common.document_types.${DocumentType.BankAccountProof}`) }
                        ]} />

                    {props.state.fields.documentType &&
                        <>
                            <Alert severity="info">
                                {props.state.fields.documentType === DocumentType.BankAccountProof && translate('steps.business_profile.bank_account_proof_explanation')}
                            </Alert>

                            <FormControl
                                error={!!props.state?.errors?.document}>
                                {props.state.errors.document &&
                                    <FormHelperText>{props.state.errors.document}</FormHelperText>
                                }
                                <Dropzone
                                    onDrop={(files => handleDropFile(files[0]))}
                                    maxFileSize={4194304}
                                    footer={false}
                                    header={false}
                                    label={translate('form_labels.file_upload')}>
                                </Dropzone>
                            </FormControl>
                        </>
                    }
                </Stack>
            </div>

            <div className="wizard-step-btn">
                <Button id="goBackBtn" color='secondary' size='large' disabled={props.state.isLoading} onClick={props.onBack}>{translate('buttons.back')}</Button>
                <Button id="continueBtn" variant='contained' color='secondary' size='large' type='submit' disabled={props.state.isLoading} onClick={handleSubmit}>{translate('buttons.continue')}</Button>
            </div>
        </div >
    );
};

export default PayoutAccountStep;

export interface IPayoutAccountState {
    countries: ICountry[],
    fields: IFields;
    countryName?: string;
    uploadedDocuments: IDocument[];
    errors: IErrors;
    operationUrl?: string;
    uploadDocumentOperationUrl?: string;
    editStatus: EditStatus;
    isLoading: boolean;
}

interface IProps {
    resourceToken: string;
    state: IPayoutAccountState;
    platformName: string,
    accountType: string;
    onBack: () => void;
    onCompleted: (result: ISubmitApplicationResult) => void;
    onSkipped: () => void;
    onError: (message?: string) => void;
    onStateChanged: (state: IPayoutAccountState) => void;
}

interface IFields {
    iban?: string;
    maskedIban?: string;
    accountOwnersName?: string;
    oldAccountOwnersName?: string;
    documentType?: DocumentType;
}

interface IErrors {
    iban?: string;
    maskedIban?: string;
    accountOwnersName?: string;
    oldAccountOwnersName?: string;
    document?: string;
    missingDocuments?: ReactNode;
}
