export async function handleKoResponse(response: Response) {
    if (!response.ok) {
        const serializedBody = await response.json();
        throw new Error(serializedBody);
    }
};

export interface ValidationErrorResponse {
    errors: any
}
