import { handleKoResponse } from "./common/fetchHelper";

export async function getMerchantCategories(resourceToken: string) {
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authorization', `bearer ${resourceToken}`);

    const requestUrl = `${process.env.REACT_APP_API_URL}/merchant-categories`;
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: headers
    };

    const response = await fetch(requestUrl, requestOptions);
    await handleKoResponse(response);

    const merchantCategories = (await response.json()) as IMerchantCategory[]

    return merchantCategories;
}

export interface IMerchantCategory {
    code: string;
    description: string;
}
