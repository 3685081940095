import "./TermsAndConditionsBody.scss";

const TermsAndConditionsBodyEn = () => {
    return (
        <div className="terms-and-conditions">
            <h1>General Terms and Conditions</h1>

            <p><strong>BETWEEN:</strong></p>
            <p>Digiteal SA having its registered office at Rue Emile Francqui No.6, Box 9, 1435 Mont-Saint-Guibert (Belgium) identified at Banque Carrefour des Entreprises, Belgium, under number BE 0630 675 588, and approved as a payment institution by the NBB since 31 October 2017, hereinafter referred to as <strong>"Digiteal"</strong>.</p>

            <p><strong>AND:</strong></p>
            <p>Any natural or legal person who has accepted these general terms and conditions, Hereinafter referred to as the <strong>"User"</strong>, individually referred to as <strong>"Party"</strong> and together as <strong>"Parties"</strong>.</p>

            <p><strong>WHEREAS:</strong></p>

            <p>Digiteal provides services including:</p>
            <ul>
                <li>Invoice presentment</li>
                <li>A payment method by which the User can make payments and configure automatic payments</li>
                <li>Secure Transactions between Buyers and Sellers through an escrow payment</li>
                <li>Access to Banking Transactions as an AISP</li>
                <li>Initiating bank payments as a PISP</li>
                <li>"Pay with payment terms" Payment Solution</li>
            </ul>

            <p>The Services are available to Users. The User can view and add to the Data that is made available.</p>
            <p>Users provide their explicit agreement on the applicability and content of these General Terms and Conditions and undertake to respect them.</p>

            <p><strong>THIS HAVING BEEN SHOWN, THE FOLLOWING WAS AGREED:</strong></p>

            <h2>ARTICLE 1. Definitions</h2>

            <p>The terms beginning with a capital letter in these general terms and conditions, whether used in the singular or plural, shall have the meanings given to them below.</p>

            <table>
                <tbody>
                    <tr>
                        <td><strong>"Account"</strong></td>
                        <td>means the User registration linked to the Login Codes</td>
                    </tr>
                    <tr>
                        <td><strong>"AISP"</strong></td>
                        <td>means a provider of account information services as defined by the PSD2 Directive</td>
                    </tr>
                    <tr>
                        <td><strong>"API"</strong></td>
                        <td>means any application programming interface made available to the Client or accessible by Digiteal as part of or in connection with a Service</td>
                    </tr>
                    <tr>
                        <td><strong>"Service"</strong></td>
                        <td>means the services offered by Digiteal to the Client and to the End Customer</td>
                    </tr>
                    <tr>
                        <td><strong>"ASPSP"</strong></td>
                        <td>means a payment service provider that provides and manages a payment account for a payer as defined by the PSD2 Directive</td>
                    </tr>
                    <tr>
                        <td><strong>"Automatic Payment"</strong></td>
                        <td>means a payment instruction based on the principle of a traditional direct debit in which the User retains full control over the activation, suspension, withdrawal date and maximum amount of his direct debit per customer relationship to the Client</td>
                    </tr>
                    <tr>
                        <td><strong>"Buyer"</strong></td>
                        <td>means an End Customer acquiring goods or services from a Seller on or through the use of the Services or a Marketplace</td>
                    </tr>
                    <tr>
                        <td><strong>"Buyer "Pay with payment terms" Agreement"</strong></td>
                        <td>means the agreement entered into between a Buyer and Digiteal, setting out the terms of the use of the "Pay with payment terms" Payment Solution by the Buyer</td>
                    </tr>
                    <tr>
                        <td><strong>"Client"</strong></td>
                        <td>means a legal person that agreed to the Client-specific Terms and Conditions of Digiteal and that invites its End Customers to use the Services</td>
                    </tr>
                    <tr>
                        <td><strong>"Client User"</strong></td>
                        <td>means a User that uses the Services on behalf of a Client</td>
                    </tr>
                    <tr>
                        <td><strong>"Contract"</strong></td>
                        <td>means the set of binding documents described in <a href="#contractualDocumentsArticle">ARTICLE 3. Contractual documents</a></td>
                    </tr>
                    <tr>
                        <td><strong>"Creator of the Escrow Payment"</strong></td>
                        <td>means the User who has defined the escrow payment, whether they are a Buyer, Seller or third party</td>
                    </tr>
                    <tr>
                        <td><strong>"Credit"</strong></td>
                        <td>means the credit made available by Digiteal to the Buyer under the form of the Prepayment of the Transaction to the Seller; which becomes due for repayment by the Buyer to Digiteal after a deferred payment term of a given number of days (60 days by default) pursuant to the Buyer "Pay with payment terms" Agreement</td>
                    </tr>
                    <tr>
                        <td><strong>"Credit Limit"</strong></td>
                        <td>means the maximum total amount of Credit that can be granted to one respective Buyer through the "Pay with payment terms"”" Payment Solution at any single moment in time and in relation to one or more Transactions</td>
                    </tr>
                    <tr>
                        <td><strong>"Data"</strong></td>
                        <td>means the information, publications, files, texts, sounds, programs and, in general, data from the User. This is further detailed in <a href="#dataArticle">ARTICLE 2. Data</a></td>
                    </tr>
                    <tr>
                        <td><strong>"Debtor"</strong></td>
                        <td>means the User that performs the payment and from whom funds are withdrawn</td>
                    </tr>
                    <tr>
                        <td><strong>"Delivery Confirmation"</strong></td>
                        <td>means the confirmation of the due delivery of services, products or goods by the Seller(s) in accordance with the terms of aTransaction</td>
                    </tr>
                    <tr>
                        <td><strong>"End Customer"</strong></td>
                        <td>means a natural or legal person to whom the Client addresses the Services</td>
                    </tr>
                    <tr>
                        <td><strong>"Escrow Payment"</strong></td>
                        <td>means a payment service where the funds of the Buyer are secured and transferred to the Seller after a condition has been met. This is further described in <a href="#escrowPaymentService">ARTICLE 8.2 Escrow Payment service (for purchase and sale Transactions)</a></td>
                    </tr>
                    <tr>
                        <td><strong>"GDPR"</strong></td>
                        <td>means applicable laws and regulations relating to protection of data including the General Data Protection Regulation (EU Regulation 2016/679), the relevant provisions of the Belgian law as well as such legislation implementing and/or offering similar or equivalent protection to that provided by the General Data Protection Regulation, as may be amended, supplemented or replaced, on the protection of individuals with regard to the processing of Personal Data as implemented in Belgium or in any other country from which or to which the Services are provided, any regulations or other measures having statutory force made under them, and any codes of practice or guidance issued by any relevant authority</td>
                    </tr>
                    <tr>
                        <td><strong>"KYC"</strong></td>
                        <td>means Know Your Customer and refers to the registration procedure during which the User is requested to provide information enabling Digiteal to implement anti-money laundering (AML), anti-terrorist and anti-fraud measures</td>
                    </tr>
                    <tr>
                        <td><strong>"Login Codes"</strong></td>
                        <td>means both the User's own identifier ("login") and the password chosen when registering for the Services</td>
                    </tr>
                    <tr>
                        <td><strong>"Marketplace"</strong></td>
                        <td>means a business-to-business e-commerce platform aggregating products and/or services listed by various third-party Sellers on its platform for acquisition by various third-party Buyers</td>
                    </tr>
                    <tr>
                        <td><strong>"Marketplace Commission"</strong></td>
                        <td>means the commission payable to the Marketplace on a per Transaction basis, as stipulated and updated from time to time in the general terms and conditions of the Marketplace</td>
                    </tr>
                    <tr>
                        <td><strong>"Marketplace Operator"</strong></td>
                        <td>menas a Client operating a Marketplace</td>
                    </tr>
                    <tr>
                        <td><strong>"Pay with payment terms" Payment Solution"</strong></td>
                        <td>means one of the Services offered by Digiteal, allowing (i) the Buyers on the Marketplace to pay after a deferred payment term and (ii) the Sellers on the Marketplace being prepaid early in accordance with the terms of the respective Transaction</td>
                    </tr>
                    <tr>
                        <td><strong>"Personal Data"</strong></td>
                        <td>means all data and information that can be used to identify a natural person as the terms is defined in the GDPR and as further detailed in <a href="#dataArticle">ARTICLE 2. Data</a></td>
                    </tr>
                    <tr>
                        <td><strong>"PISP"</strong></td>
                        <td>means a payment initiation service provider within the meaning of PSD2</td>
                    </tr>
                    <tr>
                        <td><strong>"Prepayment"</strong></td>
                        <td>means the payment effectuated by Digiteal to the Seller, the Marketplace Operator and any other party to be credited in a Transaction, amounting to (a portion of) the face value of the Invoice for one single Transaction</td>
                    </tr>
                    <tr>
                        <td><strong>"PSD2"</strong></td>
                        <td>means the Directive (EU) 2015/2366 of the European Parliament and of the Council of 25 November 2015 on payment services in the internal market, amending Directives 2002/65/EC, 2009/110/EC and 2013/36/EU and Regulation (EU) No 1093/2010, and repealing Directive 2007/64/EC and its transposition into Belgian law</td>
                    </tr>
                    <tr>
                        <td><strong>"PSU"</strong></td>
                        <td>means a payment service user, i.e. a natural or legal person who uses a payment service as payer, payee or both, within the meaning of the PSD2 Directive</td>
                    </tr>
                    <tr>
                        <td><strong>"Public Data"</strong></td>
                        <td>means the Data that can be viewed by the public</td>
                    </tr>
                    <tr>
                        <td><strong>"Registration Data"</strong></td>
                        <td>means the Data provided upon registration to the Services</td>
                    </tr>
                    <tr>
                        <td><strong>"Seller"</strong></td>
                        <td>means an End Customer selling goods and/or services to a Buyer on or through the use of the platform or the Marketplace of a Client</td>
                    </tr>
                    <tr>
                        <td><strong>"Seller Invoice"</strong></td>
                        <td>means the invoice issued by the Marketplace Operator on behalf of the Seller to the Buyer pursuant to the receipt of the Delivery Confirmation in relation to one specific Transaction</td>
                    </tr>
                    <tr>
                        <td><strong>"Service"</strong></td>
                        <td>means the services offered by Digiteal to the Client and to the End Customer as described in "ARTICLE 8"</td>
                    </tr>
                    <tr>
                        <td><strong>"Service Desk"</strong></td>
                        <td>means the Service Desk of Digiteal available at <a href="https://support.digiteal.eu" target="_blank" rel="noreferrer">support.digiteal.eu</a></td>
                    </tr>
                    <tr>
                        <td><strong>"Software"</strong></td>
                        <td>means software provided to the User as part of and/or in connection with the Service</td>
                    </tr>
                    <tr>
                        <td><strong>"Strong Customer Authentication" or "SCA"</strong></td>
                        <td>means the secure multi-factor authentication as described in Art. 4(30) of PSD2</td>
                    </tr>
                    <tr>
                        <td><strong>"Transaction"</strong></td>
                        <td>means a purchase agreement for goods and/or services entered into between a Buyer and a Seller on the platform or through the Marketplace of a Client</td>
                    </tr>
                    <tr>
                        <td><strong>"Transaction Dispute"</strong></td>
                        <td>means any dispute between a Buyer and the Seller regarding a Transaction, including (without being limited to) any alleged claim regarding non-conformity of the services, products or goods, or any alleged claim for cashback, any alleged fraud, suspicion of fraud</td>
                    </tr>
                    <tr>
                        <td><strong>"Transaction Information"</strong></td>
                        <td>
                            means all information related to Transactions occurring on a Marketplace or required for the assessment of the credit risk of the Buyers, including (without being limited to):
                            <ul>
                                <li>Details of the Buyers and the Seller (including name, place of incorporation, VAT-numbers, national company reference number, email addresses, telephone numbers, identification of ultimate beneficial owners).</li>
                                <li>Bank account details.</li>
                                <li>Payment flows (including payment statuses and payment dates).</li>
                                <li>Payment history of the Buyers on the Marketplace.</li>
                                <li>Invoices.</li>
                                <li>Delivery Confirmations.</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td><strong>"User"</strong></td>
                        <td>means the Buyer, Seller, PSU or Client who holds Login Codes and uses the Services</td>
                    </tr>
                    <tr>
                        <td><strong>"Working Days"</strong></td>
                        <td>means every day of the week between Monday and Friday excluding Belgian bank holidays</td>
                    </tr>
                    <tr>
                        <td><strong>"Working Hours"</strong></td>
                        <td>means 9AM to 5PM, CET/CEST on every Working Day</td>
                    </tr>
                </tbody>
            </table>

            <h2 id="dataArticle">ARTICLE 2. Data</h2>

            <p>2.1 "Data" means all information that is generated through the use of the Services or recorded in a format determined by Digiteal such as, but not limited to, databases, invoices, payment requests, documents, administrative data, device characteristics, software, graphic representations, photos, illustrations, sounds, film recordings, and messages. This Data is kept on servers in EU countries. The exchange of information with Digiteal is carried out in a secure manner</p>
            <p>2.2 All Data, whether publicly available or privately sent through the Services, is the sole responsibility of the User from whom it originates. This means that the User, and not Digiteal, is solely responsible for the Data that he has uploaded, downloaded, published, emailed, sent, recorded or otherwise made available through use of the Services. Digiteal has no control over the Data that is published via the Services and neither does Digiteal guarantee the accuracy, integrity or quality of such Data. The User understands and agrees that his use of the Services and Data is at his own risk. Complaints or questions regarding this Data are therefore not dealt with by Digiteal, but must be sent directly to the Users.</p>
            <p>2.3 Digiteal reserves the right to impose limitations on the Data, such as the number and/or size of documents that may be uploaded during a specified period of time, in order to limit the risks of improper or excessive use of the Services</p>
            <p>2.4 Digiteal will use all reasonable efforts and care in the provision of the Services. Except in cases of serious or deliberate misconduct, Digiteal shall not be liable for any damage, loss or deletion of the Data. The User can use the Data at any time</p>
            <p>2.5 Specifications of Personal Data: Data deemed personal is:</p>
            <ul>
                <li>The surname, first name, telephone number(s), postal address, and e-mail address of the Users.</li>
                <li>The data necessary to complete the transaction, coded in the QR code or elsewhere, namely the BIC number of the beneficiary's bank, its name, its account number in IBAN format, the amount and the currency of the transaction</li>
                <li>The documents transmitted, and in particular the invoices/expenses/invitations to pay, but also the documents sent by the Client for the purposes of certifying quality (identity card, statutes, other documents).</li>
            </ul>
            <p>2.6 Public Data specifications: Data deemed public is all Data that is not deemed private or confidential by nature, including such Personal Data.</p>

            <h2 id="contractualDocumentsArticle">ARTICLE 3. Contractual Documents</h2>

            <p>3.1 These General Terms and Conditoins, together with the documents entitled</p>
            <ul>
                <li><strong>"Data Privacy Policy"</strong> and</li>
                <li>potentially the <strong>"Client-specific Terms and Conditions"</strong> and</li>
                <li>potentially a <strong>"Marketplace Agreement"</strong></li>
            </ul>
            <p>constitute the entirety of the commitments existing between the Parties (hereinafter referred to as "the Contract"). The Contract replaces and cancels any previous oral or written undertakings relating to its subject matter</p>
            <p>3.2. It is formally agreed between the Parties that any allowance or waiver by one of the Parties, in the application of all or part of the commitments provided for in the Contract, irrespective of the frequency and duration, shall not constitute an amendment of the Contract, nor shall it be deemed to create any right whatsoever</p>

            <h2>ARTICLE 4. Access to the Services</h2>

            <p>4.1 The User, whether natural persons directly or representative of a legal entity, must be at least 18 years old to access the Service.</p>
            <p>4.2 The User must create an Account. To use the Service, he must enter his Login Codes to authenticate his Account. Upon registration, the User will be requested to provide Registration Data. He agrees to provide accurate and complete Registration Data when he registers for and makes use of the Service. He also agrees to systematically update his Registration Data in order to ensure it remains accurate and complete including his address, bank accounts and any renewed identity document.If, despite this obligation, the Registration Information is not up to date, the User must update it upon request from Digiteal</p>
            <p>Failure to provide accurate, current and complete Registration Data may result in the suspension and/or closure of the Account. The User agrees that Digiteal may register and use the Registration Data for the performance of the Contract. The User may not share his Login Codes with anyone else. The User is solely responsible for maintaining the confidentiality and security of his Login Codes and all activities that occur on or through his Account, and he agrees to immediately notify Digiteal of any breach of security relating to his Account</p>
            <p>If the User is a natural person, he agrees that the rights to his Account shall terminate in the event of death. If his mandate as a representative of a legal entity is terminated for any legal reason, another representative of the same legal entity can request the Account to be transferred to him.</p>
            <p>4.3 Digiteal reserves the right to refuse access to the Service on the grounds of non compliance with the KYC</p>
            <p>4.4 The Service is accessible through Strong Customer Authentication.</p>

            <h2>ARTICLE 5. Use of Services</h2>

            <p>5.1 Digiteal grants the User a temporary, non-exclusive, personal and non-transferable right to use the Services. Digiteal is not liable for any damages resulting from incompetent usage of the Account due to the User's failure to follow these regulations. The User is responsible for any use or abuse of this right of use by third parties.</p>
            <p>5.2 The User does not have the right to market the Service or any part of it. In particular, bank transaction access and payment initiation services are only available for the User's own bank accounts for which he is the holder (PSU).</p>
            <p>5.3 The Service or some of its equipment or parts may not be available in all languages or countries, and Digiteal does not undertake that the Service or any of its equipment or parts will be appropriate or available at any specific location. To the extent that the User chooses to access and use the Service, the User does so on his own initiative and he is responsible for complying with any applicable laws</p>
            <p>5.4 Use of the Service requires the use of compatible devices, Internet access and specific software (for which an allowance may apply); periodic updates are required and the operation of these factors may influence the use of the Service. A fast Internet connection is strongly recommended for using the Service. The most recent version of the software required to access the Service is recommended and may be required for some transactions or equipment. The User agrees that he is solely responsible for meeting these requirements, which may be modified at specified times. As soon as the User notices or reasonably should notice that the equipment or software he is using is not suitable for connecting to the Service, it does not work properly, or it hinders and/or disrupts the use or operation of the Service or network, the User must stop using such equipment.</p>
            <p>5.5 Digiteal has the right to modify or discontinue the Service (or parts thereof), either temporarily or permanently. In this case, Digiteal will send an email to the email address that is linked to the Account, to inform the User. It is the User's responsibility to check if such changes have been notified to his email address.</p>
            <p>5.6 The User agrees to use the Service only for the purposes authorised under this Contract and in accordance with applicable laws and regulations or generally accepted practices. Digiteal may give the User instructions regarding the use of the Service for operational, quality, security and other reasons. The User undertakes to follow these instructions</p>
            <p>5.7 The User agrees NOT to use the Service:</p>
            <ol type="a">
                <li>to upload, download, post, publish, email, send, record or otherwise make available Data that is unlawful, intimidating, threatening, harmful, tortious, defamatory, humiliating, offensive, violent, obscene or vulgar, or that violates the privacy of others, is hateful, racist or ethnically or otherwise objectionable</li>
                <li>to claim to be a person or entity that the User is not. Digiteal reserves the right to refuse or block an Account if it may be deemed that it reflects an inaccurate portrayal of his identity or involves the improper use of another person's name or identity</li>
                <li>to engage in activities that infringe copyright or other intellectual property rights (including uploading Data that the User does not have the right to upload)</li>
                <li>to upload, publish, email, send, store or otherwise make available material that contains viruses or other computer code, files or programs that are designed to harm, interfere with, or limit the normal operation of the Service (or any part thereof) or other computer software or hardware</li>
                <li>to interfere with or disrupt the Service or servers and/or networks connected to the Service, or the policy, requirements or provisions of networks connected to the Service (including any illegal access, use or access to data or traffic on the Service)</li>
                <li>to plan or develop illegal activities</li>
                <li>to collect and store personal information pertaining to other users of the Service for use in connection with any of the foregoing prohibited activities</li>
            </ol>
            <p>5.8 If the User's use of the Service or other behaviour (voluntary or involuntary) endangers the Service or other systems, Digiteal has the right to take all reasonable measures to protect the Service and Digiteal's systems including the option of suspending access to the Service. If the User commits a serious and/or repeated breach of the obligations set out in this Contract, Digiteal may suspend the provision of the Service by simple written notice until he complies with all obligations, or terminate the provision of the Service automatically, ipso jure and without formal demand, prior notice or compensation. Transactions in progress at the moment at which the granting of the Service is ended, will be carried out under the conditions described in <a href="#descriptionOfServicesArticleGeneral">ARTICLE 8. Description of Services</a>.</p>

            <h2>ARTICLE 6. Liability</h2>

            <p>6.1 Digiteal cannot guarantee that Digiteal is adapted to the User's specific needs and requests, in particular with regard to their computer and telecommunications system. Digiteal only assumes an obligation of means that is strictly limited to the provision of the Services</p>
            <p>6.2 The Service is offered in the state in which it exists. In particular, Digiteal cannot give any guarantee that the Service will always be accessible or that there will be no interruptions or errors in the Service or its provision.</p>
            <p>6.3 Digiteal will take all reasonable measures to ensure that the Service will be free from loss, corruption, attacks, viruses, interference, hacking or other security breaches but Digiteal accepts no responsibility in this regard.</p>
            <p>6.4 The User himself is responsible for the equipment and software necessary to join the Service and for the configuration of this equipment and software. Adaptations to the configuration of the equipment and/or software are always made at the User's risk.</p>
            <p>6.5 Except in cases of serious or deliberate misconduct, Digiteal disclaims any liability arising from the Service, e.g., the provision of the Service, the use of the Service or the inability to use the Service, modifications to the Service or temporary or permanent discontinuation of the Service or parts thereof.</p>
            <p>6.6 The responsibility of Digiteal and its related companies is in all events limited to compensation for foreseeable, direct and personal damage suffered by the User, excluding, but not limited to, any indirect or immaterial damage such as professional loss, loss of income or profit, loss or damage to data and additional costs.</p>
            <p>6.7 The User protects Digiteal and its associated companies from any damage, loss, costs, action or expense arising from the misuse of the Service by the User or others and/or as a result of disregarding the terms of this Contract or the rights of third parties.</p>
            <p>6.8 Complaints can be submitted to the Service Desk or by sending an email to <a href="mailto:support@digiteal.eu">support@digiteal.eu</a>.</p>

            <h2>ARTICLE 7. Consent of the PSU</h2>

            <p>7.1 Digiteal shall seek the agreement of the PSU if and when required under the PSD2. This consent is on the one hand for the execution of the bank account access and payment initiation service and on the other hand for the sharing of bank account access information with third parties. Each consent is worded in such a way that it is specific to the PSU, to the bank account, to the payment or possibly to a list of bank accounts and payments and finally to the third party if applicable. Digiteal may also request the agreement of the PSU or request the PSU to reaffirm its prior consent, in cases where Digiteal is not required to do so. For these purposes, Digiteal shall request the PSU and the Client to take a positive action by ticking a box or clicking on a button as provided on the page or screen of the relevant Service. This button shall be marked "I accept", "I consent", "Confirm", "Next", "Continue", "Submit" or an equivalent expression. By ticking this box or clicking on this button, the PSU or Client shall be deemed to consent to the transaction concerned or to reaffirm its previously given consent, as the case may be</p>
            <p>7.2 In cases where a PSU is permitted to withdraw a previously given consent, Digiteal may use techniques similar to those mentioned in Section 7.1 above to request and record the PSU's withdrawal of a previously given consent. The checkbox or button shall then be labelled "I withdraw consent", "I disagree", "Cancel" or an equivalent phrase. By checking this box or clicking on this button, the PSU is deemed to have withdrawn its consent for the transaction in question.</p>
            <p>7.3 As part of the Service, the Client may be authorized to add or delete bank accounts and PSUs. In the event of addition, Digiteal is required to ask the PSU to provide or reaffirm its consent to the provision of information from such bank accounts to the Client</p>
            <p>7.4 To the extent permitted by law, Digiteal may use any logging or other available information (including, without limitation, transactional data as recorded in its systems used for the Service) as conclusive evidence of such consent from the PSU or withdrawal of consent from the PSU, unless the PSU provides evidence to the contrary.</p>

            <h2 id="descriptionOfServicesArticleGeneral">ARTICLE 8. Description of Services</h2>

            <h3>8.1 Payment service (for invoices or payment requests)</h3>

            <p>8.1.1 Payment: the basic payment service offered is based on a European direct debit. In specific terms, this direct debit means that the User has the choice of paying, either on a per invoice basis, or by activating Automatic Payments.</p>
            <p>8.1.2 Refund: the User may request a refund from their bank for a payment made by direct debit up to 8 weeks after the debit has been made. Please note: a refund request does not mean that the claim is cancelled.</p>
            <p>8.1.3 Suspension and Cancellation: accessed from the Digiteal platform, the User may at any time, and by means of a single click, suspend or cancel an Automatic Payment configured by the Client.</p>
            <p>8.1.4 Digiteal may block a specific transaction or payment instrument based on security considerations. If a payment is blocked, the User will be informed by email. The User will then be able to request more information about the blocking and may have the option of requesting that the payment is unblocked by the Service Desk</p>
            <p>8.1.5 If the User becomes aware that a suspected fraudulent payment has been made from his Account, or there are anomalies, social engineering attempts or other suspicious incidents, Digiteal kindly requests that the User contacts the Service Desk. Digiteal will answer as soon as possible. Similarly, if Digiteal becomes aware of suspicious transactions on his Account or phishing attempts,Digiteal will also notify the User by email. However, Digiteal will never ask the User's Login Codes in an email or telephone conversation. Digiteal therefore urges the User to be careful to always keep these Login Codes private and not to communicate them to anyone.</p>
            <p>8.1.6 In addition to the direct debit described in point 8.1.1, Digiteal offers the User the option to</p>
            <ul>
                <li>consult their balance and banking history (account information service)</li>
                <li>and initiate transfers directly from their account (payment initiation service)</li>
            </ul>
            <p>provided that this bank account is held with a financial institution to which Digiteal is linked. The User has to make an explicit choice within Digiteal if they want to provide Digiteal with this access. Strong Customer Authentication is required to make this choice, to obtain this data, and to initiate payments in complete security. In addition, to allow these account information and payment initiation services to be used, explicit consent will be required by the financial institution that administers the PSU's bank accounts. The request for consent always mentions the service to be authorised, the reason for the request, and the period for which consent is requested. Payment initiation is carried out directly by means of confirmation that is presented to the User. The consultation of historical banking transactions is either carried out directly in respect of a specific need or active for a maximum of 90 days. To cover the professional risks related to accessing account information and initiating payment services, Digiteal has taken out professional liability insurance with CNA. This insurance covers damage up to €2,000,000 in accordance with Directive (EU) 2015/2366 of the European Parliament and of the Council of 25 November 2015 regarding payment services in the internal market, Title II, Chap.1, Sect.1, Art.5, §2 and 3 and The Code of Economic Law (CDE), Title 3 "Payment Services" of Book VII</p>
            <p>8.1.7 As a payment institution, Digiteal is subject to the NBB Regulation of 21 November 2017 published in the Belgian Official Gazette on 10 December 2017 implementing the law of 18 September 2017 on the prevention of the use of the financial system for the purposes of money laundering and funding of terrorism and the limitation of the use of cash</p>

            <h3 id="escrowPaymentService">8.2 Escrow Payment service (for purchase and sale Transactions)</h3>

            <p>8.2.1 Escrow Payments is a service that allows users to pay an agreed amount. Digiteal helps Sellers and Buyers to fulfil their contractual obligations. Digiteal is neither a Buyer, nor a Seller, nor a representative of any of the parties. Digiteal's neutrality ensures transfers are both reliable and secure. Digiteal undertakes exclusively to execute the secure Transaction upon the agreement of the parties. Digiteal does not verify the presence of associated third party rights and therefore does not assume any guarantee in this respect.</p>
            <p>8.2.2 The Escrow Payment proposed by Digiteal is a conditional payment. The validation of this Escrow Payment is either based on an amount to be reached within a certain time frame, or on a validation of the stakeholders involved in the Transaction and specified at the outset. The terms of payment are explicitly presented to the Buyer. The Buyer must accept these conditions before being asked to make the payment. To make the payment, the Buyer makes a transfer to a clearing account. This clearing account is a third party account operated by Digiteal. The remittance of funds as part of an Escrow Payment does not constitute a bank deposit and does not bear any interest. If the conditions are met, the funds are transferred to the Seller. If the conditions are not met, the funds are returned to the Buyer</p>
            <p>8.2.3 When using the Escrow Payment service, a number of different scenarios are possible:</p>
            <ul>
                <li><strong>The funds have not yet been received by Digiteal</strong>: the Creator of the Escrow Payment can cancel it free-of-charge. If funds arrive after the cancellation, they will automatically be refunded to the Debtor.</li>
                <li>
                    <strong>The funds were received by Digiteal:</strong>
                    <ul>
                        <li>The Buyer and Seller have confirmed the Transaction, the funds are transferred to the Seller within 2 Working Days.</li>
                        <li>The Buyer and Seller have cancelled the Transaction, the funds are transferred to the Buyer within 2 Working Days.</li>
                        <li>The Buyer unilaterally cancels the Transaction; the agreed sum will be refunded in full to the Buyer at their request after 8 calendar days.</li>
                        <li>If an agreement is reached with the Seller within 8 calendar days, the Buyer can change the status so that the amount is released to the Seller</li>
                    </ul>
                </li>
            </ul>
            <p>8.2.4 Other points to note related to Escrow Payments:</p>
            <ul>
                <li>If the Buyer or Seller has made an operator error (and has confirmed using their pincode) and the Transaction has been cancelled or confirmed, Digiteal invites them to contact the other party to rectify the situation.</li>
                <li>
                    In terms of IT security, several levels of security protect the funds:
                    <ul>
                        <li>The funds are held by Belfius, which is responsible for their safekeeping on behalf of Digiteal.</li>
                        <li>Digiteal uses encryption and SCA to secure access.</li>
                        <li>All payments are subject to a manual final check. Digiteal's signature is required to initiate payments.</li>
                        <li>In the event of a data breach, no system is 100% safe from a tenacious hacker. However, Digiteal has a very secure computer system, which is verified by our security officer and the external data protection officer, as well as penetration tests being performed annually in order to test Digiteal's security defences.</li>
                    </ul>
                </li>
                <li>Digiteal's payment services may not be used for illegal purposes (fraud, funding of terrorism, money laundering, as well as the purchase/sale of illegal products such as drugs, trafficking in human beings, etc. this list is not exhaustive). Should Digiteal be used for these purposes, Digiteal is required to inform the competent authorities. Should financial regulators request information from Digiteal regarding suspicious transactions falling under an enforceable mandate in Belgium, Digiteal will comply with it and hand over the information that is in our possession (identity of the parties, amount of Transactions, history).</li>
                <li>Any funds awaiting transfer belong to the Buyer until the Transaction is confirmed. In the event of claims by third parties (bankruptcies/competition between Sellers, tax or social security debts), Digiteal will enforce court decisions that have been taken by the Belgian courts or that have an enforceable title valid in Belgium once they have notified the parties involved</li>
                <li>Digiteal will permit the User to carry out international Transactions. The Buyer's account must be located in the SEPA zone. Digiteal integrates embargoes and other Belgian and European economic and financial sanctions into its onboarding and Transaction validation procedures</li>
            </ul>
            <p>8.2.5 If the Seller is a professional, in order to use the Escrow Payment service, they must have their own contract complete with general terms and conditions of sale duly signed by both parties. This contract is binding on both the Buyer and the Seller. If the Seller is a private person, they should only consider the Transaction as final when all parties have either confirmed or cancelled the transaction. A notification and an email will indicate that this has been done. The transaction may be deemed completed by the Seller when their mobile application indicates that payment to their bank account is in progress.</p>

            <h3>8.3 Access to banking transactions (AIS)</h3>

            <p>When Digiteal provides bank transaction access services to the PSU acting as beneficiary or Debtor, it will provide the following information to the PSU, provided that the relevant ASPSP makes it available to Digiteal</p>
            <ul>
                <li>A reference enabling the identification of the transaction and of the other party.</li>
                <li>Any information transferred with the payment such as amount, communication, currency and value date.</li>
            </ul>

            <h3>8.4 Payment Initiation Service (PIS)</h3>

            <p>When Digiteal provides the payment initiation service, it does so for the PSU once it has duly collected the consent of the PSU. The manner in which this initiation is transmitted to the ASPSP depends on the ASPSP and must require a Strong Customer Authentication of the PSU by the ASPSP</p>
            <p>When Digiteal provides payment initiation services to the PSU acting as Debtor, Digiteal will provide the following information to the PSU, provided that the relevant ASPSP makes it available to Digiteal</p>
            <ul>
                <li>A reference for each payment transaction and, where applicable, information relating to the Creditor.</li>
                <li>The payment amount in the currency in which the Debtor's account is debited.</li>
                <li>Where applicable, the exchange rate used in the payment transaction and the amount of the payment after this currency conversion</li>
                <li>The debit value date or the date of receipt of the payment order.</li>
            </ul>

            <h3>8.5 Service for sending and receiving invoices by PEPPOL</h3>

            <p>Digiteal integrates PEPPOL, the European standard for invoicing. When the User chooses to receive all his invoices in Digiteal, Digiteal registers him in the PEPPOL directory. The identifiers used are the national register number for individuals and the company number (+ VAT number) for companies. IBANs are also used as identifiers in the PEPPOL directory for private individuals and companies. No personal information is linked to these registrations</p>

            <h3>8.6 Payment terms for B2B marketplaces and e-commerce</h3>

            <p>8.6.1 Digiteal provides a Service that grants payment terms to Buyers representing legal entities: the "Pay with payment terms" Payment Solution When "Pay with payment terms" Payment Solution is used, Digiteal proceeds with the Prepayment of the Seller and any other third parties in a Transaction as soon as the service or the goods have been confirmed to be delivered. The Buyer needs to enter into a specific per Transaction Buyer "Pay with payment terms" Agreement in order to access the Credit</p>
            <p>8.6.2 For any Transaction, the use of the "Pay with payment terms" Payment Solution and the related Prepayment to the Seller shall be conditional on the following conditions being met:</p>
            <ul>
                <li>The Buyer agreed to use the "Pay with payment terms" Payment Solution for the Transaction concerned and executed all necessary documents for that purpose.</li>
                <li>The Buyer and the Seller have passed the onboarding process of Digiteal.</li>
                <li>The Seller Invoice instructs the Buyer to pay on the following bank account within a predefined number of days as from the date of the Transaction: BIC: GKCCBEBB, IBAN: BE61 0689 3809 5717, Branch: Belfius Nationale 4 - Brabant Wallon, Chaussée de Namur 46, 1457 Nil-Saint-Vincent-Saint-Martin, Belgium</li>
                <li>A duly executed Delivery Confirmation and an Invoice proving the delivery of the goods or services in accordance with the terms of the Transaction have been provided to Digitea</li>
                <li>The Seller(s) is/are not affiliated to the Buyer in the meaning of Article 1:20 of the Companies and Associations Code (as amended from time to time)</li>
                <li>Digiteal considers that:
                    <ul>
                        <li>The Buyer is eligible to use the "Pay with payment terms" Payment Solution as a payment method.</li>
                        <li>Where relevant, there is enough capacity under the Buyer's available Credit Limit for the "Pay with payment terms" Payment Solution being used as a payment method for the Prepayment</li>
                    </ul>
                </li>
            </ul>
            <p><strong>Repayment obligation in case of a Transaction Dispute</strong></p>

            <p>8.6.3 The Seller acknowledges and agrees that, in case a Transaction Dispute arises between itself and a Buyer, it shall be automatically obligated to repay to Digiteal the amount of the Prepayment</p>
            <p>8.6.4 Any costs incurred by Digiteal in relation to the claim for repayment shall be reimbursed by the Seller to Digiteal. Where appropriate, Digiteal shall be authorised to set-off any such costs against any sums owed to the Client (including future payments in relation to other Transactions).</p>
            <p><strong>Assistance in case of non-payment of Digiteal by the Buyer</strong></p>
            <p>8.6.5 In case the Buyer does not repay the Credit to Digiteal when it becomes due, the Seller shall assist Digiteal where appropriate with the debt collection efforts against the Buyer.</p>
            <p>8.6.6 In case of non-payment by the Buyer, the Seller shall assign the invoice issued in relation to a specific Transaction to Digiteal when instructed to do so by Digiteal.</p>
            <p>8.6.7 The Seller acknowledges and agrees that the Prepayment will be equal to (a portion of) the amount of the face value of the Invoice after deduction of the Marketplace Commission. The Seller irrevocably instructs Digiteal to pay the Marketplace commission to the Marketplace Operator on its behalf. This Contract applies to the execution of that payment. The Seller acknowledges and agrees that the Prepayment qualifies as a partial payment of the amount due by the Buyer under the Seller Invoice. The Seller shall transfer any funds it receives into its own bank account from the Buyer for Transactions for which the "Pay with payment terms" Payment Solution is used immediately and without any delay to Digiteal's bank account.</p>
            <p><strong>Access to the Transaction Information</strong></p>
            <p>The User hereby grants access to Digiteal to all Transaction Information as necessary for Digiteal to assess the eligibility of the Buyer and the Seller for the use of the "Pay with payment terms" Payment Solution and its further management. The User hereby authorises the Marketplace Operator to provide such Transaction Information to Digiteal upon request of Digiteal.</p>
            <p>The User hereby grants access to Digiteal to all Transaction Information as necessary for Digiteal to assess the eligibility of the Buyer and the Seller for the use of the "Pay with payment terms" payment solution and its further management. The Seller hereby authorises the Marketplace Operator to provide such Transaction Information to Digiteal upon request of Digiteal. The User hereby also authorises Digiteal and the Marketplace Operator to transfer and provide Transaction Information necessary for the management of the Credit to any third party that has a formal interest in the acquisition of all or part of the claims of Digiteal and/or any right or obligation of Digiteal under this Agreement.</p>

            <h2>ARTICLE 9. Costs</h2>

            <p>9.1 Use of the Service is free-of-charge for the User. The free Service offered is set out in the following exhaustive list:</p>
            <ul>
                <li>Ability to link up with Clients</li>
                <li>Option to receive new documents from Clients</li>
                <li>Option to consult a document</li>
                <li>Option to process these documents at a later date</li>
                <li>Ability to view documents on a mobile device</li>
                <li>Option to obtain reminders and notifications about administrative delays</li>
                <li>Option to adapt data relating to management of the User and notifications</li>
            </ul>
            <p>9.2 The cost of the Services is borne by the Clients.</p>
            <p>9.3 The use of the Service is subject to a charge for the Creator of an Escrow Payment. Transaction fees are due and calculated on the basis of the fee schedule (see <a href="https://www.digiteal.eu/pricing/" target="_blank" rel="noreferrer">digiteal.eu/pricing</a>). Transaction fees are only due if the transaction is confirmed. Transaction fees are charged to the Creator of the Escrow Payment at the end of the month in which the transaction was confirmed. This invoice, presented directly in the Digiteal web and mobile application, is payable within 15 calendar days.</p>
            <p>9.4 If the invoice is not paid on the due date, except in the event of a good faith dispute, Digiteal is entitled, ipso jure and without prior formal notice, to payment of interest at the key interest rate plus seven percentage points and rounded to the nearest half percentage point, as from the day following the expiry of this period. The key interest rate is the interest rate applied by the European Central Bank for its most recent main refinancing operation, as defined by the law of 2 August 2002.</p>
            <p>If the User fails to pay despite a reminder being sent, Digiteal is also entitled, without prejudice to its right to reimbursement of legal costs in accordance with the provisions of the Judicial Code, to claim from the User a fixed compensation of 10%, with a minimum of €50, in accordance with the law of 2 August 2002 on the fight against late payment in commercial matters.</p>
            <p>9.5 Digiteal will not charge any commission or fee to the Buyer or the Seller for the use of the "Pay with payment terms" Payment Solution.</p>

            <h2>ARTICLE 10. Intellectual property rights</h2>

            <p>10.1 The User acknowledges and agrees that Digiteal and/or its licensors own all legal rights, titles and interests in and to the Service, including, but not limited to, graphic representations, user interface, scripts and software that are used for the implementation of the Service and the Software, including all of the intellectual property rights that exist therein, registered or not, and wherever they may be located in the world. The User also agrees that the Service (including the Software, or part thereof) contains company-specific and confidential information that is protected by the relevant intellectual property laws and other laws, including, but not limited to, copyright laws. The User agrees not to use such protected information or materials in any way other than for the use of the Service in accordance with this Contract. No part of the Service may be reproduced in any form or by any means, except as expressly permitted under these provisions.</p>
            <p>10.2 Digiteal grants to the User a personal, non-exclusive, non-transferable, limited licence to use the Software as provided to the User by Digiteal as part of the Service and in accordance with this Contract; provided that the User does not copy, modify, rent, loan, distribute, create derivative workfrom, retroactively develop, decompile or otherwise attempt to discover the source code (except as permitted or expressly required by law), sell any rights in the Software, or lease, sub-license or cede, grant or otherwise assign a security interest in the Service (or permit others to do so) and that the User does not operate the Service in any unauthorised manner, including, but not limited to, by exceeding or overloading the network capacity. Except for the use of the Service as authorised in the Contract, the use of the software or parts of the Service is strictly prohibited and constitutes an infringement of the intellectual property rights of others.</p>
            <p>10.3 If the User believes that the use of the Service involves copyright infringement, he must inform the Service Desk.</p>
            <p>10.4 Digiteal may, at its discretion, suspend and/or terminate Accounts of Users that commitrecurring violations.</p>

            <h2>ARTICLE 11. Termination of the Service</h2>

            <p>11.1 The contract is concluded for an indefinite period.</p>
            <p>11.2 The User may terminate the use of the Service at any time. The User will be unsubscribed immediately.</p>
            <p>11.3 Digiteal may terminate the Service at any time by sending an email with one month's notice.</p>
            <p>11.4 Digiteal may, under certain circumstances, at any time and without prior notice or compensation, terminate or suspend the Service as a result of, in particular, the following:</p>
            <ol type="a">
                <li>Breaches of this Contract or other policies or guidelines referred to in this Contract and/or applicable to the Service.</li>
                <li>A request and/or order from a legal or judicial authority.</li>
                <li>If the provision of the Service is or may become unlawful.</li>
                <li>Unforeseen technical or security issues or problems.</li>
                <li>If the User is involved in fraudulent or illegal activities.</li>
            </ol>
            <p>11.5 In addition, Digiteal may terminate the Account after prior notice by email to the address associated with the Account in the event of a general cessation or material change in the Service or any part thereof. Digiteal will not be liable to the User or any third party for any damages resulting from or resulting from such termination or suspension of the Account and/or access to the Service.</p>
            <p>11.6 After termination of the Account, the User will no longer have access to the Service and all parts thereof, including, but not limited to, the Account and the Data. In addition, after a specified period of time, Digiteal will delete some or all of the Data stored in or as part of the Account(s). The separate components of the Service, which the User may have used on the basis of separate software license agreements, will also be determined in accordance with such license agreements.</p>
            <p>11.7 Regarding "Pay with Payment Terms", the termination of this Agreement shall not impact (i) any Prepayment, without prejudice to Digiteal's right to reclaim the Prepayment from the Seller in case a Transaction Dispute arises between the Buyer and the Seller, nor (ii) the clauses in Article 8.6 which shall remain applicable and enforceable vis-à-vis the Seller for a period of 5 years as from the termination of the Agreement.</p>

            <h2>ARTICLE 12. Data Protection</h2>

            <p>The use of Digiteal's services implies the processing of data, which could include personal data. In this case, Digiteal shall process personal data according to the GDPR legislation and in accordance with the provisions the general privacy policy of Digiteal, described in the following document "Data Privacy Policy", which can be accessed during the creation of the Account, via the website <a href="https://www.digiteal.eu/" target="_blank" rel="noreferrer">digiteal.eu</a> and via the <a href="https://app.digiteal.eu/" target="_blank" rel="noreferrer">app.digiteal.eu</a> platform (web & mobile app).</p>

            <h2>ARTICLE 13. Interruptions to Digiteal</h2>

            <p>13.1 Digiteal reserves the right to suspend the Service for maintenance purposes or to make changes or improvements to the Software. Digiteal will inform the User in advance where possible. However, it may be the case that interruptions occur without the User being informed, such as in the event of a technical incident or force majeure, including, but not limited to, strikes or any other event over which Digiteal has no control, or in the event of an extreme emergency.</p>

            <h2>ARTICLE 14. Other provisions</h2>

            <p>14.1 Digiteal may, at any time, add and activate new Services at the explicit request of the User and charge for them. Digiteal may, under no circumstances, charge for free services as described in paragraph 9.1</p>
            <p>14.2 Digiteal may, at any time, modify this Contract and adapt the specifications or technical properties of the Service in accordance with technical, operational, legal or economic requirements. If such a change significantly affects the use of the Service, the User can always terminate his use of the Service. The User is deemed to have accepted the changes and additions if he continues to use the Service.</p>
            <p>14.3 The invalidity of one or more provisions of this Contract shall not affect the validity of the other provisions and the invalid provision shall be replaced by a provision that is as close as possible to the intent of the invalid provision</p>
            <p>The User shall not be allowed, without the prior written consent of Digiteal, to assign or transfer the benefit of all or any of its obligations nor any benefit arising under the Contract.</p>
            <p>14.4 Nothing in this Contract shall be construed as the assignment to the User of any right, title or licence</p>
            <p>14.5 Data, components or facilities of the Service may include Data from third parties and/or hyperlinks to other websites. To the extent that Digiteal is unable to control these websites and/or materials from third parties, the User acknowledges and agree that Digiteal is not responsible for the accessibility of these websites or facilities, does not confirm or guarantee the accuracy of these websites or facilities, nor is it responsible for the Data, advertisements, products or materials accessible on or through these websites or facilities. The User also acknowledges and agrees that Digiteal is in no way responsible for any damage that the User has suffered or believes he has suffered, either directly or indirectly, as a result of his use of these websites.</p>
            <p>14.6 Digiteal may send the User a notification regarding the Service, including changes to these terms and conditions, by email to the email address linked to his Account, by regular mail, or by posting it on its website.</p>
            <p>14.7 Should Digiteal not exercise a right under this Contract, this cannot be construed as a waiver of that right or any other rights. The User agrees that, unless otherwise expressly provided in this Contract, third parties may not obtain any rights under this Contract.</p>
            <p>14.8 The Contract is governed by Belgian law, to the exclusion of any other legislation. For the performance of the Contract, the Parties must respectively provide an address for service at their registered offices as indicated on the invoice headers. Any change in the registered office or address of one of the Parties shall not be binding on the other Party until eight (8) calendar days after being duly notified of it.</p>
            <p>14.9 In order to jointly find a solution to any dispute arising in the performance of the Contract, the Parties agree to meet within thirty (30) days of receipt of a registered letter with acknowledgement of receipt as notified by one of the two Parties.</p>
            <p>14.10 If at the end of a new fifteen-day period, the parties are unable to reach an agreement, the dispute will then be submitted to the courts in Walloon Brabant, located at Nivelles.</p>
            <p>14.11 Unless otherwise agreed, the signature (optionally electronic) of this Contract by the User shall be binding on the User and Digiteal from the moment the User has ticked the box referring to the fact they have read and effectively accepted this Contract.</p>

            <table>
                <tbody>
                    <tr><td>Digiteal S.A.</td></tr>
                    <tr><td>Rue Emile Francqui 6/9</td></tr>
                    <tr><td>1435 Mont-Saint-Guibert</td></tr>
                    <tr><td>Belgium</td></tr>
                </tbody>
            </table>

            <p>This version of the General Terms and Conditions is dated 24/05/2023.</p>

            <h1>Client Terms and Conditions</h1>

            <p><strong>BETWEEN:</strong></p>
            <p>Digiteal SA having its registered office at Rue Emile Francqui No.6, Box 9, 1435 Mont-Saint-Guibert (Belgium) identified at Banque Carrefour des Entreprises, Belgium, under number BE 0630 675 588.</p>

            <p><strong>AND:</strong></p>
            <p>Any natural or legal person who has accepted these general terms and conditions, hereinafter referred to as the <strong>"User"</strong> individually referred to as <strong>"Party"</strong> and together as <strong>"Parties"</strong>.</p>

            <p><strong>WHEREAS:</strong></p>
            <p>The Client wishes to use the <strong>Services</strong> of Digiteal defined in <a href="#descriptionOfServicesArticleClient">ARTICLE 4. Description of Services</a>. The Client acknowledges that he has received from Digiteal all the necessary information enabling him to assess whether the Services are adequate for his needs and to take all necessary precautions for use thereof.</p>

            <p><strong>THIS HAVING BEEN SHOWN, THE FOLLOWING WAS AGREED:</strong></p>

            <h2>ARTICLE 1. Definitions</h2>

            <p>The <strong>terms</strong> beginning with a capital letter in these Client-specific terms and conditions, whether used in the singular or plural, shall have the meanings given to them below. Additional definitions are included in the <strong>"General Terms and Conditions"</strong>. Unless specified otherwise, the definitions in the General Terms and Conditions have the same meaning in these Client-specific Terms and Conditions.</p>

            <table>
                <tbody>
                    <tr>
                        <td><strong>"Anonymised Copy"</strong></td>
                        <td>means a copy of the Personal Data in which the private and confidential information has been deleted.</td>
                    </tr>
                    <tr>
                        <td><strong>"Force Majeure"</strong></td>
                        <td>means any unforeseeable event, arising out of circumstances other than a breach of the Contract and that is beyond the control of the affected Party and renders the performance of the affected Party's obligations under the Contract impossible.</td>
                    </tr>
                    <tr>
                        <td><strong>"Integrator"</strong></td>
                        <td>means the technical service provider that connects to Digiteal's services on behalf of the Client.</td>
                    </tr>
                    <tr>
                        <td><strong>"Intellectual Property"</strong></td>
                        <td>means all Brands, logos, trademarks, internet domain names, models and designs, patents as defined in the Belgian Code of economic law and EU legislation.</td>
                    </tr>
                    <tr>
                        <td><strong>"Intellectual Property Rights"</strong></td>
                        <td>means all the rights pertaining to the Intellectual Property including copyrights (including all rights relating to software) and moral rights, rights relating to databases, knowhow, and other rights, as well as all other industrial and intellectual rights, in any case independent from whether or not they have been registered and with the inclusion of registration applications, if any, as well as all equivalent rights or means of protection leading to a similar result anywhere in the world.</td>
                    </tr>
                    <tr>
                        <td><strong>SaaS</strong></td>
                        <td>means Software as a Service, i.e. business applications rented online.</td>
                    </tr>
                </tbody>
            </table>

            <h2>ARTICLE 2. Contractual documents</h2>

            <p>2.1. These Client-specific Terms and Conditions, together with the documents entitled</p>

            <ul>
                <li><strong>"Data Privacy Policy"</strong></li>
                <li><strong>"General Terms and Conditions"</strong> and</li>
                <li>Potentially a <strong>"Marketplace Agreement"</strong></li>
            </ul>

            <p>Constitute the entirety of the commitments existing between the Parties (hereinafter referred to as "the Contract") excluding the Client's own general terms and conditions. The Contract replaces and cancels any previous oral or written undertakings relating to its subject matter</p>

            <p>2.2. In the event of a contradiction between one and/or more provisions contained in any of the documents of the Contract, the Marketplace Agreement shall prevail over the Client-specific Terms and Conditions, the Client-specific Terms and Conditions shall prevail over General Terms and Conditions, and General Terms and Conditions shall prevail over Data Privacy Policy.</p>

            <h2>ARTICLE 3. Conclusoin, duration and renewal</h2>

            <p>3.1. Unless otherwise agreed, the online electronic signing of the Contract by the Client binds the Parties from the moment at which the Client has ticked the box referring to the reading and effective acceptance of the Contract.</p>

            <p>3.2 The Contract is concluded for a fixed period of one (1) year from the acceptance of the Contract.</p>

            <p>3.3. Unless notice is given to the other Party by registered letter at least two months before the expiry date, the Contract shall be tacitly renewed for an equivalent duration under identical conditions, unless the Parties agree on a new functional scope for Services in order to better meet the Client's needs</p>

            <p>3.4. The Client undertakes to keep all the Data contained in his profile up-to-date, including address, bank accounts and beneficial owners.</p>

            <h2 id="descriptionOfServicesArticleClient">ARTICLE 4. Description of Services</h2>

            <p>4.1. <strong>Application Services</strong></p>

            <p>In addition to the Services described in the General Terms and Conditions, Digiteal consents to the Client a right of end-use of the Services which are defined below, including application service maintenance and technical assistance.</p>

            <p><strong>a) Invoice presentation service</strong></p>

            <p>This service allows the Client to present its invoices to the End Customers electronically on the DIGITEAL invoice management platform. By accessing this platform (via their smartphone, tablet or computer), End Customers benefit free-of-charge from DIGITEAL's functionalities:</p>

            <ul>
                <li>centralisation of all their invoices</li>
                <li>automatic reminders</li>
                <li>manual or automatic payment</li>
                <li>archiving</li>
                <li>analysis</li>
                <li>tool, etc.</li>
            </ul>

            <p><strong>Online payment service</strong></p>

            <p>This service allows the Client to offer its End Customers the option to pay the Client through the DIGITEAL platform. Payments by End Customers will be made to a third party account opened in the name of DIGITEAL SA via SEPA Direct Debit or another payment method and will then be transferred automatically to the Client's account through a SEPA Credit Transfer. The transfer of funds will include the expected communication, as well as identifying Data available to Digiteal relating to the End Customer as ultimate debtor. In order to offer this payment service, Digiteal is authorised by the National Bank of Belgium as a payment institution.</p>

            <p><strong>Access to Bank Statements (AIS)</strong></p>

            <p>This service enables the Client to receive information related to the banking movements of a PSU. Access to bank movements is authorised if the PSU has given Digiteal its consent to access the bank account and to share this information with the Client</p>

            <p><strong>Payment Initiation Service (PIS)</strong></p>

            <p>This service allows the Client to present payment options that lead, after consent given by the PSU, to the execution of one or more payments from the PSU's account.</p>

            <p><strong>SDD CORE mandate creation</strong></p>

            <p>This service enables the Client to initiate the creation, check the data and get the consent for an SDD Core mandate for one of its End Customers. The confirmation process verifies that the End Customer has access to the bank account and that the name of the bank account holder matches the name of the End Customer. This confirmation and the consent of the End Customer are provided through a certified document to the Client that is the proof of the proper creation of the mandate</p>

            <p>4.2. <strong>Online Services</strong></p>

            <p>Digiteal shall make available to the Client the Services on its server via Internet. Under the conditions of <a href="#licenseArticle">ARTICLE 6. Licence</a>, Digiteal grants the Client the right to use the Services developed by Digiteal on a non-exclusive basis. Digiteal is responsible for hosting the Data, for maintenance and for the security of the Services. Digiteal regularly backs up the Data.</p>

            <p>4.3. <strong>Network</strong></p>

            <p>The network is chosen by the Client. Digiteal provides no guarantee. As Digiteal cannot be held liable for line interruptions in the network, the Client's attention is drawn in particular to the importance of choosing their operator and the operator's product, and in particular the back-up option they can offer by setting up a parallel line in the event of a network interruption.</p>

            <p>4.4. <strong>Access to Application Services</strong></p>

            <p>The Client alone shall use this right of access. With the exception of maintenance periods, they will be able to connect at any time, i.e. every day, 24 hours a day.</p>

            <p>The following access procedure must be strictly adhered to by the Client. Access is provided:</p>
            <ul>
                <li>From any computer</li>
                <li>Using the Login Codes chosen by the Client Users during their registration.</li>
            </ul>

            <p>Client Users will use their Login Codes each time they connect to the Services. Login Codes are intended to restrict access to the Services covered by the Contract and provided to the Client Users, to protect the integrity and availability of the Services, and to protect the integrity, availability and confidentiality of the Client's Personal Data as transmitted by the Users. Login Codes are both personal and confidential. They may only be changed at the request of a User or on the initiative of Digiteal, provided that the Client is informed in advance. The Client undertakes to do everything possible to keep the Login Codes concerning him/her confidential and not to disclose them in any form whatsoever.</p>
            <p>The Client is fully responsible for the choice and use of the Login Codes and is solely responsible for the safekeeping of the Login Codes provided to them by Digiteal. It will ensure that nobody who is not authorised by Digiteal has access to the Services. In general, the Client is responsible for the security of the individual workstations used to access the Services. In the event that the Client becomes aware that another person has gained access, the Client must inform Digiteal without delay. In the event of loss or theft of one of the Login Codes, the Client shall use the procedure set up by Digiteal to recover the Login Codes from its Client Users and, in the case of emergency, the Client may send an email to the address <a href="mailto:support@digiteal.eu">support@digiteal.eu</a></p>

            <h2>ARTICLE 5. Quality of Services</h2>

            <p>5.1. The Client is informed of the technical risks inherent in the Internet, and of the interruptions to access that may result therefrom. Consequently, Digiteal shall not be held liable for any slowdown or unavailability of the Services. Digiteal is not in a position to guarantee the continuity of the Services, which are executed remotely via the Internet; this is something that the Client acknowledges. In addition, it is the Client's responsibility to respect the volume thresholds and to notify Digiteal in the event of an increase in its processing capacity requirements. In the event of non-compliance by the Client with the Contract, Digiteal reserves the right to limit or suspend the Client's access to the Services.</p>

            <p>5.2. Digiteal undertakes to implement effective checks to provide reasonable assurance that the Client can access and use the relevant applications at any time, i.e. 24 hours a day, 7 days a week. Services may occasionally be suspended due to maintenance operations that are necessary for the proper functioning of the Platform. In the event of an interruption to the Services as a result of maintenance work, Digiteal undertakes to inform the Client as soon as possible of the interruption so that the Client can make suitable arrangements in advance to avoid any disruption of its activity.</p>

            <h2 id="licenseArticle">ARTICLE 6. License</h2>

            <p>6.1. Digiteal grants the Client a personal, non-exclusive, non-assignable and non-transferable right to use the Services for the duration of the Contract on a global basis. The Client may only use the Services in accordance with its needs and the relevant documentation. In particular, the licence relating to the Services is granted solely for the purpose of enabling the Client to use the Services, and this is to the exclusion of any other purpose.</p>

            <p>6.2. The right of use refers to the right to use and implement the Services in accordance with their destination, in SaaS mode via a connection to an electronic communications network. The Client may under no circumstances make the Services available to a third party, and shall strictly refrain from any other use, in particular any adaptation, modification, translation, arrangement, distribution, decompiling; this list is not exhaustive.</p>

            <h2>ARTICLE 7. Maintenance</h2>

            <p>7.1. Digiteal is responsible for progressive and corrective maintenance of the Services. A free email support service is available by emailing on <a href="mailto:support@digiteal.eu">support@digiteal.eu</a> to deal with anomalies; this support service is available during Working Hours. Digiteal shall diagnose the anomaly and then implement its correction within a maximum period of five (5) Working Days.</p>

            <p>7.2. The Client shall benefit from updates and any functional evolutions of the Services. The nature of updates and evolutions, as well as their date, are determined by Digiteal and are not subject to prior authorisation or notification to the Client. If a corrective or progressive development necessitates the interruption of the service, Digiteal shall inform the Client three (3) days in advance.</p>

            <p>7.3. Digiteal is not responsible for maintenance in the following cases:</p>
            <ul>
                <li>Refusal of the Client to cooperate with Digiteal in resolving anomalies and in particular refusal to answer questions and requests for information.</li>
                <li>Use of the Services in a manner not in accordance with their purpose or documentation.</li>
                <li>Unauthorised modification of the Services by the Client or a third party.</li>
                <li>The Client's failure to fulfil its obligations under the Contract.</li>
                <li>Implementation of any software packages, software or operating systems not compatible with the Services.</li>
                <li>Failure of electronic communication networks.</li>
                <li>Deliberate act of degradation, malicious intent, sabotage.</li>
                <li>Deterioration due to Force Majeure or improper use of the Services.</li>
            </ul>

            <h2>ARTICLE 8. Technical assitance</h2>

            <p>8.1 The technical assistance service applies to all Services and this consists of providing support to Client Users by email or through remote maintenance. The technical support service can be contacted by email at <a href="mailto:support@digiteal.eu">support@digiteal.eu</a> during Working Hours. Digiteal will respond within two (2) Working Days.</p>

            <h2>ARTICLE 9. Data processing</h2>

            <p>9.1. <strong>Personal Data</strong></p>

            <p>Digiteal acts only as a subcontractor of the Client—within the meaning of the Privacy Act—and in the context of Personal Data processing operations carried out for the purpose of performing the Contract. As a subcontractor, Digiteal undertakes to carry out operations on this Data that only fall within the framework and are strictly necessary for the performance of the Contract, so that the Client can benefit from the Services, and any fault that may be noted concerning the Services can be remedied. Digiteal undertakes to respect the strictest confidentiality regarding the Data stored by the Client and not to disclose Data to third parties unless required to do so by law. It will ensure that this obligation is respected by its employees or potential agents or subcontractors.</p>
            <p>The Client guarantees to Digiteal that it has carried out all the obligations incumbent on it under the Privacy Act and the GDPR, and that it has informed the natural persons concerned of the use made of said Personal Data. As such, the Client provides a guarantee for Digiteal against any recourse, complaint or claim from a natural person whose Personal Data is reproduced and hosted via the Service.</p>
            <p>The Data is stored on servers located in countries of the European Union. Digiteal undertakes to inform the Client of the location of the Data and, more generally, to communicate all relevant and necessary information to make the declarations. The Client, in their role as data controller,undertakes to conclude the contractual standard established by a decision of the European Commission of February 5th 2010 updated in the GDPR and to obtain the appropriate authorisation from the Commission in respect of protection of Personal Data.</p>

            <p>9.2. <strong>Data Processing</strong></p>

            <p>The Client assumes any editorial responsibility for the use of the Services.</p>
            <p>The Client is solely responsible for the quality, legality and relevance of the Data it transmits for the purpose of using the Services. It also guarantees to be the owner of the intellectual property rights allowing it to use the Data. Consequently, Digiteal declines all responsibility in the event of non-compliance of the Data with laws and regulations, public orders, or the needs of the Client.</p>
            <p>The Client guarantees Digiteal at first request against any prejudice resulting from a third party's claim for a breach of this guarantee. More generally, the Client is solely responsible for the Data that are broadcast and/or downloaded via the Services. By default, the Data is deemed Public Data. Public Data is considered non-confidential and non-exclusive. However, the natural person remains the sole owner of the Personal Data that is considered Private Data. Private Data is deemed confidential and proprietary</p>
            <p>Digiteal may make an Anonymised Copy of the Private Data. An Anonymised Copy is deemed Public Data. The Client grants Digiteal a worldwide, perpetual, non-exclusive, unlimited, royalty-free licence to use, copy, adapt, transmit, perform in public or display the Public Data.</p>
            <p>The foregoing rights shall include the right to exploit any proprietary right in the Public Data including, without limitation, the rights conferred by copyright, trademark and patent laws in any country or territory involved. This exploitation is limited to Digiteal's internal usage and usage by the initial producer of this data and its intermediaries. The Public Data cannot be commercialised to third parties.</p>

            <p>9.3. <strong>Data Security</strong></p>

            <p>Each Party undertakes to implement the appropriate technical means to ensure the security of the Data.</p>
            <p>Subject to <a href="#liabilityArticle">ARTICLE 13. Liability - Limitation - Force majeure</a>, Digiteal undertakes to preserve the integrity of the Data as well as the confidentiality of the Personal Data.</p>
            <p>The Client declares that he has been informed by Digiteal of the technical and organisational measures that the latter takes or causes to be taken by its agents and subcontractors in order to ensure the security and confidentiality of the Data so that it are adequately protected—taking into account the state of the art and technology—against accidental or unauthorised destruction by third parties, against accidental loss and against modification, access and any other processing not authorised by third parties.</p>
            <p>By entering into this Contract, the Client acknowledges that these measures are sufficient and appropriate with respect to the Data it intends to store as part of the Services</p>
            <p>The Client may at any time request from Digiteal a written description of the technical and organisational measures implemented to ensure the confidentiality and security of the Data. Digiteal may omit any technical elements or details from this description, the disclosure of which could compromise data security</p>
            <p>Digiteal also transmits Public Data and Private Data to the Client. Only End Customer's Private Data are transmitted to the Client and Integrator. In addition, part of the Data are movements on bank accounts. These movements are only transmitted if the PSU has given his consent. If this Data is transmitted to the End Customer, these End Customers must have accepted Digiteal's General Terms and Conditions and in particular the Privacy Policy.</p>

            <h2>ARTICLE 10. Financial Conditions</h2>

            <p>10.1. <strong>Dues and Payment Terms</strong></p>

            <p>A one-time setup fee is charged to the Client by Digiteal when the Services provided to the Client include presentation of invoices and/or payment collection. The setup fee and the pricing per operation is available on <a href="https://www.digiteal.eu/" target="_blank" rel="noreferrer">digiteal.eu</a>.</p>
            <p>The prices of the Services provided by Digiteal are indicated in euros and are exclusive of tax and charges. The billing address is the address of the Client's registered office.</p>
            <p>The Services provides for a per-use pricing system, i.e. issuance of an invoice to an End Customer. For a transmission to take place, the End Customer must have previously agreed to receive invoices via the Digiteal service. Otherwise, the Digiteal service notifies the Client of the End Customer's preferred means of delivery and the service is not charged.</p>

            <p>10.2. <strong>Support Fees</strong></p>

            <p>With respect to integration assistance or technical problems, the Customer's calls to Digiteal's Service Desk will be charged at a rate of €20 (excl. VAT) per quarter of an hour started, if they are not due to Digiteal's failure</p>
            <p>For example, if the Client calls the Service Desk for 35 minutes but the cause of the problem is not due to Digiteal but to a misunderstanding or error by the Client, 60 € (excl. VAT) will be charged by Digiteal to the Client. However, if it is an error in Digiteal, the assistance will be free.</p>
            <p>The assistance is invoiced monthly, based on a record of calls made during the month.</p>

            <p>10.3. <strong>Invoicing</strong></p>

            <p>Digiteal invoices the Client directly unless another agreement has been made with the Integrator. In this case, Digiteal invoices the Integrator, who might himself invoice the Client</p>
            <p>The setup will be invoiced as soon as the account is created or when the order form is signed.</p>
            <p>Digiteal invoices the Client on a monthly basis for the use of the Services, provided that a minimum amount of 10 (ten) euros is chargeable. If the minimum amount is not reached, this amount is carried forward to the following month, up to a maximum of 2 (two) carryovers. In the event of termination of the Contract by either Party, for any reason whatsoever, the amount is billable on the date of termination of the Contract.</p>

            <p>10.4. <strong>Payment Default</strong></p>

            <p>Unless otherwise agreed in writing, the Parties have agreed on the following payment procedure:</p>
            <ol type="a">
                <li>Digiteal's invoices are payable 15 calendar days from the date of issue of the invoice, net and without discount.</li>
                <li>Any complaint relating to the amount invoiced must be addressed to Digiteal by registered letter, within 8 days of receipt of the invoice.</li>
                <li>Any delay or failure to pay within the agreed time limits shall automatically and without prior notice give rise to default interest in favour of Digiteal of 10% per annum.</li>
                <li>In addition, in the event of a persistent delay of more than 60 days, the Client is liable to Digiteal, by operation of law and without formal notice, for a fixed and irreducible compensation of 10% of the outstanding amount including interest, with a fixed minimum of 50 EUR.</li>
                <li>Without prejudice to any damages, failure by the Client to pay an invoice on its due date shall automatically result in the immediate suspension of the Services. Before suspending the Services, Digiteal gives the Client notice to regularize the non-payment within 15 calendar days</li>
            </ol>

            <p>10.5. <strong>Price Revision</strong></p>

            <p>Prices may be reviewed annually. Each year, Digiteal may request a price revision by email sent to the other Party at the latest 1 month before the anniversary date of the contract.</p>
            <p>The price revision comes into effect:</p>
            <ul>
                <li>The anniversary of the signing of the contract if Digiteal has introduced its request for revision before this date. The price revision only concerns the services actually provided after the anniversary date of the contract.</li>
                <li>The 1st day of the next month following the sending of the email if Digiteal has missed one or more anniversaries. The revision of the prices concerns only the Services actually provided after the date mentioned above.</li>
            </ul>

            <p>The above prices are subject to the application of the following price revision formula, in order to take into account fluctuations in the salaries and social charges of Digiteal's employees:</p>
            <p>P = Po x [(0.80 x s/S)+ 0.20]</p>
            <ul>
                <li>P = revised price</li>
                <li>Po = price based on the posted rate</li>
                <li>s = wage cost index (Agoria) valid on the date the price revision is applied</li>
                <li>S = wage cost index (Agoria) at the time of signing the contract</li>
            </ul>

            <p>For indices, see: <a href="http://www.agoria.be" target="_blank" rel="noreferrer">agoria.be</a></p>
            <p>Without price revision, the contract is renewed tacitly for one year.</p>

            <h2>ARTICLE 11. Intellectual Property</h2>

            <p>11.1 Digiteal is and shall remain the owner of the Intellectual Property Rights relating to all elements of the Services, as well as more generally of the IT infrastructure (software and hardware) that is implemented or developed under the Contract. The Contract does not confer any Intellectual Property Rights on the Client with respect to the Services. The temporary provision of the Services under the conditions provided for in the Contract cannot be deemed the transfer of any Intellectual Property Right to the Client.</p>

            <p>11.2. The Client undertakes not to reproduce any element of the Software, or any documentation concerning it, by any means whatsoever, in any form whatsoever and on any medium whatsoever, without the prior written authorization of Digiteal. The Client may not assign all or part of the rights and obligations resulting from the Contract, whether in the context of a temporary assignment, a sub-licence or any other contract providing for the transfer of such rights and obligations.</p>

            <h2>ARTICLE 12. Warranyy of quite possession</h2>

            <p>12.1. Digiteal declares and guarantees:</p>

            <ul>
                <li>That the Services they have developed are original within the meaning of the Belgian Intellectual Property Code.</li>
                <li>That they are the owner of all the intellectual property rights that enable it to conclude the Contract</li>
            </ul>
            <p>Digiteal declares and guarantees that the Services are not likely to infringe the rights of third parties. Digiteal shall indemnify the Client against all costs and expenses of any kind whatsoever and shall defend the Client against any action relating to intellectual property rights brought by a third party against the Client as a result of the usage of the Services</p>

            <h2 id="liabilityArticle">ARTICLE 13. Liability - Limitation - Force majeure</h2>

            <p>13.1. Digiteal is not liable:</p>

            <ul>
                <li>In respect of Data stored by the Client, in particular in the event of illegal content or content that is stored in violation of the rights of third parties.</li>
                <li>For any problem that may be due to the fault, negligence, omission, failure of the Client or the failure by the Client to comply with its legal obligations.</li>
                <li>For any problem that may arise from the failure to comply with the advice given to the Client, or from the Client's use of the Services that does not comply with the provisions of the Contract.</li>
                <li>In the event of misuse of access to the Services or Login Codes by a third party who has taken the Login Codes as a result of the fault, negligence or omission of the Client or a Client User, or in the event of the Login Codes being hacked by a third party.</li>
                <li>In the event of damage caused by a virus emanating from or propagated through the Client's equipment, or by computer piracy (hacking, etc.).</li>
                <li>For any difficulty in using the Services related to Internet access, connection speed and/or availability of the Internet by the Client, the latter assuming sole responsibility for having a suitable and adequate Internet connection to be able to access the Services and use them correctly.</li>
                <li>For any difficulty in using the Services related to the Client's computer equipment or its incompatibility for the correct use of the Services, including due to any third-party software, settings or initial configuration change that may occur after the provision of the Services.</li>
                <li>In the event of a delay in the performance of its services if the impossibility of performing its services within the agreed time limit is due to a fault committed by the Client, in particular a failure to provide necessary information to make available or resolve a fault with the Services.</li>
                <li>In the event of unavailability of the Services due to updating, maintenance or technical or other disruptions affecting all or part of the Services that are beyond the control of Digiteal (without necessarily being a case of force majeure) as well as in the event of force majeure.</li>
                <li>In the event of communication of Data relating to the Client, at the express request of a competent administrative or judicial authority, in accordance with and in compliance with applicable law</li>
                <li>In the event of suspension of the Services pursuant to <a href="#resolutionArticle">ARTICLE 14. Resolution</a>.</li>
            </ul>

            <p>13.2. The obligations entered into by Digiteal are obligations of means. Digiteal does not guarantee that the Services are free from defects. He undertakes exclusively to remedy, with all reasonable diligence in respect of the principle of good faith, any malfunctions of the Services.</p>

            <p>13.3. In the event of fault proven by the Client, Digiteal shall only be liable for compensation for the financial consequences of direct and foreseeable damage resulting from the performance of the Services. Consequently, Digiteal shall under no circumstances be liable for any indirect or unforeseeable loss or damage of the Client or third parties, including but not limited to any lost profits, loss, inaccuracy or corruption of files or Data, commercial prejudice, loss of revenue or profit, loss of clients, loss of opportunity, cost of obtaining an alternative product, service or technology, in connection with or resulting from the non-performance or improper performance of the Services</p>
            <p>In all cases, the amount of Digiteal's liability is strictly limited to the reimbursement of the sums actually paid by the Client on the date of occurrence of the event giving rise to liability, per day of interruption based on the average consumption during the previous calendar month. In addition, Digiteal shall not be liable for the accidental destruction of the Data by the Client or a Client User who has accessed the Services using the Client's Login Codes. In no event shall Digiteal be liable for any damage caused by an interruption or reduction in service of the telecommunications operator, the electricity supplier or in the event of force majeure.</p>

            <p>13.4. The Client must comply with card scheme rules. Digiteal can immediately terminate the contract for good cause in these cases: card scheme/law violation, operating in an unsound matter, brand or relationship damaging activities, fraudulent or other activity or card scheme/acquirer request. The Client must provide customer service for the cardholder.</p>

            <p>13.5 Digiteal is liable for all acts, omissions, cardholder disputes and other cardholder customer service-related issues caused by the Client.</p>
            <p>Digiteal is responsible and financially liable for each Transaction processed by the Client.</p>
            <p>Digiteal must not transfer or attempt to transfer its financial liability by asking or requesting cardholders to waiver their dispute rights.</p>
            <p>Digiteal must not contract with a Client whose contract was terminated at the direction of card schemes or a government agency. The Client hereby confirms that none of his previous contracts were terminated for non-compliance to card scheme rules or upon request of a government agency.</p>
            <p>Digiteal must not deposit transactions from Clients signed from outside Digiteal's jurisdiction. Digiteal must not permit a Client to transfer or attempt to transfer its financial liability by asking orrequesting cardholders to waive their dispute rights.</p>

            <h2 id="#resolutionArticle">ARTICLE 14. Termination</h2>

            <p>14.1. In the event of a breach by one of the Parties of its contractual obligations, and without prejudice to any damages if any, the Contract may be automatically terminated by the other Party thirty (30) days after the sending of a letter of formal notice sent by registered post to the address of the other Party's registered office with acknowledgement of receipt. Formal notice shall indicate any deficiency or deficiencies found.</p>
            <p>In the event of a termination, the Client will cease to use the Services as of the day upon which the registered letter is received.</p>

            <h2>ARTICLE 15. Reversibility</h2>

            <p>15.1. In the event of termination of the Contract, irrespective of cause, Digiteal undertakes to communicate to the Client free-of-charge—if the latter so requests by registered letter with acknowledgement of receipt and within fifteen (15) days from the date of receipt of such request—all the Data belonging to it in a standard format that can be read without difficulty in an equivalent environment.</p>
            <p>The Client shall actively cooperate with Digiteal to facilitate the recovery of the Data. Digiteal shall ensure that the Client can continue to use the Data, without interruption, directly or with the assistance of another service provider.</p>
            <p>During the reversibility phase, Digiteal will not provide the Services.</p>

            <h2>ARTICLE 16. Communication and advertising</h2>

            <p>16.1. Subject to 16.2, the Client authorises implicitly Digiteal to mention the Client's trademark and the Services in use in all communication and advertising documents (brochure, sheet, website and any other communication medium). As such, Digiteal shall be entitled to refer to the Client's identification elements</p>

            <p>16.2. A Client who does not wish their trademark to be used in Digteal's public communications may inform Digiteal by sending this by simple request to the Service Desk.</p>

            <h2>ARTICLE 17. Payment Service</h2>

            <p>17.1 Risk control: any Client who wishes to use the Services will be analysed by the "risk control" department. Depending on the risk profile, a guarantee may be required if, for example, the Client was recently established, the Client has a high credit risk, etc. A review will be carried out on an annual basis to examine possible new elements. Depending on the circumstances, a guarantee may be required, increased, decreased or cancelled.</p>

            <p><strong>Refund request by the Debtor</strong></p>

            <p>17.2 Refund: in the event of a refund requested by the Debtor for a debit made by Digiteal, Digiteal will automatically withdraw the amount - received by the Client - from the Client in question. Digiteal will use a B2C mandate for this purpose for each of the Client's bank accounts. The Client will have established a B2C mandate in respect of Digiteal prior to the start of the Contract. Revocation of the mandate can only be effective at the end of the Contract.</p>

            <p><strong>Access to the transaction information</strong></p>

            <p>17.3 The Client hereby confirms that it will obtain the required authorisations from the End Customers so that:</p>
            <ol type="1">
                <li>Digiteal can be granted access to all Transaction Information as necessary for Digiteal to assess the eligibility of End Customers for the use of the "Pay with payment terms" payment Service and its further management. The Client confirms that it will provide such Transaction Information to Digiteal upon request.</li>
                <li>Digiteal and the Client are authorised to transfer and provide Transaction Information necessary for the management of the Credit to any third party that has a formal interest in the acquisition of all or part of the claims of Digiteal and/or any right or obligation of Digiteal under any agreement between Digiteal and a User.</li>
            </ol>

            <h2>ARTICLE 18. Exclusion of interventions</h2>

            <p>18.1. Any interventions due to non-conforming or unauthorised use of the Services by the Client, or following a fault with one of the elements of its configuration, are invoiced in addition.</p>

            <h2>ARTICLE 19. Commitment of resources</h2>

            <table>
                <tbody>
                    <tr>
                        <td><strong>Commitment of resources</strong></td>
                        <td><strong>Level of service</strong></td>
                    </tr>
                    <tr>
                        <td>Operational and Technical Assistance</td>
                        <td>Operational Assistance services are provided during WorkingHours. E-mails and calls received outside these hourswill be taken into account at 09:00 on the following Working Day.</td>
                    </tr>
                    <tr>
                        <td>Scheduled service interruptions</td>
                        <td>Planned at least 1 week in advance.</td>
                    </tr>
                    <tr>
                        <td>Number of scheduled global service interruptions</td>
                        <td>Maximum 3 times per month with a duration of maximum 60 minutes per interruption.</td>
                    </tr>
                    <tr>
                        <td>Service availability</td>
                        <td>99,9% 24/7 excluding scheduled global service interruptions.</td>
                    </tr>
                    <tr>
                        <td>Services response time</td>
                        <td>Less than 2 seconds in 90% of cases, lessthan 5 seconds on average for the remaining 10%.</td>
                    </tr>
                </tbody>
            </table>

            <h2>ARTICLE 20. Receipt and qualification of requests</h2>

            <p>20.1. <strong>Date on which the request has been received</strong></p>

            <p>Requests must be addressed to our Service Desk. Requests must include the following information to be considered compliant:</p>
            <ol type="a">
                <li>Affected Service</li>
                <li>Description of the problem</li>
                <li>Procedure for reproducing the problem</li>
                <li>Screenshot of the problem</li>
                <li>Date of the event</li>
                <li>Criticality (number of Users impacted)</li>
                <li>If applicable, the phone number of the requester</li>
            </ol>
            <p>The receipt of a compliant request marks the opening of a ticket. In the event of a non-compliant request, the Client is asked under the deadline for the first response to complete his request. The receipt of a new completed request triggers a new deadline for first response. If no return is made by the Client within two working days, the ticket is closed.</p>

            <p>20.2. <strong>Qualification</strong></p>

            <p>A first response is provided by email within a period specified in the table below under the term "Deadline for taking charge" with the aim of:</p>
            <ul>
                <li>To acknowledge receipt of the request.</li>
                <li>To qualify the request under the conditions described below.</li>
                <li>To provide the answer if possible.</li>
                <li>
                    To notify that further investigation is required and to request any additional information. In the latter case, only the response to the requester for the additional information requested will trigger the time limit for the provision of a correction or an alternative solution. The Digiteal support engineer qualifies the request as Functional Assistance, Technical Assistance or Corrective Maintenance according to the following nomenclature:
                    <ul>
                        <li><strong>"Functional Assistance"</strong>: refers to any request for information or clarification for the use of the functionalities of the Services.</li>
                        <li><strong>"Technical Assistance"</strong>: refers to any request for information or clarification regarding the communication of the Services with the Client's information system. This request for technical assistance is reclassified as a "minor incident" if it appears that the requester's use of the platform complies with the technical documentation, but that the expected result does not correspond to the platform's "normal" behaviour.</li>
                        <li><strong>"Corrective Maintenance"</strong>: refers to all operations in response to anomalies that do not fall within the scope of Functional or Technical Assistance.</li>
                        <li><strong>"Technical Maintenance"</strong>: refers to any incident, exclusively related to the technical infrastructure under the control of Digiteal, and which prevents access to the Services.</li>
                    </ul>
                </li>
            </ul>

            <p>20.3. <strong>Corrective Maintenance Level</strong></p>

            <p>In the event that Corrective Maintenance is chosen by the support engineer, Digiteal will proceed to the precise qualification of the gravity of the incident and the corresponding support level according to the following nomenclature:</p>
            <ul>
                <li><strong>"Basic Support"</strong> applies to minor incidents such as errors, bugs that degrade the Services in relation to its technical documentation, but the occurrence of which does not hinder its use.</li>
                <li><strong>"Advanced Support"</strong> applies to incidents that cause disruptions that do not prevent the Services from being provided, or that can be bypassed, but in any case do not allow normal use of one or more features of the Services in relation to its technical documentation such as errors, bugs.</li>
                <li><strong>"Expert Support"</strong>  applies to incidents that result in a complete loss of the Services or have a very significant impact on the Services, which cannot be resolved by finding an alternative to them.</li>
            </ul>

            <h2>ARTICLE 21. Processing of requests</h2>

            <p>21.1. <strong>Assistance and Maintenance deadlines</strong></p>

            <p>First level actions are carried out within the time limits announced in the table below under the term "First level action". The aforementioned time limits begin to run from the point at which the Digiteal support engineer responds or from the return of the components by the Client as requested by the support engineer. In the absence of any further comments from the applicant within 2 Working Days of the final corrective or first-level action being taken to correct the situation, the support ticket shall be closed.</p>

            <table className="styled-table">
                <tbody>
                    <tr>
                        <td><strong>Actions</strong></td>
                        <td colSpan={5}><strong>Intervention</strong></td>
                    </tr>
                    <tr>
                        <td>Deadline for first response</td>
                        <td colSpan={5}>1 Working Day</td>
                    </tr>
                    <tr>
                        <td>Qualification of 1st level</td>
                        <td><strong>ASSISTANCE</strong></td>
                        <td colSpan={3}><strong>CORRECTIVE MAINTENANCE</strong></td>
                        <td><strong>TECHNICAL MAINTENANCE</strong></td>
                    </tr>
                    <tr>
                        <td>Second level qualification</td>
                        <td><strong>Technical and Functional Assistance</strong></td>
                        <td><strong>Basic Support</strong></td>
                        <td><strong>Advanced support</strong></td>
                        <td><strong>Expert support</strong></td>
                        <td>/</td>
                    </tr>
                    <tr>
                        <td>First level action</td>
                        <td>Email response with suggested explanation or solution</td>
                        <td>No</td>
                        <td>Correction of the incident by re-parameterisation of the Services or email instructions pertaining to an alternative solution*</td>
                        <td>Update specific to the Anomaly</td>
                        <td>Restoration of access to the Services</td>
                    </tr>
                    <tr>
                        <td>Second level action</td>
                        <td>/</td>
                        <td>Correction made in a future update</td>
                        <td>Correctoin made in a future update</td>
                        <td>/</td>
                        <td>/</td>
                    </tr>
                </tbody>
            </table>

            <p>* Alternative solution: provides an alternative way to use the Services by achieving the intended purpose.</p>
        </div>
    );
};

export default TermsAndConditionsBodyEn;
