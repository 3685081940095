import { Error } from "@mui/icons-material";
import './ErrorMsg.scss'

const ErrorMsg = (props: IProps) => {

return (
        <div className='error-container'>
            <Error></Error>
            <p>{props.text}</p>
        </div>
    );
}

export default ErrorMsg;

interface IProps {
    text: string;
}
