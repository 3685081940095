import { IconButton, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IBusinessTypeState } from "../../BusinessTypeStep/BusinessTypeStep";
import EditIcon from '@mui/icons-material/Edit';

const BusinessTypeSummary = (props: IProps) => {
    const { t } = useTranslation();

    const selectedCountry = props.state.countries.find(c => c.code === props.state.fields.countryCode);
    const selectedType = props.state.types.find(t => t.value === props.state.fields.type);
    const selectedStructure = props.state.structures && props.state.structures.find(s => s.value === props.state.fields.structure);

    return (
        <div className='business-type-summary-container summary-section'>
            <div className='summary-section-header'>
                <h3>{t('steps.business_type.name')}</h3>
                <Tooltip title={t('buttons.edit')}>
                    <IconButton aria-label="edit" size="small" onClick={props.onEdit}>
                        <EditIcon fontSize="inherit" />
                    </IconButton>
                </Tooltip>
            </div>

            <div className="summary-section-content summary-section-grid">
            <div>{t('form_labels.country')}</div>
                <div>{selectedCountry!.name}</div>

                <div>{t('form_labels.company_type')}</div>
                <div>{selectedType!.description}</div>

                {selectedStructure &&
                    <>
                        <div>{t('form_labels.business_structure')}</div>
                        <div>{selectedStructure.description}</div>
                    </>
                }
            </div>
        </div>
    );
};

export default BusinessTypeSummary;

interface IProps {
    state: IBusinessTypeState;
    onEdit: () => void;
}
