import { IconButton, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IPayoutAccountState } from "../../PayoutAccountStep/PayoutAccountStep";
import EditIcon from '@mui/icons-material/Edit';
import { getDocumentTypeText } from "../../../../../../helpers/applicationHelper";

const PayoutAccountSummary = (props: IProps) => {
    const { t: translate } = useTranslation();

    const selectedCountry = props.state.countries.find(c => c.code === props.state.fields.iban!.substring(0, 2));

    return (
        <div className='payout-account-summary-container summary-section'>
            <div className='summary-section-header'>
                <h3>{translate('steps.payouts.name')}</h3>
                <Tooltip title={translate('buttons.edit')}>
                    <IconButton aria-label="edit" size="small" onClick={props.onEdit}>
                        <EditIcon fontSize="inherit" />
                    </IconButton>
                </Tooltip>
            </div>

            <div className="summary-section-content summary-section-grid">
                <div>{translate('form_labels.iban')}</div>
                <div>{props.state.fields.iban!}</div>

                <div>{translate('form_labels.country')}</div>
                <div>{selectedCountry!.name}</div>

                <div>{translate('form_labels.account_owners_name')}</div>
                <div>{props.state.fields.accountOwnersName}</div>

                <div>{translate('form_labels.currency')}</div>
                <div>EUR</div>

                {props.state.uploadedDocuments.length > 0 &&
                    <>
                        <div>{translate('steps.common.documents')}</div>
                        <div>
                            {props.state.uploadedDocuments.map((doc, i) => {
                                return (
                                    <div key={`doc-${i}`}>{`${doc.name} - ${getDocumentTypeText(doc.type, translate)}`}</div>
                                );
                            })}
                        </div>
                    </>
                }
            </div>
        </div>
    );
};

export default PayoutAccountSummary;

interface IProps {
    state: IPayoutAccountState;
    onEdit: () => void;
}
