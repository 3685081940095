import { updateBusinessType } from "../../../../../services/updateBusinessTypeService";
import { Button, SelectChangeEvent, Stack } from "@mui/material";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import './BusinessTypeStep.scss';
import { IBusinessTypes, getBusinessTypes } from "../../../../../services/getBusinessTypesService";
import SelectField, { IItem } from "../../../../shared/SelectField/SelectField";
import { ICountry } from "../../../../../services/getCountriesService";
import CountryField from "../../../../shared/CountryField/CountryField";
import { ISubmitApplicationResult } from "../../../../../services/commonTypes";

const BusinessTypeStep = (props: IProps) => {
    const { t } = useTranslation();

    const handleChangeField = (fieldName: string, value: string) => {
        props.onStateChanged(
            {
                ...props.state,
                fields: {
                    ...props.state.fields!,
                    [fieldName]: value
                }
            });
    };

    const handleChangeType = (event: SelectChangeEvent<any>) => {
        props.onStateChanged({
            ...props.state,
            fields: {
                ...props.state.fields!,
                type: event.target.value
            }
        });
    };

    const handleChangeStructure = (event: SelectChangeEvent<any>) => {
        props.onStateChanged(
            {
                ...props.state,
                fields: {
                    ...props.state.fields,
                    structure: event.target.value,
                }
            });
    };

    const handleSubmit = async () => {
        const isValid = isFormValid();
        if (!isValid) {
            return;
        }

        props.onStateChanged({ ...props.state, isLoading: true });

        try {
            const result = await updateBusinessType(props.state.operationUrl!, props.resourceToken, { countryCode: props.state.fields.countryCode!, type: props.state.fields.type!, structure: props.state.fields.structure });
            props.onCompleted(result);
        } catch (error) {
            console.log(error);
            props.onError();
            props.onStateChanged({ ...props.state, isLoading: false });
        }

        props.onStateChanged({
            ...props.state,
            isLoading: false,
            errors: {}
        });
    };

    const isFormValid = () => {
        let errors: IFields = {};

        if (!props.state.fields.countryCode) {
            errors.countryCode = t('validation_errors.required');
        }

        props.onStateChanged({ ...props.state, errors: errors });

        return (Object.keys(errors).length === 0);
    };

    useEffect(() => {

        if (!props.state.fields.countryCode) {
            props.onStateChanged({
                ...props.state,
                businessTypes: undefined,
                types: [],
                fields: {
                    ...props.state.fields!,
                    type: undefined,
                },
            });

            return;
        }

        props.onStateChanged({ ...props.state, isLoading: true });

        getBusinessTypes(props.resourceToken, props.state.fields.countryCode!)
            .then((businessTypes) => {
                const types = businessTypes.items.map(t => { return { description: t.label, value: t.value } as IItem });

                let type = types.find(s => s.value === props.state.fields.type);
                if(!type) {
                    type = types.find(s => s.value === businessTypes.defaultValue);
                }

                const selectedTypeBusinessTypeItem = businessTypes.items.find(t => t.value === type?.value);
                if (!selectedTypeBusinessTypeItem) {
                    throw new Error('Not supported');
                }

                const structures = selectedTypeBusinessTypeItem.children?.items.map(s => { return { description: s.label, value: s.value } as IItem });

                let structure = structures?.find(s => s.value === props.state.fields.structure);
                if (!structure) {
                    structure = structures?.find(s => s.value === selectedTypeBusinessTypeItem?.children?.defaultValue);
                }

                props.onStateChanged({
                    ...props.state,
                    businessTypes: businessTypes,
                    types: types,
                    structures: structures ?? [],
                    fields: {
                        ...props.state.fields!,
                        type: type?.value,
                        structure: structure?.value
                    },
                    isLoading: false
                });
            })
            .catch(error => {
                console.log(error);
                props.onError();
                props.onStateChanged({ ...props.state, isLoading: false });
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.state.fields.countryCode]);

    const timesBusinessTypeHasChanged = useRef(0);

    useEffect(() => {
        timesBusinessTypeHasChanged.current++;
        const businessTypes = props.state.businessTypes;

        if (!props.state.fields.type || !businessTypes) {
            props.onStateChanged({
                ...props.state,
                structures: [],
                fields: {
                    ...props.state.fields!,
                    structure: undefined
                },
            });

            return;
        }

        if (timesBusinessTypeHasChanged.current <= 1) {
            return;
        }

        const selectedTypeBusinessTypeItem = businessTypes.items.find(t => t.value === props.state.fields.type);
        if (!selectedTypeBusinessTypeItem) {
            throw new Error('Not supported');
        }

        const structures = selectedTypeBusinessTypeItem.children?.items.map(s => { return { description: s.label, value: s.value } as IItem });
        const defaultStructure = structures?.find(s => s.value === selectedTypeBusinessTypeItem?.children?.defaultValue);

        props.onStateChanged({
            ...props.state,
            structures: structures ?? [],
            fields: {
                ...props.state.fields!,
                structure: defaultStructure?.value
            }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.state.fields.type]);

    return (
        <div className='business-type-step-container wizard-step-fields'>
            <h2>{t('steps.business_type.introduction')}</h2>
            <Stack spacing={2}>
                <CountryField
                    id='countryCode'
                    name='countryCode'
                    label={t('form_labels.country')}
                    value={props.state.fields?.countryCode}
                    errorMessage={props.state.errors?.countryCode}
                    items={props.state.countries}
                    onChange={handleChangeField}
                    required />

                {props.state.types.length > 0 &&
                    <SelectField
                        id='type'
                        name='type'
                        label={t('form_labels.company_type')}
                        value={props.state.fields.type}
                        onChange={handleChangeType}
                        errorMessage={props.state.errors?.type}
                        items={props.state.types}
                        required />
                }

                {props.state.structures.length > 0 &&
                    <SelectField
                        id='structure'
                        name='structure'
                        label={t('form_labels.business_structure')}
                        value={props.state.fields.structure}
                        onChange={handleChangeStructure}
                        errorMessage={props.state.errors?.structure}
                        items={props.state.structures}
                        required />
                }

                <div className="wizard-step-btn">
                    <Button id="goBackBtn" color='secondary' size='large' disabled={props.state.isLoading} onClick={props.onBack}>{t('buttons.back')}</Button>
                    <Button id="continueBtn" variant='contained' color='secondary' size='large' type='submit' disabled={props.state.isLoading} onClick={handleSubmit}>{t('buttons.continue')}</Button>
                </div>
            </Stack>
        </div>
    );
};

export default BusinessTypeStep;

interface IProps {
    resourceToken: string;
    state: IBusinessTypeState;
    onBack: () => void;
    onCompleted: (result: ISubmitApplicationResult) => void;
    onError: (message?: string) => void;
    onStateChanged: (state: IBusinessTypeState) => void;
}

export interface IBusinessTypeState {
    countries: ICountry[],
    businessTypes?: IBusinessTypes;
    fields: IFields;
    errors: IFields;
    types: IItem[];
    structures: IItem[];
    operationUrl?: string;
    isLoading: boolean;
}

interface IFields {
    countryCode?: string;
    type?: string;
    structure?: string;
}
