import { handleKoResponse } from "./common/fetchHelper";

export async function getCountries(resourceToken: string) {
    const headers = new Headers();
    headers.set('Content-Type', 'application/json');
    headers.set('Authorization', `bearer ${resourceToken}`);

    const requestUrl = `${process.env.REACT_APP_API_URL}/countries`;
    const requestOptions: RequestInit = {
        method: 'GET',
        headers: headers
    };

    const response = await fetch(requestUrl, requestOptions);
    await handleKoResponse(response);

    const countryDtos = (await response.json()) as ICountryDto[]
    const countries = countryDtos.map<ICountry>(c => mapFromDto(c));

    return countries;
}

function mapFromDto(dto: ICountryDto): ICountry {
    return {
        code: dto.code,
        phonePrefix: dto.phone_prefix,
        name: dto.name,
        eeaMember: dto.eea_member
    };
}

interface ICountryDto {
    code: string;
    phone_prefix: string;
    name: string;
    eea_member: boolean;
}

export interface ICountry {
    code: string;
    phonePrefix: string;
    name: string;
    eeaMember: boolean;
}
