import { useTranslation } from "react-i18next";
import tips1 from "../../../../../../../resources/images/tips-1.png";
import tips2 from "../../../../../../../resources/images/tips-2.png";
import tips3 from "../../../../../../../resources/images/tips-3.png";
import tips4 from "../../../../../../../resources/images/tips-4.png";
import "./VerificationTipsBody.scss";

const VerificationTipsBody = () => {
    const { t } = useTranslation();

    return (
        <div className='applicant-details-step-tips'>
            <div>
                <img src={tips1} alt={t('steps.applicant_details.vt_good_upload')} />
                <h4>{t('steps.applicant_details.vt_good_upload')}</h4>
                <p>{t('steps.applicant_details.vt_1')}</p>
            </div>
            <div>
                <img src={tips2} alt={t('steps.applicant_details.vt_bad_upload') + ' - ' + t('steps.applicant_details.vt_bad_upload_cut')} />
                <h4>{t('steps.applicant_details.vt_bad_upload') + ' - ' + t('steps.applicant_details.vt_bad_upload_cut')}</h4>
                <p>{t('steps.applicant_details.vt_2')}</p>
            </div>
            <div>
                <img src={tips3} alt={t('steps.applicant_details.vt_bad_upload') + ' - ' + t('steps.applicant_details.vt_bad_upload_poor_quality')} />
                <h4>{t('steps.applicant_details.vt_bad_upload') + ' - ' + t('steps.applicant_details.vt_bad_upload_poor_quality')}</h4>
                <p>{t('steps.applicant_details.vt_3')}</p>
            </div>
            <div>
                <img src={tips4} alt={t('steps.applicant_details.vt_bad_upload') + ' - ' + t('steps.applicant_details.vt_bad_upload_poor_quality')} />
                <h4>{t('steps.applicant_details.vt_bad_upload') + ' - ' + t('steps.applicant_details.vt_bad_upload_poor_quality')}</h4>
                <p>{t('steps.applicant_details.vt_4')}</p>
            </div>
        </div>
    );
};

export default VerificationTipsBody;
