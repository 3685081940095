import { IconButton, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IRepresentativesState } from "../../RepresentativesStep/RepresentativesStep";
import EditIcon from '@mui/icons-material/Edit';
import { getRolesText } from "../../../../../../helpers/applicationHelper";
import './RepresentativesSummary.scss';
import React from "react";

const RepresentativesSummary = (props: IProps) => {
    const { t } = useTranslation();

    return (
        <div className='representatives-summary-container summary-section'>
            <div className='summary-section-header'>
                <h3>{t('steps.representatives.name')}</h3>
                <Tooltip title={t('buttons.edit')}>
                    <IconButton aria-label='edit' size='small' onClick={props.onEdit}>
                        <EditIcon fontSize='inherit' />
                    </IconButton>
                </Tooltip>
            </div>

            <div className='summary-section-content representatives-summary-grid'>
                {props.state.addedRepresentatives.map((r, index) => {
                    return (
                        <React.Fragment key={`rep-${index}`}>
                            <div>{`${r.firstName} ${r.lastName}`}</div>
                            <div>{r.email}</div>
                            <div>{getRolesText(r.roles, t)}</div>
                        </React.Fragment>
                    );
                })}
            </div>
        </div>
    );
};

export default RepresentativesSummary;

interface IProps {
    state: IRepresentativesState;
    onEdit: () => void;
}
